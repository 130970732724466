<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router/composables'
import { getAuth } from 'firebase/auth'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'

const route = useRoute()
const router = useRouter()

function logout() {
  getAuth().signOut()
  store.dispatch('setUserDB', null)
  store.dispatch('setUserFB', null)
  localStorage.removeItem('currentProfileId')
  router.push({
    name: 'Login',
    query: { redirect: route.path },
  })
}
</script>

<template>
  <div>
    <slot
      :logout="logout"
      name="activator"
    >
      Log Out
    </slot>
  </div>
</template>
