<script lang="ts" setup>
import { IEventPreview } from '@/types/event'
import { useImage } from '@/shared/composables/image'

const { getEventImg } = useImage()

interface IProps {
  previewEvent: IEventPreview
}

defineProps<IProps>()
</script>

<template>
  <router-link
    :to="{ name: 'Event', params: { id: previewEvent.id } }"
    class="event-card-mobile"
  >
    <div class="wrapper-img">
      <v-img
        :src="getEventImg(previewEvent.image, 800)"
        alt=""
        class="img"
      />
    </div>
    <h5 class="event-card-title">
      {{ previewEvent.title }}
    </h5>
    <p class="event-card-date">
      {{ previewEvent.dateStart.toLocaleDateString('en-US') }}
    </p>
  </router-link>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.event-card-mobile {
  position: relative;
  width: 320px !important;
  max-width: 320px;
  opacity: 1;
  transition: all 0.4s ease-out;

  .wrapper-img {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    background-color: var(--color-black);
    border-radius: 10px;

    .img {
      position: absolute;
      flex-shrink: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-white);
      border-radius: 10px;
      transition:
        scale 0.4s ease-out,
        opacity 0.4s ease-out;
      inset: 0;
    }
  }

  .event-card-title {
    @include font-h5;

    margin-top: 8px;
    overflow: hidden;
    color: var(--color-white);
    text-align: center;
    text-overflow: ellipsis;
  }

  .event-card-date {
    @include font-body2;

    color: var(--color-white);
    letter-spacing: 0.6667px;
    text-align: center;
    text-transform: uppercase;
  }
}
</style>
