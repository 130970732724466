import { callPrivateApi } from '@/api/apiHelper'
import { initGetEventResp, initUpdateEventReq } from '@/api/adapter'

export const getEvents = async ({
  pageNumber: p,
  pageSize: pp,
  tab: t,
  sortBy: s,
  userId: u,
  keyword: k,
  orgIds: o,
  userLat: ulat,
  userLng: ulon,
  start,
  end,
}) => {
  const data = await callPrivateApi('get', '/events', {
    params: {
      p,
      pp,
      t,
      s,
      u,
      k,
      o,
      ulat,
      ulon,
      start,
      end,
    },
  })
  if (!Array.isArray(data)) return data
  return data.map((event) => initGetEventResp(event))
}

export const getMapEvents = async ({
  tab: t,
  sortBy: s,
  userId: u,
  keyword: k,
  orgIds: o,
  userCoords: uc,
  visibleMap,
}) => {
  const data = await callPrivateApi('get', '/events-map', {
    params: {
      t,
      s,
      u,
      k,
      o,
      uc,
      ...visibleMap,
    },
  })
  if (!Array.isArray(data)) return data
  return data.map((event) => initGetEventResp(event))
}

export const getEventsAvailableTabs = async ({ userId, orgId }) =>
  callPrivateApi('get', '/events/tabs', { params: { u: userId, o: orgId } })

export const getEvent = async (eventId) => {
  const data = await callPrivateApi('get', `/events/${eventId}`)
  return initGetEventResp(data)
}

export const addEvent = async () => {
  const data = await callPrivateApi('post', '/events')
  return initGetEventResp(data)
}

export const updateEvent = async (event) => {
  const data = await callPrivateApi('post', `/events/${event.id}`, initUpdateEventReq(event))
  return initGetEventResp(data)
}

export const addRole = async (eventId, userId, role) => {
  const data = await callPrivateApi('post', `/events/${eventId}/role`, { userId, role })
  return initGetEventResp(data)
}

export const removeRole = async (eventId, userId, role) => {
  const data = await callPrivateApi('delete', `/events/${eventId}/role`, { data: { userId, role } })
  return initGetEventResp(data)
}

export const addBattle = async (eventId) => callPrivateApi('post', `/events/${eventId}/add-battle`)

export const publishEvent = async (eventId) => callPrivateApi('post', `/events/${eventId}/publish`)

export const deleteEvent = async (eventId) => callPrivateApi('post', `/events/${eventId}/delete`)

export const createTicketPayment = async (eventId, tier) =>
  callPrivateApi('post', `/events/${eventId}/buy-ticket/${tier}`)

export const checkinCurrentUser = async (eventId) => {
  const data = await callPrivateApi('post', `/events/${eventId}/checkin`)
  return initGetEventResp(data)
}

export const checkInUser = async (eventId, userId) => {
  const data = await callPrivateApi('post', `/events/${eventId}/checkin/${userId}`)
  return initGetEventResp(data)
}
