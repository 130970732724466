<script lang="ts" setup>
import { defineEmits, defineProps, onMounted, ref } from 'vue'
import { mdiUpload } from '@mdi/js'
import { useImage } from '@/shared/composables/image'
import { Organization } from '@/modules/Organizations/types'

const { saveImage, getOrgImage } = useImage()

const props = defineProps<{
  org: Organization
}>()

const emit = defineEmits(['update'])

const orgImage = ref('')
const orgImageFile = ref<FileList | null>(null)

const changeImage = (e: Event) => {
  const target = e.target as HTMLInputElement
  const filesImg = target.files

  if (!filesImg || filesImg.length === 0) return

  const reader = new FileReader()
  reader.onload = (event) => {
    orgImage.value = event.target?.result as string
    orgImageFile.value = filesImg
  }
  reader.readAsDataURL(filesImg[0])
}

const nextStage = async () => {
  if (orgImageFile.value) {
    props.org.image = await saveImage(orgImageFile.value[0])
  }

  emit('update')
}

onMounted(() => {
  orgImage.value = getOrgImage(props.org.image, 1000)
})
</script>

<template>
  <div class="org-info">
    <v-img
      :src="orgImage"
      class="image"
    >
      <label
        :class="['label white--text', { 'label-edit': orgImageFile || org.image }]"
        for="organizationImageInput"
      >
        <span class="label-text">{{ orgImageFile || org.image ? 'Edit Image' : 'Upload Image' }}</span>
        <v-icon color="var(--color-white)">
          {{ mdiUpload }}
        </v-icon>
        <input
          id="organizationImageInput"
          ref="inputFile"
          accept="image/png, image/jpeg"
          name="eventImage"
          style="display: none"
          type="file"
          @change="changeImage"
        />
      </label>
    </v-img>
    <div class="info-wrap">
      <p class="info-title">General information *</p>
      <v-text-field
        v-model="org.name"
        autocomplete="off"
        background-color="#fff"
        label="Organization name"
        outlined
      />

      <v-btn
        :disabled="!org.name"
        class="white--text"
        color="var(--color-blue-800)"
        tile
        @click="nextStage"
      >
        Next
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.org-info {
  width: 100%;
  min-height: 500px;
  margin: 20px auto;
}

.image {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 200px;
  background: #6cace4;

  .label {
    position: relative;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    max-width: 180px;
    margin: 0 auto;
    padding: 6px 16px;
    background-color: var(--color-blue-800);
    box-shadow:
      0 3px 1px -2px rgb(0 0 0 / 20%),
      0 2px 2px 0 rgb(0 0 0 / 14%),
      0 1px 5px 0 rgb(0 0 0 / 12%);
    cursor: pointer;

    .label-text {
      position: relative;
      color: var(--color-white);
      font-size: 14px;
      font-family: ff-good-web-pro, Roboto, Helvetica, sans-serif;
      text-transform: uppercase;
    }

    &::before {
      position: absolute;
      color: inherit;
      background-color: #fff;
      opacity: 0;
      transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
      content: '';
      pointer-events: none;
      inset: 0;
    }

    &:hover,
    &:focus {
      &::before {
        opacity: 0.08;
      }
    }
  }

  .label-edit {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-weight: bold;
  }
}

.info-title {
  margin: 12px 0;
  color: var(--color-dark-800);
}

.info-wrap {
  padding: 20px;
}
</style>
