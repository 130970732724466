<template>
  <div class="battles">
    <div
      v-for="(battle, index) in event.battles"
      :key="index"
      class="battle"
    >
      <EventBattlesResultsChip
        v-if="event.period === 'past'"
        :battle="battle"
        @click="toBattleDetails(battle.id)"
      />
      <EventBattlesBattleChip
        v-else
        :battle="battle"
        @click="toBattleDetails(battle.id)"
      />
    </div>
  </div>
</template>

<script>
import EventBattlesBattleChip from '@/components/EventBattlesBattleChip.vue'
import EventBattlesResultsChip from '@/components/EventBattlesResultsChip.vue'

export default {
  name: 'EventBattles',
  components: {
    EventBattlesBattleChip,
    EventBattlesResultsChip,
  },

  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toBattleDetails(battleId) {
      this.$router.push({ name: 'Battle', params: { id: battleId } })
    },
  },
}
</script>

<style lang="scss" scoped>
.battle {
  position: relative;
  width: 100%;
  margin: 5px 0;
  cursor: pointer;
}
</style>
