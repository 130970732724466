<template>
  <div class="battle-register-participants-modal">
    <BKButton
      v-if="event.permissions.canEdit"
      white-text
      @click="isShow = true"
    >
      Add competitors
    </BKButton>

    <BKModal :is-show.sync="isShow">
      <template #title>
        <div>
          <h3 class="register-participants-title">Register competitors</h3>
          <BattleUserSelect
            :battle-id="battle.id"
            :exclude="[...idsToExclude, ...judgesIds]"
            @selectUser="addUser"
            @remove-user="removeUser"
          />
        </div>
      </template>

      <ul class="users-list">
        <li
          v-for="(user, index) in selectedUsers"
          :key="user.id"
          class="users-list-item"
        >
          <v-avatar size="32">
            <img
              :src="getAvatar(user.avatar, 32)"
              alt="user.dancerName"
            />
          </v-avatar>
          {{ user.dancerName }}
          <button
            class="remove-button"
            @click="removeUser(user)"
          >
            <v-icon color="var(--rasberry-red)">
              {{ mdiMinusCircle }}
            </v-icon>
          </button>
          <BattleUserAgreements
            v-if="battle.hasAgreement || battle.hasWaiver"
            :battle="battle"
            :user="user"
            class="user-agreements"
            @update:user="updateUser(index, $event)"
          />
        </li>
      </ul>

      <template #actions>
        <BKButton
          :disabled="isSubmitDisabled"
          :loading="loading"
          white-text
          @click="submit"
        >
          Submit
        </BKButton>
      </template>
    </BKModal>
  </div>
</template>

<script>
import api from '@/api'
import Images from '@/mixins/image'
import BKButton from '@/components/AppShared/BKButton.vue'
import { BKModal } from '@/shared/ui/modals'
import BattleUserAgreements from '@/components/Battle/BattleUserAgreements.vue'
import BattleUserSelect from '@/components/Battle/BattleUserSelect.vue'
import { mdiMinusCircle } from '@mdi/js'

export default {
  name: 'BattleRegisterParticipantsModal',
  components: {
    BattleUserAgreements,
    BattleUserSelect,
    BKButton,
    BKModal,
  },
  mixins: [Images],
  props: {
    battle: {
      type: Object,
      default: null,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mdiMinusCircle,
      isShow: false,
      loading: false,
      selectedUsers: [],
    }
  },
  computed: {
    idsToExclude() {
      return this.selectedUsers.map((user) => user.id).concat(this.battle.participants.map((user) => user.id))
    },
    isSubmitDisabled() {
      if (!this.selectedUsers.length) return true
      return this.selectedUsers.some(
        (user) => (user.showAgreement && !user.agreementSigned) || (user.showWaiver && !user.waiverSigned)
      )
    },
    judgesIds() {
      return this.battle.judges.map((judge) => judge.id)
    },
  },
  methods: {
    addUser(user) {
      this.selectedUsers.push(user)
    },
    removeUser(user) {
      this.selectedUsers = this.selectedUsers.filter((selectedUser) => selectedUser.id !== user.id)
    },
    updateUser(index, user) {
      this.selectedUsers = this.selectedUsers.map((selectedUser, i) => (i === index ? user : selectedUser))
    },
    async submit() {
      this.loading = true
      const userIds = this.selectedUsers.map((user) => user.id)
      const data = await api.admin.registerDancers(this.battle.id, userIds)

      this.loading = false
      if (!data) return

      this.$emit('update:battle', data)
      this.selectedUsers = []
      this.isShow = false
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .search {
  font-weight: initial;
  font-size: initial;
}

.battle-register-participants-modal {
  padding: 0 20px;
}

.register-participants-title {
  margin-bottom: 20px;
}

.users-list {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.users-list-item {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid var(--color-grey-600);
}

.user-agreements {
  flex: 1 0 100%;
}

.remove-button {
  margin-left: auto;
  border-radius: 50%;

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px var(--rasberry-red);
  }
}
</style>
