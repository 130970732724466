<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import {
  mdiCalendarCheck,
  mdiEmailOutline,
  mdiFacebook,
  mdiInstagram,
  mdiLinkVariant,
  mdiTrophyOutline,
  mdiTwitter,
  mdiYoutube,
} from '@mdi/js'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { useImage } from '@/shared/composables/image'
import { userApi, UserDB } from '@/enitites/user'

const props = defineProps<{
  user: UserDB
}>()

const { saveImage, getFlag, getImageById } = useImage()

const loading = ref(false)
const fileImg = ref<FileList | null>(null)
const srcImg = ref('')
const error = ref<string | null>(null)

const getUserDB = computed(() => store.getters.getUserDB)

const age = computed(() => {
  if (!props.user.id || !props.user.birthday) return 0
  const birthday = new Date(props.user.birthday)
  const ageMs = Date.now() - birthday.getTime()
  const ageDate = new Date(ageMs)
  return Math.abs(ageDate.getUTCFullYear() - 1970)
})

const saveImgCard = async () => {
  error.value = null
  if (!fileImg.value) return

  loading.value = true
  getUserDB.value.card = await saveImage(fileImg.value[0])
  await userApi.updateUser(getUserDB.value)
  await store.dispatch('showAlertToast', 'changesSaved')
  loading.value = false
}

const handlerFileChange = (evt: Event) => {
  const target = evt.target as HTMLInputElement
  fileImg.value = target.files || null

  if (!fileImg.value || !fileImg.value.length) return
  loading.value = true
  const reader = new FileReader()
  reader.onload = (event) => {
    srcImg.value = event.target?.result as string
    saveImgCard()
  }
  reader.readAsDataURL(fileImg.value[0])
}

onMounted(() => {
  srcImg.value = getImageById(props.user.card, 800, 800, 'card')
})
</script>

<template>
  <section class="profile__cards cards">
    <v-card
      :style="`background-image: linear-gradient(to top, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0) 72%), url(${srcImg})`"
      class="cards__card-photo card-photo pa-5"
    >
      <v-avatar
        class="card-photo__flag flag"
        size="26"
      >
        <v-img
          :src="getFlag(user.country)"
          alt="USA"
        />
      </v-avatar>
      <v-btn
        v-if="user.canEdit"
        class="card-photo__btn cards__btn"
        for="playerCard"
        tag="label"
      >
        GIVE YOUR CARD A PHOTO
        <!--        eslint-disable vuejs-accessibility/form-control-has-label-->
        <input
          id="playerCard"
          accept="image/png, image/jpeg"
          name="userImage"
          style="display: none"
          type="file"
          @change="handlerFileChange"
        />
        <!--        eslint-enable vuejs-accessibility/form-control-has-label-->
      </v-btn>
      <div>
        <p class="card-photo__name">
          {{ user.dancerName }}
        </p>
        <span class="card-photo__person-from">{{ user.city }} {{ user.state }}, {{ user.country }}</span>
        <div
          v-if="user"
          class="card-photo__badges badges"
        >
          <div
            v-if="user.eventsAttended !== null"
            class="badges__badge badge"
          >
            <div class="badge__events">
              <v-icon class="badge__icon">
                {{ mdiCalendarCheck }}
              </v-icon>
              <span class="badge__events-count card-photo__count">{{ user.eventsAttended }}</span>
            </div>
          </div>
          <div
            v-if="user.battlesEntered !== null"
            class="card-photo__badge badge"
          >
            <div class="badge__battles-entered">
              <i class="badge__icon icon-RIbbon2" />
              <span class="badge__battles-entered-count card-photo__count">{{ user.battlesEntered }}</span>
            </div>
          </div>
          <div
            v-if="user.battlesWon !== null"
            class="card-photo__badge badge"
          >
            <div class="badge__battles-won">
              <v-icon class="badge__icon">
                {{ mdiTrophyOutline }}
              </v-icon>
              <span class="badge__battles-won-count card-photo__count">{{ user.battlesWon }}</span>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <v-card class="cards__card-info card-info">
      <div class="card-info__avatar-row">
        <v-avatar
          class="card-info__avatar"
          size="100px"
        >
          <v-img
            :src="getImageById(user.avatar, 100, 100, 'avatar')"
            alt="Person name"
          />
        </v-avatar>
        <v-avatar
          class="card-info__flag flag"
          size="30px"
        >
          <v-img
            :alt="user.country"
            :src="getFlag(user.country)"
          />
        </v-avatar>
      </div>
      <p class="card-info__name">
        {{ user.dancerName }}
      </p>
      <span class="card-info__full-name-age">{{ user.fullName }}, {{ age }}</span>
      <span class="card-info__person-from">{{ user.city }} {{ user.state }}, {{ user.country }}</span>
      <div class="card-info__footer">
        <v-divider class="mt-12" />
        <div
          v-if="user.socials"
          class="py-3 d-flex"
        >
          <span class="mr-auto"><small>Follow</small></span>
          <a
            v-if="user.socials.email"
            :href="'mailto:' + user.socials.email"
            target="_blank"
            title="email"
          >
            <v-icon
              class="card-info__social-link-icon"
              color="black"
            >
              {{ mdiEmailOutline }}
            </v-icon>
          </a>
          <a
            v-if="user.socials.facebook"
            :href="'https://www.facebook.com/' + user.socials.facebook"
            target="_blank"
            title="facebook"
          >
            <v-icon
              class="card-info__social-link-icon"
              color="black"
            >
              {{ mdiFacebook }}
            </v-icon>
          </a>
          <a
            v-if="user.socials.instagram"
            :href="'https://www.instagram.com/' + user.socials.instagram"
            target="_blank"
            title="instagram"
          >
            <v-icon
              class="card-info__social-link-icon"
              color="black"
            >
              {{ mdiInstagram }}
            </v-icon>
          </a>
          <a
            v-if="user.socials.twitter"
            :href="'https://twitter.com/' + user.socials.twitter"
            target="_blank"
            title="twitter"
          >
            <v-icon
              class="card-info__social-link-icon"
              color="black"
            >
              {{ mdiTwitter }}
            </v-icon>
          </a>
          <a
            v-if="user.socials.youtube"
            :href="'https://www.youtube.com/channel/' + user.socials.youtube"
            target="_blank"
            title="youtube"
          >
            <v-icon
              class="card-info__social-link-icon"
              color="black"
            >
              {{ mdiYoutube }}
            </v-icon>
          </a>
          <a
            v-if="user.socials.website"
            :href="'https://' + user.socials.website"
            target="_blank"
            title="website"
          >
            <v-icon
              class="card-info__social-link-icon"
              color="black"
            >
              {{ mdiLinkVariant }}
            </v-icon>
          </a>
        </div>
      </div>
    </v-card>
  </section>
</template>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.flag {
  position: absolute;
  border: 2px solid white;
  border-radius: 50% !important;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__card-photo {
    display: flex;
    flex-direction: column-reverse;
  }

  &__card-photo,
  &__card-info {
    position: relative;
    width: 296px;
    height: 352px;
    margin: 0 20px;
  }

  &__btn {
    color: #041e42;
    font-weight: bold;
    background-color: white !important;
    border: 2px solid #041e42;
    border-radius: 0;
    box-shadow: none;

    ::v-deep .v-btn__content {
      @include font-body1;
    }
  }

  .card-photo {
    text-align: center;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;

    &__flag {
      top: 10px;
      right: 10px;
    }

    &__btn {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__name {
      @include font-h3;

      margin-bottom: -5px;
      color: var(--color-white);
      font-weight: bold;
    }

    &__person-from {
      color: var(--color-white);
    }

    &__badges {
      @include font-body2;

      display: flex;
      justify-content: space-between;
      margin: 16px 0 0;
      color: #999;
    }

    .badge {
      min-width: 60px;

      &__icon {
        margin-bottom: 5px;
        color: #999;
        font-size: 25px;
      }
    }

    &__count {
      @include font-h4;

      display: block;
      margin: -5px;
    }
  }

  .card-info {
    padding: 40px 20px 20px;
    text-align: center;

    &__avatar-row {
      position: relative;
      display: inline-block;
      width: 184px;
      height: 100px;
    }

    &__avatar {
      max-width: 184px;
      max-height: 100px;
    }

    &__flag {
      top: 0;
      right: 35px;
    }

    &__name {
      @include font-h4;

      margin: 8px 0 0;
      font-weight: bold;
    }

    &__full-name-age,
    &__person-from {
      @include font-body2;

      display: inline-block;
      width: 100%;
    }

    &__add-social-link {
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: rgb(25 118 210);
      text-transform: none;
      text-decoration: none;
      border-top: 1px solid #ddd;
    }

    &__social-link-icon {
      margin: -4px 5px 0 0;
    }

    &__btn {
      width: 100%;
      margin: 26px 0 14px;
    }

    &__footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 20px;
    }
  }
}

@media (max-width: 959px) {
  .cards {
    .card-info {
      margin-top: 20px;
    }
  }
}

@media (max-width: 400px) {
  .cards {
    &__card-photo,
    &__card-info {
      position: relative;
      width: 100%;
      height: 420px;
      margin: 0;
    }

    .card-photo {
      background-position: center;
    }

    .card-info {
      &__add-social-link {
        padding: 7px 0;
        text-align: left;
      }

      &__avatar-row {
        width: 96px;
        height: 96px;
      }

      &__avatar {
        max-width: 96px;
        max-height: 96px;
      }

      &__flag {
        top: -5px;
        right: 2px;
      }
    }

    .badge {
      margin-bottom: 20px;

      &__title {
        display: none;
      }
    }
  }

  .flag {
    max-width: 30px;
    border: 2px solid white;
  }
}
</style>
