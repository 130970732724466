<template>
  <div class="battle">
    <p class="battle__title">
      Battle
      <span class="battle__title--description">(optional)</span>
    </p>
    <div class="battle__wrap-blocks d-flex flex-wrap flex-column flex-sm-row justify-center align-center">
      <div
        v-for="battle in eventData.battles"
        :key="battle.id"
        class="battle__block d-flex flex-column justify-center align-center"
      >
        <v-btn
          class="battle__block-edit"
          color="var(--color-blue-800)"
          icon
          text
          @click="handlerClickEdit(battle.id)"
        >
          <v-icon>
            {{ mdiLeadPencil }}
          </v-icon>
        </v-btn>
        <v-btn
          class="battle__block-del"
          color="var(--color-blue-800)"
          icon
          text
          @click="confirmDeleteBattle(battle.id)"
        >
          <v-icon>
            {{ mdiTrashCanOutline }}
          </v-icon>
        </v-btn>
        <span class="battle__block-title">{{ battle.title }}</span>
        <p class="battle__block-subtitle">
          {{ getBattleCategory(battle.crewSize) }}
        </p>
      </div>

      <BKConfirmationDialog
        :confirm-text="confirmationDialog.confirmText"
        :is-show="confirmationDialog.isShow"
        :title="confirmationDialog.title"
        @cancel="confirmationDialog.isShow = false"
        @confirm="confirmationDialog.confirm"
      >
        {{ confirmationDialog.subtitle }}
      </BKConfirmationDialog>

      <div class="battle__block d-flex justify-center align-center">
        <v-btn
          :disabled="loadingAddBtn"
          :loading="loadingAddBtn"
          class="battle__btn-add"
          color="var(--color-blue-800)"
          outlined
          tile
          @click="addBattle"
        >
          Add Battle
          <v-icon class="ml-4">
            {{ mdiPlus }}
          </v-icon>
        </v-btn>
      </div>
    </div>
    <CreateEventAddBattle
      v-if="dialog"
      :battle-id="currentBattleId"
      :is-edit-mode="true"
      :is-show-modal.sync="dialog"
      @updateBattle="updateBattle"
    />
    <div class="battle__navigation wrap navigation">
      <div class="navigation__wrap-dots mt-6">
        <span
          v-for="i in 5"
          v-show="isNew"
          :key="i"
          :class="i === currentDotNav ? 'navigation__dot--active' : ''"
          class="navigation__dot"
        />
      </div>
      <span
        v-show="error"
        class="navigation__text-error"
        >{{ error }}</span
      >
      <div class="navigation__wrap-btn mb-10">
        <div class="d-flex flex-column flex-sm-row-reverse">
          <v-btn
            :loading="loadingNextBtn"
            class="navigation__btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="handlerClickNext"
          >
            {{ backStep ? 'save' : 'next' }}
          </v-btn>
          <v-btn
            class="navigation__btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="backStep ? handlerClickCancelEdit() : handlerClickBack()"
          >
            {{ backStep ? 'cancel' : 'back' }}
          </v-btn>
        </div>
        <v-btn
          v-if="!backStep"
          block
          class="navigation__btn-cancel"
          color="var(--color-blue-800)"
          height="48"
          max-width="194"
          text
          @click="toggleCancelEventCreation"
        >
          cancel
        </v-btn>
      </div>
      <CreateEventCancel
        :event="eventData"
        @saveAsDraft="$emit('saveAsDraft')"
      />
    </div>
  </div>
</template>

<script>
import api from '@/api'

import Battles from '@/mixins/battle'
import { mapActions } from 'vuex'

import CreateEventAddBattle from '@/components/CreateEventAddBattle.vue'
import CreateEventCancel from '@/components/CreateEventCancel.vue'
import { BKConfirmationDialog } from '@/shared/ui/modals'
import { mdiLeadPencil, mdiPlus, mdiTrashCanOutline } from '@mdi/js'

export default {
  name: 'CreateEventBattles',
  components: {
    BKConfirmationDialog,
    CreateEventAddBattle,
    CreateEventCancel,
  },
  mixins: [Battles],
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    screen: {
      type: String,
      default: '',
    },
    eventData: {
      type: Object,
      default: () => ({}),
    },
    backStep: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiLeadPencil,
      mdiTrashCanOutline,
      mdiPlus,
      dialog: false,
      isCancel: false,
      dialogEdit: false,
      isEditMode: false,
      battles: [],
      currentBattleId: 0,
      isFirstLoading: false,
      loadingNextBtn: false,
      loadingAddBtn: false,
      currentDotNav: 4,
      error: null,
      confirmationDialog: {
        isShow: false,
        title: '',
        subtitle: '',
        confirmText: '',
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        confirm: () => {},
      },
    }
  },
  methods: {
    ...mapActions(['toggleCancelEventCreation']),
    async handlerClickNext() {
      this.error = null
      this.loadingNextBtn = true
      try {
        this.$parent.isClickBtnNext = true
        this.$emit('update:screen', 'CreateEventTotal')
      } catch (error) {
        this.error = error.response.data
      } finally {
        this.loadingNextBtn = false
      }
    },
    confirmDeleteBattle(id) {
      this.confirmationDialog = {
        isShow: true,
        title: 'Delete',
        subtitle: 'Are you sure you want to delete this battle',
        confirmText: 'Delete',
        confirm: () => {
          this.deleteBattle(id)
          this.confirmationDialog.isShow = false
        },
      }
    },
    handlerClickBack() {
      this.$parent.isClickBtnNext = false
      this.$emit('update:screen', 'CreateEventTiers')
    },
    handlerClickCancelEdit() {
      this.$emit('update:screen', 'CreateEventTotal')
    },
    async addBattle() {
      this.loadingAddBtn = true
      const battle = await api.events.addBattle(this.eventData.id)
      this.loadingAddBtn = false
      if (battle) {
        this.eventData.battles.push(battle)
        this.currentBattleId = battle.id
        this.dialog = true
      }
    },
    async updateBattle(battle) {
      if (battle.description) {
        // eslint-disable-next-line no-param-reassign
        battle.description = this.$sanitize(battle.description)
      }
      const resp = await api.battles.update(battle)
      if (resp) {
        const battleIndex = this.eventData.battles.findIndex((bat) => bat.id === battle.id)
        this.eventData.battles[battleIndex].title = battle.title
        this.eventData.battles[battleIndex].crewSize = battle.crewSize
      }
    },
    async deleteBattle(battleId) {
      const resp = await api.battles.deleteBattle(battleId)
      if (resp) {
        this.eventData.battles = this.eventData.battles.filter((battle) => battle.id !== battleId)
      }
    },

    handlerClickEdit(battleId) {
      this.currentBattleId = battleId
      this.dialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/navigationEventCreate';
@import '@/assets/style/mixins';

.hidden {
  visibility: hidden;
}

.show {
  visibility: visible;
}

.wrap {
  max-width: 400px;
  margin: 0 auto;
}

.battle {
  max-width: 1500px; // вмещается 4 блока батла
  min-height: 550px;
  margin: 0 auto;
  padding-top: 120px;

  &__block {
    position: relative;
    width: 100%;
    max-width: 360px;
    height: 230px;
    margin: 5px;
    padding: 0 45px;
    background: var(--color-grey-700);

    &-title {
      @include font-h2;

      color: var(--color-dark-800);
      font-weight: var(--font-weight-bold);
      text-align: center;
      word-break: break-all;
    }

    &-subtitle {
      @include font-h5;

      color: var(--color-dark-800);
      text-align: center;
    }

    &-edit {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    &-del {
      position: absolute;
      top: 50px;
      right: 15px;
    }
  }

  &__title {
    display: block;
    width: 100%;
    max-width: 360px;
    margin: 12px auto;
    color: var(--color-dark-800);

    &--description {
      color: #999;
    }
  }

  &__btn-add {
    font-weight: bold;
    border: 2px solid;
  }
}
</style>
