<template>
  <v-select
    v-model="currentOrg"
    :append-icon="mdiChevronDown"
    :autocomplete="false"
    :items="orgsOptions"
    background-color="#fff"
    hide-details
    item-text="name"
    label="Organization"
    outlined
    return-object
  >
    <template v-slot:item="{ item }">
      <v-list-item-avatar size="25">
        <img
          :alt="item.name"
          :src="getOrgImage(item.image, 100)"
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.name" />
      </v-list-item-content>
    </template>
    <template v-slot:selection="{ item }">
      <v-list-item-avatar size="25">
        <img
          :alt="item.name"
          :src="getOrgImage(item.image, 100)"
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.name" />
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import Images from '@/mixins/image'
import { mdiChevronDown } from '@mdi/js'

export default {
  name: 'BKOrganizationSelect',
  mixins: [Images],
  props: {
    orgs: {
      type: Array,
      required: true,
    },
    selectedOrg: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      mdiChevronDown,
      currentOrg: {},
    }
  },
  computed: {
    orgsOptions() {
      return [{ id: 0, image: 'logo-default', name: 'Breakkonnect' }, ...this.orgs]
    },
  },
  watch: {
    currentOrg(org) {
      this.$emit('update:selectedOrg', org.id)
    },
  },
  mounted() {
    this.currentOrg = this.orgsOptions.find((org) => org.id === this.selectedOrg)
  },
}
</script>
