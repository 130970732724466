<template>
  <section class="winners">
    <h2 class="subtitle">Results</h2>
    <div
      v-for="place in places"
      :key="place.id"
      class="my-6"
    >
      <div v-if="getPlaceWinners(place.id).length">
        <span
          :class="{
            winner: place.id <= 2,
            first: place.id === 1,
            second: place.id === 2,
          }"
          class="select-label"
        >
          <i
            v-if="place.id <= 2"
            class="mr-1 icon-Trophy"
          />
          {{ place.title }}
        </span>
        <div
          v-for="(winner, index) in getPlaceWinners(place.id)"
          :key="index"
        >
          <div class="d-flex flex-column">
            <div class="ma-1">
              <template v-if="winner.participants">
                <div class="user-link">
                  <div class="mb-2">
                    {{ winner.name }}
                  </div>

                  <router-link
                    v-for="participant in winner.participants"
                    :key="participant.id"
                    :to="{ name: 'Profile', params: { id: participant.id } }"
                    class="user-link"
                  >
                    <v-avatar
                      class="ml-1"
                      size="30"
                    >
                      <v-img :src="getAvatar(participant.avatar, 30)" />
                    </v-avatar>
                  </router-link>
                </div>
              </template>

              <template v-else>
                <router-link
                  :to="{ name: 'Profile', params: { id: winner.id } }"
                  class="user-link"
                >
                  <v-avatar
                    class="ml-1"
                    size="30"
                  >
                    <v-img :src="getAvatar(winner.avatar, 30)" />
                  </v-avatar>
                  <span class="ml-2">
                    {{ winner.name }}
                  </span>
                </router-link>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <BKButton
        v-if="canEdit"
        class="edit-btn"
        outlined
        width="50%"
        @click="$emit('editWinners')"
      >
        Edit winners
      </BKButton>
    </div>
  </section>
</template>

<script>
import Images from '@/mixins/image'
import { BATTLE_PLACES } from '@/common/constants'
import BKButton from '@/components/AppShared/BKButton.vue'

export default {
  name: 'BattleWinners',
  components: {
    BKButton,
  },
  mixins: [Images],
  props: {
    winners: {
      type: Array,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      places: BATTLE_PLACES,
    }
  },
  methods: {
    getPlaceWinners(place) {
      return this.winners.filter((w) => w.place === place)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.winners {
  position: relative;
  margin: 16px;
  padding: 16px;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-600);
  border-radius: 6px;

  .subtitle {
    @include font-h2;

    color: var(--color-dark-800);
    text-transform: uppercase;
  }

  .select-label {
    @include font-h4;

    display: block;
    margin-bottom: 5px;
    color: var(--color-dark-800);
    font-weight: var(--font-weight-bold);

    &.winner {
      font-weight: var(--font-weight-normal);
    }

    & .v-icon {
      font-size: 20px;
    }

    &.first {
      color: #8c7328;
    }

    &.second {
      color: #999;
    }
  }

  .user-link {
    color: black;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
