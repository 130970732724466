<script lang="ts" setup>
import { computed, defineEmits, defineProps } from 'vue'
import BKUserSelect from '@/components/BKUserSelect.vue'
import { Organization, OrganizationUser, UserRole } from '@/modules/Organizations/types'
import { addUserWithRole, removeUserWithRole } from '@/modules/Organizations/endpoints'

const props = defineProps<{
  org: Organization
}>()

const emit = defineEmits<{
  (e: 'back'): void
  (e: 'next'): void
}>()

const excludedUsersIds = computed(() => props.org.admins.map((admin) => admin.id))

const addAdmin = async (user: OrganizationUser, role: UserRole) => {
  const resp = await addUserWithRole(props.org.id, user.id, role)
  if (resp) props.org.admins.push(user)
}

const removeAdmin = async (user: OrganizationUser, role: UserRole) => {
  const resp = await removeUserWithRole(props.org.id, user.id, role)
  if (resp) {
    props.org.admins = props.org.admins.filter((admin) => admin.id !== user.id)
  }
}
</script>
<template>
  <v-card class="org-admin">
    <div class="admin-wrap">
      <p class="org-admins-title">Organization admins</p>
      <!--      eslint-disable vuejs-accessibility/aria-role-->
      <BKUserSelect
        :exclude="excludedUsersIds"
        :select-user-chip="org.admins"
        label-text="Select admins"
        role="admin"
        @removeUser="removeAdmin"
        @selectUser="addAdmin"
      />
      <!--      eslint-enable vuejs-accessibility/aria-role-->

      <v-btn
        class="btn-edit white--text"
        color="var(--color-blue-800)"
        tile
        @click="emit('back')"
      >
        Back
      </v-btn>
      <v-btn
        class="btn-edit white--text"
        color="var(--color-blue-800)"
        tile
        @click="emit('next')"
      >
        Next
      </v-btn>
    </div>
  </v-card>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.org-admin {
  width: 100%;
  min-height: 500px;
  margin: 20px auto;
}

.org-admins-title {
  margin: 12px 0;
  color: var(--color-dark-800);
}

.admin-wrap {
  padding: 20px;
}
</style>
