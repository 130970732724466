// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { callPrivateApi } from '@/api/apiHelper'
import {
  Organization,
  OrganizationsList,
  OrganizationUser,
  SearchParams,
  UserRole,
} from '@/modules/Organizations/types'

export const addUserWithRole = async (orgId: number, userId: number, role: UserRole) =>
  callPrivateApi<OrganizationUser[]>('post', `/orgs/${orgId}/role`, {
    userId,
    role,
  })

export const removeUserWithRole = async (orgId: number, userId: number, role: UserRole) =>
  callPrivateApi<OrganizationUser[]>('delete', `/orgs/${orgId}/role`, { data: { userId, role } })

export const fetchOrganization = async (id: number | string) => callPrivateApi<Organization>('get', `/orgs/${id}`)
export const updateOrganization = async (org: Organization) =>
  callPrivateApi<Organization>('post', `/orgs/${org.id}`, org)
export const fetchOrganizationsList = async ({
  pageNumber: p,
  pageSize: pp,
  userId: u,
}: {
  pageNumber: number
  pageSize: number
  userId?: number
}) => callPrivateApi<OrganizationsList>('get', '/orgs', { params: { p, pp, u } })

export const createOrganization = async () => callPrivateApi<number>('post', '/orgs')
export const fetchLeaderboard = async ({ pageNumber, pageSize, sortBy, sortDir, orgId, year }: SearchParams) => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    sb: `${sortBy}-${sortDir}`,
    y: year,
  }
  return callPrivateApi<OrganizationUser[]>('get', `/orgs/${orgId}/leaderboard`, { params })
}
