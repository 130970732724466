<template>
  <section class="crew-chip">
    <div class="crew-name">
      {{ crew.name }}
    </div>
    <div class="members">
      <router-link
        v-for="dancer in crew.participants"
        :key="dancer.id"
        :to="{ name: 'Profile', params: { id: dancer.id } }"
      >
        <v-badge
          v-if="!dancer.confirmed"
          bottom
          color="#ff6f00"
          offset-x="20"
          offset-y="17"
        >
          <span slot="badge">?</span>
          <v-avatar
            class="mr-2"
            left
            size="32"
          >
            <v-img :src="getAvatar(dancer.avatar, 100)" />
          </v-avatar>
        </v-badge>

        <v-badge
          v-else
          :value="dancer.checkedIn"
          bottom
          color="var(--color-green)"
          offset-x="18"
          offset-y="15"
        >
          <span
            slot="badge"
            class="battle-detail__badge"
            ><v-icon>{{ mdiCheck }}</v-icon></span
          >
          <v-avatar
            class="d-inline-block mr-1"
            size="30"
          >
            <v-img :src="getAvatar(dancer.avatar, 30)" />
          </v-avatar>
        </v-badge>
      </router-link>
    </div>
  </section>
</template>

<script>
import Images from '@/mixins/image'
import { mdiCheck } from '@mdi/js'

export default {
  name: 'BKCrewChip',
  mixins: [Images],
  data() {
    return {
      mdiCheck,
    }
  },
  props: {
    crew: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.crew-chip {
  min-width: 200px;
  max-width: fit-content;
  padding: 12px;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-700);
  border-radius: var(--border-radius-rounded);

  @media (max-width: $screen-sm) {
    min-width: unset;
    max-width: 100%;
  }

  .members {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
  }

  .crew-name {
    margin-bottom: 8px;
    color: var(--color-dark-800);
  }
}
</style>
