<template>
  <!--      eslint-disable vuejs-accessibility/click-events-have-key-events-->
  <div
    class="crew-row"
    @click="$emit('open', crew)"
  >
    <div class="wrap-avatar">
      <span v-if="crew.randId">{{ crew.randId }}.</span>
      <p class="info-battle my-2">
        {{ crew.name }}
      </p>
      <div class="participant-container">
        <div
          v-for="participant in crew.participants"
          :key="participant.id + 100"
        >
          <div class="user-link">
            <BKBadge
              v-if="!participant.confirmed"
              bordered
              bottom
              color="var(--color-orange-500)"
            >
              <template #badge>
                <span
                  ><v-icon>{{ mdiHelp }}</v-icon></span
                >
              </template>
              <v-avatar
                class="d-inline-block mr-1"
                size="32"
              >
                <v-img :src="getAvatar(participant.avatar, 64)" />
              </v-avatar>
            </BKBadge>

            <BKBadge
              v-else
              :value="participant.checkedIn"
              bordered
              bottom
              color="var(--color-green)"
            >
              <template #badge>
                <span
                  ><v-icon>{{ mdiCheck }}</v-icon></span
                >
              </template>
              <v-avatar
                class="d-inline-block mr-1"
                size="32"
              >
                <v-img :src="getAvatar(participant.avatar, 64)" />
              </v-avatar>
            </BKBadge>
          </div>
        </div>
      </div>
    </div>
    <v-icon
      v-if="crew.randId"
      class="check"
      color="var(--color-green)"
    >
      {{ mdiCheck }}
    </v-icon>
  </div>
  <!--      eslint-enable vuejs-accessibility/click-events-have-key-events-->
</template>

<script>
import Image from '@/mixins/image'
import BKBadge from '@/components/AppShared/BKBadge.vue'
import { mdiCheck, mdiHelp } from '@mdi/js'

export default {
  name: 'BattleCrewRow',
  components: { BKBadge },
  mixins: [Image],
  props: {
    crew: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mdiCheck,
      mdiHelp,
      isShowActions: false,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.crew-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid var(--color-grey-600);
  cursor: pointer;
  row-gap: 20px;

  &.last-child {
    border: none;
  }

  &.highlighted {
    border: 2px solid var(--color-orange-500);
  }
}

.participant-container {
  display: flex;
  width: 100%;
}

.wrap-avatar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 8px;
}

.check {
  margin-left: auto;
}

.user-link {
  color: black;
}
</style>
