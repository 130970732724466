<template>
  <div class="user-alert">
    <v-avatar
      class="main-photo"
      size="100"
    >
      <v-img
        :src="getAvatar(getUserDB.avatar, 100)"
        alt="avatar"
        class="avatar"
      />
    </v-avatar>
    <span
      v-if="true"
      class="name"
    >
      {{ getUserDB.dancerName }}
    </span>
    <div class="actions">
      <v-btn
        :to="{ name: 'Profile', params: { id: getUserDB.id } }"
        block
        class="profile-button btn"
        color="var(--color-blue-800)"
        dark
        height="52"
        tile
        width="228"
      >
        <img
          alt="icon account"
          class="icon"
          height="24"
          src="/img/ic_AvatarHead.svg"
          width="24"
        />
        <span class="text">View profile</span>
      </v-btn>
      <v-btn
        block
        class="settings-button btn"
        color="var(--color-blue-800)"
        dark
        height="52"
        tile
        to="/profile-setting"
        width="228"
      >
        <img
          alt="icon settings"
          class="icon"
          height="24"
          src="/img/ic_Settings_White.svg"
          width="24"
        />
        <span class="text">Profile Settings</span>
      </v-btn>
      <v-btn
        block
        class="settings-button btn"
        color="var(--color-blue-800)"
        dark
        height="52"
        tile
        width="228"
        @click="openSwitchPanel"
      >
        <img
          alt="icon settings"
          class="icon"
          height="24"
          src="/img/other-profiles.svg"
          width="24"
        />
        <span class="text">Switch profiles</span>
      </v-btn>
      <div class="logout-button-container">
        <HeaderUserMenuLogoutButton />
      </div>
      <div
        v-show="isShowSwitchPanel"
        class="switch-account"
      >
        <button
          class="btn-back"
          type="button"
          @click="openSwitchPanel"
        >
          <img
            alt="icon settings"
            class="icon"
            height="22"
            src="/img/arrow.svg"
            width="24"
          />
          Back
        </button>
        <v-radio-group v-model="account">
          <v-radio
            v-for="profile in getUserDB.profiles"
            :key="profile.id"
            :value="profile.id"
            @change="fetchSelectedProfile(profile.id)"
          >
            <template #label>
              <div class="label">
                <v-avatar size="48">
                  <v-img
                    :src="getAvatar(profile.avatar, 48)"
                    alt="avatar"
                    class="avatar"
                  />
                </v-avatar>
                {{ profile.dancerName }}
              </div>
            </template>
          </v-radio>
        </v-radio-group>
        <button
          v-if="$route.name !== 'CreateOtherProfile'"
          class="add-profile-btn"
          type="button"
          @click="$router.push({ name: 'CreateOtherProfile' })"
        >
          <span class="circle" />
          Add profile
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { userApi } from '@/enitites/user'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useImage } from '@/shared/composables/image'
import { HeaderUserMenuLogoutButton } from '@/features/authorization'

const { getAvatar } = useImage()

const getUserDB = computed(() => store.getters.getUserDB)
const account = ref(getUserDB.value.id)
const isShowSwitchPanel = ref(false)
const router = useRouter()

watch(getUserDB, (value) => {
  account.value = value.id
})

async function fetchSelectedProfile(id: string) {
  isShowSwitchPanel.value = false
  localStorage.setItem('currentProfileId', id)
  const userDB = await userApi.getCurrentUser()
  await store.dispatch('setUserDB', userDB)
  router.go(0)
}

function openSwitchPanel() {
  isShowSwitchPanel.value = !isShowSwitchPanel.value
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.user-alert {
  position: relative;
  box-shadow: var(--secondary-box-shadow);
}

.main-photo {
  margin-bottom: 10px;
}

.actions {
  margin-top: 18px;

  .profile-button,
  .settings-button {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background-color: var(--color-blue-800);
    border-radius: 6px;

    .icon {
      margin-top: -2px;
    }

    .text {
      @include font-body1;

      font-weight: var(--font-weight-bold);
      text-transform: uppercase;
    }
  }

  .settings-button {
    margin-top: 12px;
  }

  .logout-button-container {
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }
}

.btn ::v-deep .v-btn__content {
  gap: 8px;
}

.switch-account {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  padding: 40px 16px;
  overflow-y: auto;
  background-color: var(--color-white);

  ::v-deep .v-radio {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin: 0;
  }

  ::v-deep .v-label {
    height: auto;
  }

  ::v-deep .v-input--radio-group__input {
    gap: 32px;
  }

  ::v-deep .v-input--selection-controls {
    margin-top: 30px;
  }

  .label {
    display: flex;
    gap: 8px;
    align-items: center;
    color: var(--color-dark-900);
  }

  .btn-back {
    display: flex;
    align-items: center;
    font-weight: var(--font-weight-bold);
  }
}

.add-profile-btn {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 16px;

  .circle {
    position: relative;
    width: 48px;
    height: 48px;
    background-color: var(--color-gold-900);
    border-radius: 50%;
  }

  .circle::after,
  .circle::before {
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 10px);
    width: 20px;
    height: 2px;
    background-color: var(--color-white);
    content: '';
  }

  .circle::before {
    transform: rotate(90deg);
  }
}
</style>
