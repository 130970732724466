<template>
  <div>
    <div class="search">
      <BKInput
        v-model="key"
        :append-icon="mdiMagnify"
        :label="labelText"
        autocomplete="off"
        background-color="white"
        hide-details
        outlined
        single-line
        @blur="handlerBlur"
        @focus="search"
        @keyup.prevent="search($event)"
      />
      <v-card class="search__list">
        <v-list-item
          v-for="user in users"
          :key="user.id"
          :disabled="isUserDisabled(user.id)"
          class="user-list px-5"
          @click="pickUser(user)"
        >
          <v-list-item-avatar>
            <img
              :alt="user.dancerName"
              :src="getAvatar(user.avatar, 100)"
            />
          </v-list-item-avatar>
          <v-list-item-content> {{ user.dancerName }}</v-list-item-content>
        </v-list-item>
      </v-card>
    </div>

    <div
      v-if="!hideInvitation"
      class="mt-4"
    >
      <p class="search__subtitle-email mt-4">
        Not on Break Konnect? Invite them to join via
        <!--      eslint-disable vuejs-accessibility/click-events-have-key-events-->
        <a @click="isShowEmailSend = !isShowEmailSend">email</a>
        <!--      eslint-disable vuejs-accessibility/click-events-have-key-events-->
      </p>
      <div v-if="isShowEmailSend">
        <v-text-field
          ref="email"
          v-model="email"
          :rules="[rules.email]"
          autocomplete="off"
          background-color="#fff"
          class="registraton__text-field"
          label="Enter email"
          outlined
          @input="$nextTick(() => (email = email.trim()))"
        />
        <div class="d-flex justify-end">
          <v-btn
            class="text-none"
            color="var(--color-blue-800)"
            text
            @click="isShowEmailSend = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="sendEmail"
            :loading="sendEmail"
            class="text-none"
            color="var(--color-blue-800)"
            text
            @click="handlerClickInvite"
          >
            Send
          </v-btn>
        </div>
      </div>
    </div>

    <v-chip
      v-for="(selection, i) in selectUserChip"
      :key="selection.id + i + selection.dancerName"
      class="chip my-2 pr-6 search__users"
      color="transparent"
      large
      pill
    >
      <v-badge
        v-if="isBattle && !selection.confirmed"
        bottom
        color="#ff6f00"
        offset-x="20"
        offset-y="17"
      >
        <span slot="badge">?</span>
        <v-avatar
          class="flex-shrink-0"
          left
        >
          <img
            :alt="selection.dancerName"
            :src="getAvatar(selection.avatar, 100)"
          />
        </v-avatar>
      </v-badge>
      <v-avatar
        v-else
        class="flex-shrink-0"
        left
      >
        <img
          :alt="selection.dancerName"
          :src="getAvatar(selection.avatar, 100)"
        />
      </v-avatar>
      <span class="text-chip">{{ selection.dancerName }}</span>
      <v-icon
        v-if="getIsUserRemovable(selection.id)"
        class="chip__close-icon"
        color="red"
        @click="removeUser(selection)"
      >
        {{ mdiMinusCircleOutline }}
      </v-icon>
    </v-chip>
  </div>
</template>

<script>
import Images from '@/mixins/image'
import BKInput from '@/components/AppShared/BKInput.vue'
import { mdiMagnify, mdiMinusCircleOutline } from '@mdi/js'
import { userApi } from '@/enitites/user'

export default {
  name: 'BKUserSelect',
  components: {
    BKInput,
  },
  mixins: [Images],
  props: {
    labelText: {
      type: String,
      default: 'Select user',
    },
    selectUserChip: {
      type: Array,
      default: () => [],
    },
    role: {
      type: String,
      required: true,
    },
    event: {
      type: Object,
      default: null,
    },
    isBattle: {
      type: Boolean,
      default: false,
    },
    exclude: {
      type: Array,
      default: () => [],
    },
    disable: {
      type: Array,
      default: () => [],
    },
    nonRemovable: {
      type: Array,
      default: () => [],
    },
    hideInvitation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiMinusCircleOutline,
      mdiMagnify,
      key: '',
      loading: false,
      users: [],
      isFirstLoading: false,
      isShowEmailSend: false,
      email: '',
      sendEmail: false,
      rules: {
        required: (value) => !!value || '',
        email: (value) => {
          if (value.length) {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          }

          return false
        },
      },
    }
  },
  methods: {
    handlerClickInvite() {
      // TODO: Ask Felix about this
      console.log('INVITE')
    },
    isUserDisabled(userId) {
      return this.disable.some((id) => id === userId)
    },
    handlerBlur() {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.users = []), 300)
    },
    pickUser(user) {
      const exist = this.selectUserChip.findIndex((u) => u.id === user.id)
      if (exist === -1) {
        this.$emit('selectUser', user, this.role)
      }
      this.users = []
      this.key = ''
    },
    removeUser(user) {
      this.$emit('removeUser', user, this.role)
    },
    getIsUserRemovable(userId) {
      return !this.nonRemovable.some((uId) => uId === userId)
    },
    async search(key = '') {
      if (key.keyCode >= 37 && key.keyCode <= 40) return

      const searchParams = {
        pageNumber: 0,
        pageSize: 100,
        orgIds: 0,
        exclude: this.exclude.join(),
        keyword: this.key,
      }
      const users = await userApi.getPublicUsers(searchParams)
      // TODO: Make user filter by dancerName on BE in next PR

      this.$nextTick(() => {
        this.users = users
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/style/mixins';

.search {
  &__users {
    & .v-badge__badge {
      @include font-caption2;

      min-width: 17px !important;
      height: 17px !important;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.search {
  &.v-badge__badge {
    min-width: 15px !important;
    height: 15px !important;
  }

  position: relative;

  &__subtitle-email {
    @include font-body2;

    color: var(--color-grey-300);
  }

  &__list {
    position: absolute;
    z-index: 10;
    width: 100%;
    max-height: 450px;
    overflow-y: scroll;
  }
}

.chip {
  display: block;
  width: 100%;
  max-width: 255px;
  height: 34px !important;

  &__close-icon {
    position: absolute;
    right: -2px;
  }
}

.text-chip {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
