<template>
  <section class="create-organization">
    <div class="background" />
    <div class="wrap">
      <div class="mb-12">
        <h2 class="create-org-title page-title">Create Organization</h2>
      </div>
      <transition
        appear
        mode="out-in"
      >
        <component
          :is="stages[currentStage]"
          v-if="org"
          :component="stages[currentStage]"
          :org.sync="org"
          class="step-wrap"
          @back="toPreviousStage"
          @end="publishOrg"
          @next="toNextStage"
          @update="updateOrg"
        />
      </transition>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { fetchOrganization, updateOrganization } from '@/modules/Organizations/endpoints'
import { Organization } from '@/modules/Organizations/types'
import CreateOrganizationAdmins from '@/modules/Organizations/components/create-organization/CreateOrganizationAdmins.vue'
import CreateOrganizationInfo from '@/modules/Organizations/components/create-organization/CreateOrganizationInfo.vue'
import CreateOrganizationPromoters from '@/modules/Organizations/components/create-organization/CreateOrganizationPromoters.vue'

const router = useRouter()
const route = useRoute()

const stages = [CreateOrganizationInfo, CreateOrganizationAdmins, CreateOrganizationPromoters]
const currentStage = ref(0)
const org = ref<Organization>()

const updateOrg = async () => {
  if (!org.value) return
  const updatedOrg = await updateOrganization(org.value)
  if (updatedOrg) {
    currentStage.value += 1
  }
}

const toNextStage = () => {
  const nextStage = currentStage.value + 1
  if (stages[nextStage]) {
    currentStage.value = nextStage
  }
}

const toPreviousStage = () => {
  const previousStage = currentStage.value - 1
  if (stages[previousStage]) {
    currentStage.value = previousStage
  }
}

const publishOrg = async () => {
  if (!org.value) return
  org.value.status = 'published'
  const publishedOrg = await updateOrganization(org.value)
  if (publishedOrg) {
    await router.push({ name: 'Organization', params: { id: String(org.value.id) } })
  }
}
onMounted(async () => {
  const orgId = route.params.id
  org.value = await fetchOrganization(orgId)
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.create-organization {
  height: 100%;
  margin-top: 80px;
  background-color: #fff;

  .background {
    height: 488px;
    background: #fff url('/img/HeroCE.jpg') 60% 95% / cover no-repeat;
  }

  .wrap {
    max-width: 515px;
    margin: -350px auto 0;

    .create-org-title {
      @include font-h2;

      text-align: center;
      text-transform: uppercase;
    }

    .step-wrap {
      padding: 0 23px;
    }
  }
}
</style>
