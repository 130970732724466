<script lang="ts" setup>
import { Organization } from '@/modules/Organizations/types'
import { useImage } from '@/shared/composables/image'

const { getOrgImage } = useImage()
defineProps<{
  org: Organization
}>()
</script>

<template>
  <div class="org">
    <v-card
      :to="{ name: 'Organization', params: { id: org.id } }"
      class="org-card"
    >
      <v-img
        :src="getOrgImage(org.image, 300)"
        class="image"
      />
    </v-card>
    <span class="org-name">
      {{ org.name }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.org {
  width: 305px;
  margin: 14px 17px;

  .org-card {
    width: 305px;
    height: 305px;
    border-radius: var(--border-radius-rounded) !important;
  }

  .image {
    width: 100%;
    height: 100%;
  }
}

.org-name {
  display: block;
  margin-top: 8px;
  color: var(--color-white);
  font-weight: var(--font-weight-bold);
  text-align: center;
}
</style>
