const validationRules = {
  data() {
    return {
      rules: {
        required: (value) => (!!value && !!value.trim()) || '',
        email: (value) => {
          const emailPattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return emailPattern.test(value) || 'Invalid e-mail'
        },
        birthday: (value) => {
          const pattern = /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/
          return pattern.test(value) || 'Date format mm/dd/yyyy'
        },
      },
    }
  },
}

export default validationRules
