<script lang="ts" setup>
import { ISparring } from '@/types/sparring'
import { computed, ref } from 'vue'
import BKSvgIcon from '@/components/AppShared/BKSvgIcon.vue'
import { IBattle } from '@/types/battle'

const emit = defineEmits<{ (e: 'select-winner', value: number): void }>()

interface IProps {
  sparring: ISparring
  battle: IBattle
  selectedWinner: number
  disabled?: boolean
  isSpectator?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  disabled: false,
  isSpectator: false,
})
const participantsArray = computed(() => [props.sparring.red, props.sparring.blue])
const selectedWinner = ref<number>(props.selectedWinner)
const isFinishedSparring = computed(() => props.sparring.status === 'finished')
</script>

<template>
  <section class="scoreless-sparring">
    <ul class="participants-list">
      <li
        v-for="participant in participantsArray"
        :key="participant.id"
        :class="['participant', { winner: participant.id === props.sparring.winnerId }]"
      >
        <BKSvgIcon
          :color="participant.id === props.selectedWinner ? 'var(--color-gold-900)' : 'transparent'"
          class="icon"
          height="24px"
          name="crown"
          width="24px"
        />
        <span :class="['name', { 'lose-name': participant.id !== props.sparring.winnerId && isFinishedSparring }]">
          {{ participant.name || 'No name' }}
        </span>
        <label
          :class="['label', { disabled: isFinishedSparring }]"
          :for="participant.id"
        >
          <input
            :id="participant.id"
            v-model="selectedWinner"
            :disabled="disabled"
            :value="participant.id"
            class="checkbox"
            type="radio"
            @change="emit('select-winner', participant.id)"
          />
          <span class="select-winner-title">Winner</span>
        </label>
      </li>
    </ul>
    <div class="actions">
      <slot name="actions" />
      <BKButton
        :color="isSpectator ? 'var(--color-blue-800)' : 'transparent'"
        :to="{ name: 'Brackets', params: { id: battle.id } }"
        :white-text="isSpectator"
        class="back-link"
      >
        Back
      </BKButton>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.scoreless-sparring {
  .participants-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-bottom: 20px;
    padding: 0 !important;

    .participant {
      display: grid;
      gap: 12px;

      .icon {
        justify-self: center;
      }

      .name {
        margin-bottom: 4px;
        font-weight: var(--font-weight-bold);
        text-align: center;
      }

      .name.lose-name {
        font-weight: var(--font-weight-normal);
      }

      .label {
        cursor: pointer;

        .select-winner-title {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 110px;
          background-color: var(--color-grey-700);
          border: 1px solid var(--color-dark-800);
          border-radius: 6px;
          transition: background-color 0.3s ease-in-out;
        }

        &.disabled {
          cursor: default;
          pointer-events: none;

          .select-winner-title {
            color: var(--color-grey-500);
            border: none;
          }
        }

        .checkbox {
          @include visually-hidden;

          appearance: none;

          &:checked + .select-winner-title {
            background-color: var(--color-gold-800);
            border-color: var(--active-checkbox-border-color);
          }
        }
      }

      &.winner {
        .icon {
          color: var(--color-green);
        }

        .label.disabled {
          .select-winner-title {
            color: var(--color-white);
            background-color: var(--color-green);
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .finish-btn {
      max-width: 400px;

      &.v-btn.v-btn--disabled.v-btn--has-bg {
        color: var(--color-white) !important;
        background-color: #c9ced5 !important;
      }
    }

    .back-link {
      max-width: 400px;
      margin-top: 12px;
      color: var(--color-blue-800);
    }
  }
}
</style>
