<template>
  <BKLoader v-if="loading" />
  <section
    v-else
    class="content"
  >
    <template v-if="battle">
      <transition
        mode="out-in"
        name="slide-fade"
      >
        <router-view
          v-if="creatingGroupsRoute"
          :battle="battle"
          :groups-amount="filteredParticipantsByGroups.length"
          @update:battle="initBattle"
        />
      </transition>
      <section
        v-if="!creatingGroupsRoute"
        class="wrapper"
      >
        <BattleHeading
          :battle="battle"
          :event-id="event.id"
          :is-there-groups="!!filteredParticipantsByGroups.length"
        />
        <template v-if="!groupInfoRoute">
          <div
            v-if="filteredParticipantsByGroups.length > 1"
            class="group-header"
          >
            <div
              v-if="$route.query.prelimsViewMode === 'groups'"
              class="group-info"
            >
              <span
                v-if="filteredParticipantsByGroups.length"
                class="group-amount"
              >
                {{ getPluralString(filteredParticipantsByGroups.length, 'group') }}
              </span>
              <span class="participants-amount">
                {{ getPluralString(totalParticipants, participantsType) }}
              </span>
            </div>
            <div
              v-if="filteredParticipantsByGroups.length"
              class="modes-container"
            >
              <button
                :class="['mode-button', 'group-mode-btn', { active: prelimsViewMode === 'groups' }]"
                type="button"
                @click="$router.push({ query: { prelimsViewMode: 'groups' } })"
              >
                <!-- eslint-disable max-len @stylistic/js/max-len -->
                <svg
                  class="icon"
                  fill="none"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    d="M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V9C21 10.1046 20.1046 11 19 11H5C3.89543 11 3 10.1046 3 9V5ZM3 11V14C3 15.1046 3.89543 16 5 16H19C20.1046 16 21 15.1046 21 14V11C21 12.1046 20.1046 13 19 13H5C3.89543 13 3 12.1046 3 11ZM3 19V16C3 17.1046 3.89543 18 5 18H19C20.1046 18 21 17.1046 21 16V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19Z"
                    fill-rule="evenodd"
                  />
                </svg>
                <!--  eslint-enable  max-len -->
                Groups
              </button>
              <button
                :class="['mode-button', 'list-mode-btn', { active: prelimsViewMode === 'list' }]"
                type="button"
                @click="$router.push({ query: { prelimsViewMode: 'list' } })"
              >
                <!-- eslint-disable max-len @stylistic/js/max-len -->
                <svg
                  class="icon"
                  fill="none"
                  height="20"
                  viewBox="0 0 20 20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_34_67800)">
                    <path
                      clip-rule="evenodd"
                      d="M3.33333 5.83329C3.79357 5.83329 4.16667 5.4602 4.16667 4.99996C4.16667 4.53972 3.79357 4.16663 3.33333 4.16663C2.8731 4.16663 2.5 4.53972 2.5 4.99996C2.5 5.4602 2.8731 5.83329 3.33333 5.83329ZM6.66667 4.16663C6.20643 4.16663 5.83333 4.53972 5.83333 4.99996C5.83333 5.4602 6.20643 5.83329 6.66667 5.83329H16.6667C17.1269 5.83329 17.5 5.4602 17.5 4.99996C17.5 4.53972 17.1269 4.16663 16.6667 4.16663H6.66667ZM6.66667 9.16663C6.20643 9.16663 5.83333 9.53972 5.83333 9.99996C5.83333 10.4602 6.20643 10.8333 6.66667 10.8333H16.6667C17.1269 10.8333 17.5 10.4602 17.5 9.99996C17.5 9.53972 17.1269 9.16663 16.6667 9.16663H6.66667ZM5.83333 15C5.83333 14.5397 6.20643 14.1666 6.66667 14.1666H16.6667C17.1269 14.1666 17.5 14.5397 17.5 15C17.5 15.4602 17.1269 15.8333 16.6667 15.8333H6.66667C6.20643 15.8333 5.83333 15.4602 5.83333 15ZM4.16667 9.99996C4.16667 10.4602 3.79357 10.8333 3.33333 10.8333C2.8731 10.8333 2.5 10.4602 2.5 9.99996C2.5 9.53972 2.8731 9.16663 3.33333 9.16663C3.79357 9.16663 4.16667 9.53972 4.16667 9.99996ZM3.33333 15.8333C3.79357 15.8333 4.16667 15.4602 4.16667 15C4.16667 14.5397 3.79357 14.1666 3.33333 14.1666C2.8731 14.1666 2.5 14.5397 2.5 15C2.5 15.4602 2.8731 15.8333 3.33333 15.8333Z"
                      fill-rule="evenodd"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_34_67800">
                      <rect
                        fill="white"
                        height="20"
                        width="20"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <!--  eslint-enable  max-len @stylistic/js/max-len -->
                List
              </button>
            </div>
          </div>
          <template v-if="prelimsViewMode === 'list'">
            <PrelimsCrews
              v-if="isCrewBattle && battle"
              :battle.sync="battle"
              :event="event"
            >
              <template #title>
                <div class="group-info">
                  <span
                    v-if="filteredParticipantsByGroups.length"
                    class="group-amount"
                  >
                    {{ getPluralString(filteredParticipantsByGroups.length, 'group') }}
                  </span>
                  <span class="participants-amount">
                    {{ getPluralString(totalParticipants, participantsType) }}
                  </span>
                </div>
              </template>
            </PrelimsCrews>

            <Prelims
              v-else
              :battle.sync="battle"
              :event="event"
            >
              <template #title>
                <div class="group-info">
                  <span
                    v-if="filteredParticipantsByGroups.length"
                    class="group-amount"
                  >
                    {{ getPluralString(filteredParticipantsByGroups.length, 'group') }}
                  </span>
                  <span class="participants-amount"> {{ getPluralString(totalParticipants, participantsType) }} </span>
                </div>
              </template>
            </Prelims>
          </template>
          <template v-else>
            <PrelimsGroups
              v-if="filteredParticipantsByGroups.length > 1"
              :battle.sync="battle"
              :event="event"
              :groups-list="filteredParticipantsByGroups"
            />
          </template>
        </template>

        <router-view
          v-if="groupInfoRoute && filteredParticipantsByGroups.length > 1"
          :battle.sync="battle"
          :event="event"
          :groups-list="filteredParticipantsByGroups"
          @update:battle="initBattle"
        />
      </section>
    </template>
  </section>
</template>

<script>
import BattleHeading from '@/components/Battle/BattleHeading.vue'
import PrelimsCrews from '@/components/Battle/Prelims/PrelimsCrews.vue'
import Prelims from '@/components/Battle/Prelims/BattlePrelims.vue'
import { mapActions, mapGetters } from 'vuex'
import api from '@/api'
import PrelimsGroups from '@/components/Battle/Prelims/Groups/PrelimsGroups.vue'
import { filterAndFormatCrews, filterAndFormatParticipants } from '@/services/groups'
import { getPluralString } from '@/services/pluralization'

export default {
  name: 'PrelimsPage',
  components: {
    PrelimsGroups,
    BattleHeading,
    PrelimsCrews,
    Prelims,
  },
  data() {
    return {
      loading: true,
      battle: null,
      event: null,
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    isCrewBattle() {
      if (!this.battle) return false
      return this.battle.crewSize !== 1
    },
    creatingGroupsRoute() {
      return this.$route.name === 'CreatingPrelimsGroups'
    },
    prelimsViewMode() {
      return this.$route.query.prelimsViewMode
    },
    groupInfoRoute() {
      return this.$route.name === 'Group'
    },
    filteredParticipantsByGroups() {
      if (this.battle && this.battle.crews && this.battle.crews.length) {
        const checkedInCrews = this.battle.crews.filter((crew) =>
          crew.participants.every((participant) => participant.checkedIn)
        )
        return filterAndFormatCrews(checkedInCrews)
      }

      if (this.battle && this.battle.participants && this.battle.participants.length) {
        const checkedInParticipants = this.battle.participants.filter((participant) => participant.checkedIn)
        return filterAndFormatParticipants(checkedInParticipants)
      }
      return []
    },
    totalParticipants() {
      if (this.battle && this.battle.crews && this.battle.crews.length) {
        return this.battle.crews.filter((crew) => crew.participants.every((participant) => participant.checkedIn))
          .length
      }

      if (this.battle && this.battle.participants && this.battle.participants.length) {
        return this.battle.participants.filter((participant) => participant.checkedIn).length
      }
      return 0
    },
    participantsType() {
      if (this.battle && this.battle.crews && this.battle.crews.length) {
        return 'crew'
      }

      if (this.battle && this.battle.participants && this.battle.participants.length) {
        return 'participant'
      }
      return ''
    },
  },
  watch: {
    filteredParticipantsByGroups(newVal) {
      if (newVal.length === 1 && this.battle.canEdit) {
        this.removeGroups()
        if (this.$route.query.prelimsViewMode === 'list') return
        this.$router.push({ name: 'Prelims', query: { prelimsViewMode: 'list' } })
      }
    },
  },
  async mounted() {
    this.loading = true
    await this.initBattle()
    await this.initEvent()

    this.loading = false
  },
  methods: {
    getPluralString,
    ...mapActions(['setIsNotFound']),
    async initBattle() {
      const battle = await api.battles.getBattle(this.$route.params.id)
      if (!battle) {
        this.setIsNotFound(!this.event)
        return
      }

      this.battle = battle
    },
    async initEvent() {
      if (!this.battle) return
      const event = await api.events.getEvent(this.battle.eventId)
      if (!event) {
        this.setIsNotFound(true)
        return
      }

      this.event = event
    },
    async removeGroups() {
      await api.prelims.createGroups(this.battle.id, { amount: 0, type: 'random' })
      await this.initBattle()
      await this.initEvent()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.content {
  @include page-with-content;

  padding-top: 78px;

  .group-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 20px;
  }

  .group-info {
    display: grid;

    .group-amount {
      @include font-h4;

      font-weight: var(--font-weight-bold);
    }

    .participants-amount {
      @include font-h4;

      color: var(--color-blue-900);
      font-weight: var(--font-weight-bold);
    }

    .group-amount ~ .participants-amount {
      @include font-body2;

      color: var(--color-grey-300);
      font-weight: var(--font-weight-normal);
    }
  }

  .modes-container {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-left: auto;

    .mode-button {
      @include font-caption1;

      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      justify-content: center;
      background-color: var(--color-grey-700);
      border-radius: 6px;
      transition:
        background-color 0.25s,
        color 0.25s;

      .icon {
        width: 20px;
        fill: var(--color-dark-800);
      }
    }

    .mode-button.active {
      color: var(--color-grey-700);
      background-color: var(--color-grey-300);

      .icon {
        fill: var(--color-grey-700);
      }
    }

    .group-mode-btn {
      padding: 8px;
    }

    .list-mode-btn {
      padding: 8px 17px;
    }
  }
}
</style>
