<template>
  <section class="results-editor">
    <h2 class="subtitle">Add Results</h2>

    <div
      v-for="place in places"
      :key="place.id"
    >
      <template v-if="place.isShow">
        <span
          :class="{
            winner: place.id <= 2,
            first: place.id === 1,
            second: place.id === 2,
          }"
          class="select-label"
        >
          <i
            v-if="place.id <= 2"
            class="mr-1 icon-Trophy"
          />
          {{ 2 >= place.id ? `Add ${place.title}` : place.title }}
        </span>
        <div
          v-for="(field, index) in place.fields"
          :key="index"
        >
          <v-autocomplete
            v-model="field.winner"
            :append-icon="mdiMagnify"
            :items="crews"
            clearable
            item-disabled="isDisabled"
            item-text="name"
            outlined
            placeholder="Competitors"
            return-object
            @change="updateCrewsStatus"
          >
            <template v-slot:item="{ item }">
              <div class="col-12 px-0 d-flex justify-space-between">
                <div class="ma-1">
                  {{ item.name }}
                </div>
                <div>
                  <v-avatar
                    v-for="user in item.participants"
                    :key="user.id"
                    class="ma-1"
                    size="30"
                  >
                    <v-img
                      :alt="`${user.dancerName}'s avatar`"
                      :src="getAvatar(user.avatar, 30)"
                    />
                  </v-avatar>
                </div>
              </div>
            </template>

            <template v-slot:selection="{ item }">
              <div class="d-flex flex-column">
                <div class="ma-1">
                  {{ item.name }}
                </div>
                <div>
                  <v-avatar
                    v-for="user in item.participants"
                    :key="user.id"
                    class="ma-1"
                    size="30"
                  >
                    <v-img
                      :alt="`${user.dancerName}'s avatar`"
                      :src="getAvatar(user.avatar, 30)"
                    />
                  </v-avatar>
                </div>
              </div>
            </template>
          </v-autocomplete>
        </div>
      </template>
    </div>

    <v-btn
      v-if="isNextPlaceFieldExist"
      text
      @click="setNextPlaceFieldsVisible"
    >
      Add top {{ lastShowedPlace * 2 }}
      <v-icon class="ml-2">
        {{ mdiPlus }}
      </v-icon>
    </v-btn>

    <div class="actions">
      <BKButton
        v-if="battleWinners.length"
        class="action-btn"
        color="var(--color-blue-800)"
        outlined
        @click="$emit('cancelEditWinners')"
      >
        Cancel
      </BKButton>
      <BKButton
        :disabled="loading"
        :loading="loading"
        class="action-btn"
        color="var(--color-blue-800)"
        white-text
        @click="saveWinners"
      >
        Save
      </BKButton>
    </div>
  </section>
</template>

<script>
import Images from '@/mixins/image'
import { BATTLE_PLACES } from '@/common/constants'
import api from '@/api'
import { mdiMagnify, mdiPlus } from '@mdi/js'

export default {
  name: 'BattleWinnerEditorForCrews',
  mixins: [Images],
  props: {
    battle: {
      type: Object,
      required: true,
    },
    battleWinners: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      mdiMagnify,
      mdiPlus,
      valid: true,
      loading: false,
      lastShowedPlace: 2,
      places: BATTLE_PLACES,
      crews: [],
    }
  },
  computed: {
    isNextPlaceFieldExist() {
      return !!this.places.some((place) => place.id === this.lastShowedPlace * 2)
    },
    battleWinnersMaxPlace() {
      return this.battleWinners.reduce((max, winner) => (winner.place > max ? winner.place : max), 0)
    },
  },
  created() {
    this.crews = this.battle.crews
  },
  mounted() {
    this.initFieldsInPlaces()
  },
  methods: {
    async saveWinners() {
      let winners = []

      this.places.forEach((p) => {
        p.fields.forEach((f) => {
          if (!f.winner) return
          winners = [...winners, { id: f.winner.id, place: f.place }]
        })
      })

      this.loading = true
      const updatedBattle = await api.battles.setWinners(this.battle.id, winners)
      if (updatedBattle) {
        this.$emit('update:battle', updatedBattle)
        if (updatedBattle.winners) this.$emit('closeEditWinners')
      }
      this.loading = false
    },
    setNextPlaceFieldsVisible() {
      this.lastShowedPlace *= 2
      this.places.forEach((place) => {
        // eslint-disable-next-line no-param-reassign
        if (place.id === this.lastShowedPlace) place.isShow = true
      })
    },
    updateCrewsStatus() {
      this.crews = this.battle.crews.map((cr) => {
        // eslint-disable-next-line no-param-reassign
        cr.isDisabled = this.places.some((p) => p.fields.some((f) => f.winner && f.winner.id === cr.id))
        return cr
      })
    },
    initFieldsInPlaces() {
      this.lastShowedPlace = this.battleWinnersMaxPlace || 2

      this.places = this.places.map((place, index) => {
        const previousPlaceId = this.places[index - 1] ? this.places[index - 1].id : 0
        const countPlacesToPush = place.id - previousPlaceId

        // eslint-disable-next-line no-param-reassign
        place.isShow = place.id <= (this.battleWinnersMaxPlace || 2)

        // eslint-disable-next-line no-param-reassign
        place.fields = []

        let placeWinners = this.battleWinners.filter((w) => w.place === place.id)
        placeWinners = placeWinners.length ? placeWinners.map((w) => w.id) : []
        let placeWinnerIndex = 0

        for (let i = 0; i < countPlacesToPush; i++) {
          if (placeWinners.length) {
            // eslint-disable-next-line no-loop-func
            const winner = this.battle.crews.find((p) => p.id === placeWinners[placeWinnerIndex])
            // eslint-disable-next-line no-plusplus
            placeWinnerIndex++
            place.fields.push({
              winner,
              place: place.id,
            })
          } else {
            place.fields.push({
              winner: null,
              place: place.id,
            })
          }
        }

        return place
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.results-editor {
  position: relative;
  padding: 16px;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-600);
  border-radius: 6px;

  .actions {
    display: flex;
    gap: 16px;
    margin-top: 16px;

    .action-btn {
      flex: 1 1 50%;
    }
  }

  .subtitle {
    @include font-h3;

    margin-bottom: 8px;
    color: var(--color-dark-800);
    font-weight: bold;
    text-transform: uppercase;
  }

  .select-label {
    @include font-h4;

    display: block;
    margin-bottom: 5px;
    color: var(--color-dark-800);
    font-weight: var(--font-weight-bold);

    &.winner {
      font-weight: var(--font-weight-normal);
    }

    & .v-icon {
      font-size: 20px;
    }

    &.first {
      color: #8c7328;
    }

    &.second {
      color: #999;
    }
  }
}
</style>
