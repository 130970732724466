import { api } from '@/plugins/axios'

// eslint-disable-next-line consistent-return,import/prefer-default-export
export const callPrivateApi = async (method, url, ...args) => {
  try {
    const { data } = await api[method](url, ...args)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}
