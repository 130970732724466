const pluralRules = new Intl.PluralRules(undefined, { type: 'cardinal' })

// eslint-disable-next-line import/prefer-default-export
export const getPluralString = (number: number, word: string) => {
  const pluralCategory = pluralRules.select(number)

  switch (pluralCategory) {
    case 'one':
      return `${number} ${word}`
    case 'other':
      return `${number} ${word}s`
    default:
      return `${number} ${word}s`
  }
}
