<template>
  <footer class="footer">
    <router-link
      class="footer__logo-link"
      to="/"
    >
      <img
        alt="Break Konnect"
        class="footer__logo"
        src="/img/FooterLogo.svg"
      />
    </router-link>
    <div class="footer__social">
      <a
        class="footer__social-link social-link"
        href="https://twitter.com/BreakKonnect"
        target="_blank"
        title="Our Twitter page"
      >
        <v-icon class="social-link__icon">{{ mdiTwitter }}</v-icon>
      </a>
      <a
        class="footer__social-link social-link"
        href="https://www.facebook.com/Break-Konnect-100873084639924"
        target="_blank"
        title="Our Facebook page"
      >
        <v-icon class="social-link__icon">{{ mdiFacebook }}</v-icon>
      </a>
      <a
        class="footer__social-link social-link"
        href="https://www.instagram.com/break_konnect/"
        target="_blank"
        title="Our Instagram account"
      >
        <v-icon class="social-link__icon">{{ mdiInstagram }}</v-icon>
      </a>
      <a
        class="footer__social-link social-link"
        href="mailto:hello@breakkonnect.com"
        title="Send email to us"
      >
        <v-icon class="social-link__icon">{{ mdiEmailOutline }}</v-icon>
      </a>
    </div>
    <span class="footer__by">
      Brought to you by
      <a
        class="footer__by-link"
        href="http://elosostudios.com/"
        target="_blank"
        title="EL OSO STUDIOS"
      >
        elosostudios.com
      </a>
    </span>
    <div class="footer__tos-links">
      <router-link
        class="footer__tos"
        to="/policy"
      >
        Privacy Policy
      </router-link>
      <span>|</span>
      <router-link
        class="footer__tos"
        to="/terms"
      >
        Terms of Service
      </router-link>
    </div>
    <span class="footer__copyright">&copy; {{ currentYear }} all rights reserved</span>
  </footer>
</template>

<script lang="ts" setup>
import { mdiEmailOutline, mdiFacebook, mdiInstagram, mdiTwitter } from '@mdi/js'

const currentYear = new Date().getFullYear()
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

$grey: #aaa;

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -1px;
  padding: 100px 0;
  color: $grey;
  font-family: Roboto, Helvetica, sans-serif;
  background: #121314 url('/img/footer-bg.jpg') 50% 50% / cover no-repeat;

  &__logo-link {
    width: 100%;
    max-width: 210px;
    margin-bottom: 35px;
  }

  &__logo {
    width: 100%;
  }

  &__logo-img {
    margin-bottom: -3px;
  }

  &__social {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 180px;
    margin-bottom: 35px;
  }

  .social-link {
    text-decoration: none;

    &__icon {
      margin-bottom: -3px;
      color: $grey;
    }
  }

  &__by {
    margin-bottom: 15px;
  }

  &__by-link {
    @include font-body2;

    color: $grey;
    text-decoration: none;
  }

  &__tos {
    @include font-caption1;

    text-decoration: none;
  }

  &__tos-links {
    margin-bottom: 15px;
  }

  &__copyright {
    @include font-caption1;
  }
}
</style>
