<script lang="ts" setup>
import { computed } from 'vue'

interface IProps {
  name: string
  width?: string
  height?: string
  strokeColor?: string
  strokeWidth?: string
  color?: string
  className?: string
}

const props = withDefaults(defineProps<IProps>(), {
  width: '24px',
  height: '24px',
  strokeColor: 'none',
  strokeWidth: '1px',
  color: 'var(--color-white)',
  className: 'svg',
})

const iconPath = computed(() => {
  // eslint-disable-next-line
  let icon = require(`/public/img/ui-icons/${props.name}.svg`)
  if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
    icon = icon.default
  }

  return icon.url
})
</script>

<template>
  <svg
    aria-hidden="true"
    class="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use :href="iconPath" />
  </svg>
</template>

<style lang="scss" scoped>
.svg-icon {
  width: v-bind(width);
  height: v-bind(height);
  color: v-bind(color);
  fill: currentcolor;
  // stylelint-disable-next-line value-keyword-case
  stroke: v-bind(strokeColor);
  // stylelint-disable-next-line value-keyword-case
  stroke-width: v-bind(strokeWidth);
}
</style>
