<template>
  <div>
    <transition
      :name="isClickBtnNext ? 'slide-fade' : 'slide-fade-revers'"
      appear
      mode="out-in"
    >
      <keep-alive>
        <component
          :is="questionsToForms[currentScreen]"
          :current-step="currentScreenIndex"
          :error="error"
          :loading="isLoading"
          :steps="availableForms.length"
          :user="updatedUser"
          @changeAvatar="changeImage($event, 'avatar')"
          @changeCard="changeImage($event, 'card')"
          @goBack="backForm"
          @submitForm="submitForm"
        />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OnboardingFullNameForm from '@/components/Onboarding/OnboardingFullNameForm.vue'
import OnboardingUserNameForm from '@/components/Onboarding/OnboardingUserNameForm.vue'
import OnboardingBirthdayForm from '@/components/Onboarding/OnboardingBirthdayForm.vue'
import OnboardingLocationForm from '@/components/Onboarding/OnboardingLocationForm.vue'
import OnboardingAvatarForm from '@/components/Onboarding/OnboardingAvatarForm.vue'
import OnboardingCardForm from '@/components/Onboarding/OnboardingCardForm.vue'
import { userApi } from '@/enitites/user'

export default {
  components: {
    OnboardingFullNameForm,
    OnboardingUserNameForm,
    OnboardingBirthdayForm,
    OnboardingLocationForm,
    OnboardingAvatarForm,
    OnboardingCardForm,
  },
  props: {
    addToExisting: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isClickBtnNext: true,
      isLoading: false,
      error: '',
      currentScreenIndex: 0,
      updatedUser: {
        fullName: '',
        dancerName: '',
        birthday: '',
        country: '',
        state: '',
        city: '',
        avatar: '',
        card: '',
        canEdit: true,
      },
      questionsToForms: {
        fullName: OnboardingFullNameForm,
        dancerName: OnboardingUserNameForm,
        birthday: OnboardingBirthdayForm,
        location: OnboardingLocationForm,
        avatar: OnboardingAvatarForm,
        card: OnboardingCardForm,
      },
      availableForms: ['fullName', 'dancerName', 'birthday', 'location', 'avatar', 'card'],
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    currentScreen() {
      return this.availableForms[this.currentScreenIndex]
    },
    isLastScreen() {
      return this.currentScreenIndex === this.availableForms.length - 1
    },
  },
  mounted() {
    this.updatedUser = this.getUserDB
    // when user create other profile there is no a player card img
    this.updatedUser.card = ''
  },
  methods: {
    ...mapActions(['setUserDB']),
    async submitForm(value) {
      if (this.currentScreen === 'location') {
        this.updatedUser.country = value.country
        this.updatedUser.state = value.state
        this.updatedUser.city = value.city
      } else if (this.currentScreen !== 'avatar' && this.currentScreen !== 'card') {
        this.updatedUser[this.currentScreen] = value
      }

      if (!this.isLastScreen) {
        this.nextForm()
        return
      }

      this.isLoading = true

      const data = this.addToExisting
        ? await userApi.addOtherProfile(this.updatedUser)
        : await userApi.updateUser(this.updatedUser)

      if (data && this.addToExisting) {
        localStorage.setItem('currentProfileId', data.id)
        this.setUserDB(data)
        this.$router.push({ name: 'Profile', params: { id: data.id } })
      } else if (data && !this.addToExisting) {
        this.setUserDB(this.updatedUser)
        this.$router.push(this.$route.query.redirect || { name: 'Home' })
      } else {
        this.error = 'Something went wrong, please try again later'
      }

      this.isLoading = false
    },
    changeImage(imageId, type) {
      this.updatedUser[type] = imageId
    },
    backForm() {
      this.error = ''
      this.isClickBtnNext = false
      this.currentScreenIndex -= 1
    },
    nextForm() {
      this.error = ''
      this.isClickBtnNext = true
      this.currentScreenIndex += 1
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/slideFade';
</style>
