const battleParticipantJsonReviver = (key, value) => {
  if (key === 'participants') {
    return value.map((participant) => ({
      ...participant,
      agreementSignedAt: participant.agreementSigned ? new Date(participant.agreementSignedAt) : null,
      checkedInAt: participant.checkedIn ? new Date(participant.checkedInAt) : null,
      confirmedAt: participant.confirmed ? new Date(participant.confirmedAt) : null,
      waiverSignedAt: participant.waiverSigned ? new Date(participant.waiverSignedAt) : null,
    }))
  }
  return value
}

export default battleParticipantJsonReviver
