// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as events from './EventsEndpoints'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as workshops from './WorkshopsEndpoints'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as media from './MediaEndpoints'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as battles from './BattlesEndpoints'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as crews from './CrewsEndpoints'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as prelims from './PrelimsEndpoints'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as sparrings from './SparringsEndpoints'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as admin from './AdminEndpoints'

export default {
  events,
  workshops,
  media,
  battles,
  crews,
  sparrings,
  prelims,
  admin,
}
