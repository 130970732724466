<template>
  <ModalWrapper
    :is-show="isShowCancelEventCreation"
    @update:isShow="toggleCancelEventCreation"
  >
    <v-card tile>
      <div class="col-8 py-10 mx-auto">
        <v-container>
          <v-row>
            <v-card-title class="cancel__title"> Cancel Event</v-card-title>
          </v-row>
          <v-card-text class="pa-1"> You can delete the event or save it as a draft and work on it later.</v-card-text>
        </v-container>

        <v-card-actions
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
          class="d-flex"
        >
          <v-btn
            class="px-10 mx-3 my-2"
            color="#f21849"
            height="48"
            outlined
            tile
            @click="removeEvent"
          >
            Delete event
          </v-btn>
          <v-btn
            class="px-10 mx-3 my-2"
            color="#000000"
            height="48"
            outlined
            tile
            @click="saveAsDraftEvent"
          >
            Save as draft
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </ModalWrapper>
</template>

<script>
import api from '@/api'

import { mapActions, mapState } from 'vuex'

import { ModalWrapper } from '@/shared/ui/modals'

export default {
  components: {
    ModalWrapper,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['isShowCancelEventCreation']),
  },

  methods: {
    ...mapActions(['toggleCancelEventCreation']),
    async removeEvent() {
      const resp = await api.events.deleteEvent(this.event.id)
      if (resp) {
        this.toggleCancelEventCreation()
        this.$router.push({ name: 'EventsPage' })
      }
    },
    saveAsDraftEvent() {
      this.$emit('saveAsDraft')
      this.toggleCancelEventCreation()
    },
  },
}
</script>
<style lang="scss" scoped>
.cancel {
  &__title {
    text-transform: uppercase;
  }
}
</style>
