<template>
  <OnboardingFormLayout
    :current-step="currentStep"
    :error="error"
    :steps="steps"
    title="Location"
  >
    <template #default>
      <v-form v-model="valid">
        <BKCountrySelect
          :class="'create-form__field'"
          :country.sync="userLocation.country"
          :hide-details="true"
          :is-show-state.sync="isShowState"
        />
        <div class="inputs-wrapper">
          <v-text-field
            v-model="userLocation.city"
            :rules="[rules.required]"
            autocomplete="off"
            background-color="var(--color-white)"
            hide-details
            label="City*"
            outlined
            @keyup.enter="$emit('submitForm', userLocation)"
          />
          <v-text-field
            v-if="isShowState"
            v-model="userLocation.state"
            :rules="[rules.required]"
            autocomplete="off"
            background-color="var(--color-white)"
            hide-details
            label="State*"
            outlined
            @keyup.enter="$emit('submitForm', userLocation)"
          />
        </div>
      </v-form>
    </template>
    <template #actions>
      <BKButton
        outlined
        @click="$emit('goBack')"
      >
        back
      </BKButton>

      <BKButton
        :disabled="loading || !valid"
        :loading="loading"
        white-text
        @click="$emit('submitForm', userLocation)"
      >
        next
      </BKButton>
    </template>
  </OnboardingFormLayout>
</template>

<script>
import validationRules from '@/mixins/validation'
import BKButton from '@/components/AppShared/BKButton.vue'
import BKCountrySelect from '@/components/AppShared/BKCountrySelect.vue'
import OnboardingFormLayout from '@/components/Onboarding/OnboardingFormLayout.vue'

export default {
  components: {
    BKButton,
    BKCountrySelect,
    OnboardingFormLayout,
  },
  mixins: [validationRules],
  props: {
    currentStep: {
      type: Number,
      default: 0,
    },
    error: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    steps: {
      type: Number,
      default: 0,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userLocation: {
        country: this.user.country || '',
        city: this.user.city || '',
        state: this.user.state || '',
      },
      isShowState: null,
      valid: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.inputs-wrapper {
  display: flex;
  gap: 8px;

  @media screen and (max-width: 460px) {
    flex-direction: column;
    gap: 0;
  }
}
</style>
