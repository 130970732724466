import { callPrivateApi } from '@/api/apiHelper'
import { initBattleParticipants, initBattleResp, initGetEventResp } from '@/api/adapter'

export const registerDancers = async (battleId, userIds) => {
  const data = await callPrivateApi('post', `/admin/battles/${battleId}/register`, { userIds })
  return initBattleResp(data)
}

export const unregisterDancer = async (battleId, userId, crewId) =>
  callPrivateApi('post', `/admin/battles/${battleId}/unregister`, {
    userId,
    crewId,
  })

export const uncheckInDancer = async (eventId, userId) => {
  const data = await callPrivateApi('post', `/admin/events/${eventId}/uncheck/${userId}`)
  return initGetEventResp(data)
}

export const getAvailableDancers = async ({ battleId, pageSize: pp = 50, pageNumber: p = 0, keyword: k }) => {
  const params = { pp, p, k }
  const data = await callPrivateApi('get', `/admin/battles/${battleId}/users`, { params })
  return initBattleParticipants(data)
}

export const signParticipantAgreement = async (battleId, userId) =>
  callPrivateApi('post', `/admin/battles/${battleId}/sign-agreement/${userId}`)

export const signParticipantWaiver = async (battleId, userId) =>
  callPrivateApi('post', `/admin/battles/${battleId}/sign-waiver/${userId}`)

export const acceptDancer = async (battleId, crewId, userId) =>
  callPrivateApi('post', `/admin/crews/${battleId}/${crewId}/accept/${userId}`)

export const declineDancer = async (battleId, crewId, userId) =>
  callPrivateApi('post', `/admin/crews/${battleId}/${crewId}/decline/${userId}`)

export const uncheckDancer = async (eventId, userId) =>
  callPrivateApi('post', `/admin/events/${eventId}/uncheck/${userId}`)

export const unregisterCrew = async (battleId, crewId) =>
  callPrivateApi('post', `/admin/crews/${battleId}/${crewId}/unregister`)

export const setSparringWinner = async ({ sparringId, winnerId }) =>
  callPrivateApi('post', `admin/sparrings/${sparringId}/set-winner/${winnerId}`)
