<script lang="ts" setup>
import { useImage } from '@/shared/composables/image'
import { IEventPreview } from '@/types/event'
import BKImage from '@/components/AppShared/BKImage.vue'

const { getEventImg } = useImage()

function formattedDate(date: Date) {
  return new Date(date).toLocaleDateString('en-US')
}

interface IProps {
  event: IEventPreview
}

const props = defineProps<IProps>()

const srcImg = getEventImg(props.event.image, 190)
</script>

<template>
  <div
    v-if="event"
    :aria-label="`${event.title} card`"
    class="event-preview-card"
  >
    <img
      :src="srcImg"
      alt=""
      aria-hidden="true"
      class="background-img"
    />
    <div class="content">
      <div class="img-container">
        <BKImage
          :src="srcImg"
          alt=""
          class="event-img"
          height="auto"
          width="85"
        />
      </div>
      <div class="data">
        <span v-if="event.title">{{ event.title }}</span>
        <span v-if="event.dateStart">{{ formattedDate(event.dateStart) }}</span>
        <span v-if="event.locationAddress">{{ event.locationAddress }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.event-preview-card {
  position: relative;
  padding: 12px;
  overflow: hidden;
  text-align: left;
  background-color: var(--color-dark-900);
  border-radius: 12px;

  .content {
    position: relative;
    z-index: 1;
    display: flex;
    gap: 12px;
    align-items: center;
    color: var(--color-white);

    .data {
      display: grid;
      gap: 4px;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .event-img {
      border-radius: 6px;
    }

    .img-container {
      width: 85px;
      height: 85px;
    }
  }

  .background-img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(60px);
  }
}
</style>
