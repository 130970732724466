import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line import/no-cycle
import { ImageActions } from '@/services/image'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userDB: null,
    userForChange: null,
    userFB: null,
    mobileMenuHidden: false,
    alertToastMessage: '',
    isShowCancelEventCreation: false,
    alertStateOpen: false,
    userDataInfoFirebase: {},
    eventIdForModal: '',
    browserHistoryState: null,
    isCreateEventFlow: false,
    isNotFound: false,
  },
  getters: {
    getUserDB: (state) => state.userDB,
    getUserForChange: (state) => state.userForChange,
    getUserFB: (state) => state.userFB,
    getAlertToastMessage: (state) => {
      if (!state.alertToastMessage) return null

      const messagesMap = {
        changesSaved: 'Changes has been saved',
        ticketPurchased: 'Ticket has been purchased',
        emailReset: 'Email has been sent to "email" with password reset instructions',
        eventCreated: 'Event Created',
        movedParticipant: 'Move successful',
        bracketingIsReady: 'Ready for bracketing',
      }

      if (messagesMap[state.alertToastMessage]) {
        return { status: 'success', message: messagesMap[state.alertToastMessage] }
      }

      return { status: 'error', message: state.alertToastMessage }
    },
    getIsNotFound: (state) => state.isNotFound,
  },
  mutations: {
    SET_USER_DB(state, userDB) {
      state.userDB = userDB
    },
    SET_USER_FOR_CHANGE(state, user) {
      state.userForChange = JSON.parse(JSON.stringify(user))
    },
    SET_USER_FB(state, userFB) {
      state.userFB = userFB
    },
    TOGGLE_MENU_MOBILE(state) {
      state.mobileMenuHidden = !state.mobileMenuHidden
    },
    SHOW_ALERT_TOAST_MESSAGE(state, message) {
      state.alertToastMessage = message
    },
    changeEventIdForModal(state, id) {
      state.eventIdForModal = id
    },
    toggleCancelEventCreation(state) {
      state.isShowCancelEventCreation = !state.isShowCancelEventCreation
    },
    setUserDataInfoFirebase(state, data) {
      state.userDataInfoFirebase = data
    },
    setBrowserHistoryState(state, data) {
      state.browserHistoryState = data
    },
    setIsCreateEventFlow(state, data) {
      state.isCreateEventFlow = data
    },
    setIsNotFound(state, isNotFound) {
      state.isNotFound = isNotFound
    },
  },
  actions: {
    setUserDB({ commit }, userDB) {
      commit('SET_USER_DB', userDB)
    },
    setCurrentUserForChange({ commit, state }) {
      const userForUpdate = {
        avatarUrl: ImageActions.getImageById(state.userDB.avatar, 200, 200, 'avatar'),
        avatarFile: null,
        ...state.userDB,
      }

      commit('SET_USER_FOR_CHANGE', userForUpdate)
    },
    setUserFB({ commit }, userFB) {
      commit('SET_USER_FB', userFB)
    },
    showAlertToast({ commit }, message) {
      commit('SHOW_ALERT_TOAST_MESSAGE', message)
      setTimeout(() => commit('SHOW_ALERT_TOAST_MESSAGE', ''), 5000)
    },
    async toggleCancelEventCreation({ commit }) {
      commit('toggleCancelEventCreation')
    },
    async setIsCreateEventFlow({ commit }, value) {
      commit('setIsCreateEventFlow', value)
    },
    setIsNotFound({ commit }, isNotFound) {
      commit('setIsNotFound', isNotFound)
    },
  },
})
