<template>
  <!--  eslint-disable  vuejs-accessibility/click-events-have-key-events-->
  <section
    class="breadcrumbs"
    @click="toBrackets"
  >
    <v-icon>
      {{ mdiArrowLeft }}
    </v-icon>
    <span>Brackets</span> /
    <h1 class="breadcrumbs-battle-name">
      {{ title }}
    </h1>
  </section>
  <!--  eslint-enable vuejs-accessibility/click-events-have-key-events-->
</template>

<script>
import { mdiArrowLeft } from '@mdi/js'

export default {
  name: 'SparringBreadcrumbs',
  props: {
    title: {
      type: String,
      required: true,
    },
    bracketsId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      mdiArrowLeft,
    }
  },
  methods: {
    toBrackets() {
      this.$router.push({ name: 'Brackets', params: { id: this.bracketsId } })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.breadcrumbs {
  margin-bottom: 10px;
  color: var(--sky-blue);
  font-weight: var(--font-weight-bold);
  cursor: pointer;

  & .v-icon {
    @include font-body1;

    color: var(--sky-blue);
  }

  & .breadcrumbs-battle-name {
    display: inline;
    color: var(--color-grey-500);
  }
}
</style>
