<template>
  <!--  eslint-disable vuejs-accessibility/no-autofocus-->
  <v-text-field
    ref="input"
    v-model.trim="inputValue"
    :append-icon="appendIcon"
    :append-outer-icon="appendOuterIcon"
    :autofocus="autofocus"
    :color="color"
    :dense="dense"
    :disabled="disabled"
    :error="error"
    :error-messages="errorMessages"
    :hide-details="hideDetails"
    :label="label"
    :max="max"
    :min="min"
    :placeholder="placeholder"
    :prefix="prefix"
    :prepend-inner-icon="prependIcon"
    :readonly="readonly"
    :required="required"
    :rules="rules"
    :type="type"
    autocomplete="autocomplete"
    background-color="var(--color-white)"
    class="app-input"
    outlined
    @blur="$emit('blur')"
    @focus="$emit('focus')"
    @input="$emit('input', inputValue)"
    @keypress.enter.prevent="$emit('pressEnter')"
    @keyup.prevent="$emit('keyup', $event)"
    @click:append="$emit('click:append')"
  >
    <template #append>
      <slot name="append" />
    </template>
  </v-text-field>
  <!--  eslint-enable vuejs-accessibility/no-autofocus-->
</template>

<script>
export default {
  name: 'BKInput',
  props: {
    autocomplete: {
      type: String,
      required: false,
      default: 'off',
    },
    value: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    prefix: {
      type: String,
      required: false,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      required: false,
      default: () => [],
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    appendIcon: {
      type: String,
      required: false,
      default: '',
    },
    prependIcon: {
      type: String,
      required: false,
      default: '',
    },
    appendOuterIcon: {
      type: String,
      required: false,
      default: '',
    },
    hideDetails: {
      type: [String, Boolean],
      required: false,
      default: 'auto',
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: 'var(--color-blue-800)',
    },
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: [Number, String],
      required: false,
      default: '',
    },
  },
  data() {
    return {
      inputValue: this.value,
    }
  },
  watch: {
    value(value) {
      this.inputValue = value
    },
    inputValue() {
      if (this.type === 'number') {
        this.$emit('update:value', this.inputValue ? parseInt(this.inputValue, 10) : '')
        return
      }

      this.$emit('update:value', this.inputValue)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.app-input::v-deep {
  .v-text-field {
    &__slot {
      @include font-body1;
    }
  }

  &.v-text-field--outlined {
    border-color: var(--color-grey-600);
    border-radius: 6px;
  }
}
</style>
