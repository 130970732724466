<template>
  <div class="onboarding-form-layout">
    <p
      v-if="title"
      class="onboarding-form-layout__title"
    >
      {{ title }}
    </p>
    <slot />
    <div
      v-if="steps"
      class="onboarding-form-layout__dots"
    >
      <span
        v-for="i in steps"
        :key="i"
        class="onboarding-form-layout__dot"
        :class="i === currentStep + 1 ? 'onboarding-form-layout__dot--active' : ''"
      />
    </div>
    <span
      v-show="error"
      class="onboarding-form-layout__text-error"
      >{{ error }}</span
    >
    <div class="onboarding-form-layout__actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    steps: {
      type: Number,
      default: 0,
    },
    currentStep: {
      type: Number,
      default: 0,
    },
    error: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.onboarding-form-layout {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 420px;
  margin-bottom: 40px;

  &__title {
    margin-bottom: 8px;
    color: var(--color-dark-800);
  }

  &__actions {
    display: flex;
    gap: 20px;
    width: 100%;

    & > button {
      flex: 1 1 50%;
    }

    @media screen and (max-width: 460px) {
      flex-direction: column-reverse;

      & > button {
        flex: unset;
      }
    }
  }

  &__text-error {
    margin-bottom: 10px;
    color: var(--error-red);
  }

  &__dots {
    display: flex;
    gap: 8px;
    justify-content: center;
    width: 100%;
    margin: 40px 0 20px;
  }

  &__dot {
    width: 8px;
    height: 8px;
    background-color: var(--color-grey-600);
    border-radius: 50%;

    &--active {
      background-color: var(--color-blue-800);
    }
  }

  &::v-deep {
    .v-input {
      margin-bottom: 10px !important;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}
</style>
