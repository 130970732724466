<template>
  <main id="page">
    <v-app>
      <transition
        mode="out-in"
        name="slide-fade"
      >
        <router-view
          v-if="!getIsNotFound"
          class="router-view"
        />
        <ErrorPage v-else />
      </transition>
      <TheCookies />
    </v-app>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import ErrorPage from '@/components/ErrorPage.vue'
import { TheCookies } from '@/widgets/Cookie'

export default {
  name: 'TargetPage',
  components: {
    ErrorPage,
    TheCookies,
  },
  computed: {
    ...mapGetters(['getIsNotFound']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/slideFade';

.router-view {
  flex-grow: 1;
}

#page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
  background-color: rgb(0 0 0);
}
</style>
