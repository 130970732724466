<template>
  <ModalWrapper
    :is-show="isShowModal"
    class="ticket-modal"
    modal-width="500"
    @update:isShow="updateShowModal($event)"
  >
    <section class="ticket">
      <section class="header">
        <div class="img">
          <img
            :src="getEventImg(event.image, 320)"
            alt="event-img"
            class="event-img"
          />
        </div>
        <div class="information">
          <h3>{{ event.title }}</h3>

          <div
            v-if="event.orgChip"
            class="org"
          >
            <div class="img">
              <img
                :src="getOrgImage(event.orgChip.image, 100)"
                alt="organization image"
                class="org-img"
              />
            </div>
            <span class="name">
              {{ event.orgChip.name }}
            </span>
          </div>
          <span class="field">
            {{ date }}
          </span>
          <span class="field">
            {{ time }}
          </span>
          <span class="field">
            {{ event.locationAddress }}
          </span>
        </div>
      </section>

      <section
        v-for="(ticket, index) in event.tickets"
        :key="index"
        class="amount"
      >
        <div class="field">
          <span class="key">{{ ticket.title }}</span>
        </div>
        <div class="field">
          <span class="key">Fee</span>
          <span class="value">{{ ticket.ticketFees }}</span>
        </div>
        <div class="field">
          <span class="key">Taxes</span>
          <span class="value">{{ ticket.ticketTaxes }}</span>
        </div>
        <div class="field-total">
          <span class="key">Total</span>
          <span class="value">{{ ticket.ticketSum }}</span>
        </div>

        <div class="description">
          {{ ticket.description }}
        </div>
      </section>
    </section>
  </ModalWrapper>
</template>

<script>
import { ModalWrapper } from '@/shared/ui/modals'
import Images from '@/mixins/image'
import { getDateRangeFormatted, getTimeRangeFormatted } from '@/services/date'

export default {
  name: 'EventTicket',
  components: {
    ModalWrapper,
  },
  mixins: [Images],
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    event: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    date() {
      return getDateRangeFormatted(this.event.dateStart, this.event.dateEnd)
    },
    time() {
      return getTimeRangeFormatted(this.event.dateStart, this.event.dateEnd)
    },
  },
  async mounted() {
    this.loading = false
  },
  methods: {
    updateShowModal(evt) {
      this.$emit('update:isShowModal', evt)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/style/mixins';

.loader {
  display: flex !important;
  margin: 110px auto;
}

.ticket {
  padding: 20px;
  background-color: var(--color-white);

  @media (max-width: $screen-md) {
    padding-top: 70px;
  }
}

.header {
  display: flex;

  .event-img {
    display: block;
    width: 172px;
    height: 172px;
    border-radius: 4px;
  }

  .org {
    display: flex;
    align-items: center;
    margin: 8px 0;

    .org-img {
      width: 28px;
      height: 28px;
    }

    .name {
      margin-left: 8px;
    }
  }

  .information {
    margin-left: 16px;

    .field {
      display: block;
      margin-top: 8px;
    }
  }
}

.amount {
  margin-top: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-grey-600);

  // stylelint-disable no-descending-specificity
  // TODO: fix stylelint errors

  .field,
  .field-total {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }

  // stylelint-enable no-descending-specificity

  .field-total {
    font-weight: var(--font-weight-bold);
  }

  .description {
    margin-top: 20px;
  }
}
</style>
