<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { mdiArrowLeft } from '@mdi/js'
import { Organization } from '@/modules/Organizations/types'
import { fetchOrganization, updateOrganization } from '@/modules/Organizations/endpoints'

const org = ref<Organization>()
const isLoading = ref(false)

const route = useRoute()
const router = useRouter()

const currentRoute = computed(() => route.matched.find((routeItem) => routeItem.parent))
const childRoutes = computed(
  () => router.options.routes?.find((item) => item.name === 'OrganizationSettings')?.children || []
)

onMounted(async () => {
  org.value = await fetchOrganization(route.params.id)
  if (!org.value) {
    await router.push({ name: 'Organizations' })
  }
})

async function updateOrg() {
  if (!org.value) return
  isLoading.value = true
  const resp = await updateOrganization(org.value)
  if (resp) {
    await store.dispatch('showAlertToast', 'changesSaved')
  }
  isLoading.value = false
}
</script>

<template>
  <section :class="['org-settings', { 'child-route': currentRoute }]">
    <v-progress-circular
      v-if="!org"
      class="loader"
      color="#FC7800"
      indeterminate
      size="200"
      width="15"
    />
    <div
      v-else
      class="wrap"
    >
      <h2 :class="['org-title', 'page-title', { 'child-route': currentRoute }]">
        <router-link :to="{ name: 'Organization', params: { id: String(org?.id) } }">
          <v-icon
            alt="Back to organization page"
            aria-hidden="false"
            class="title-arrow"
          >
            {{ mdiArrowLeft }}
          </v-icon>
        </router-link>
        {{ org.name }} Settings
      </h2>
      <div class="org-container">
        <div :class="['navigation', { 'child-route': currentRoute }]">
          <router-link
            v-for="(childRoute, index) in childRoutes"
            :key="index"
            :to="{ name: childRoute.name }"
            active-class="active"
            class="item"
          >
            {{ childRoute.meta?.title }}
          </router-link>
        </div>
        <div class="setting-container">
          <h2
            v-if="currentRoute"
            class="org-title page-title child-route-title"
          >
            <router-link :to="{ name: 'OrganizationSettings' }">
              <v-icon
                alt="Back to list of settings"
                aria-hidden="false"
                class="title-arrow"
              >
                {{ mdiArrowLeft }}
              </v-icon>
            </router-link>
            {{ currentRoute.meta.title }}
          </h2>

          <transition
            :name="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'slide-fade' : ''"
            mode="out-in"
          >
            <router-view
              :is-loading="isLoading"
              :org="org"
              @update="updateOrg"
            />
          </transition>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.loader {
  display: flex !important;
  margin: 210px auto;
}

.confirm-alert {
  position: absolute;
  z-index: 1;
  width: 100%;
  font-weight: var(--font-weight-bold);
}

.org-settings {
  margin-top: 80px;
  background-color: var(--color-grey-700);

  &.child-route {
    @media (max-width: 768px) {
      background-color: var(--color-white);
    }
  }
}

.wrap {
  width: 100%;
  max-width: 880px;
  margin: 96px auto;

  .org-title {
    @include font-h2;

    display: block;
    margin-bottom: 10px;

    &.child-route {
      display: block;

      @media (max-width: 768px) {
        display: none;
      }
    }

    &.child-route-title {
      display: none;

      @media (max-width: 768px) {
        display: block;
      }
    }

    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }

  .title-arrow {
    @include font-h2;

    margin-left: -40px;
    color: var(--color-black);
    cursor: pointer;

    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

  @media (max-width: 768px) {
    margin: 40px auto;
  }
}

.org-container {
  display: flex;
  height: 620px;
  background-color: var(--color-white);
  border-radius: var(--border-radius-rounded);

  // TODO: Move this to globals
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);

  .navigation {
    min-width: 190px;
    background-color: var(--color-grey-600);
    border-top-left-radius: var(--border-radius-rounded);
    border-bottom-left-radius: var(--border-radius-rounded);

    .item {
      display: block;
      padding: 16px 20px;
      color: rgb(0 0 0 / 87%);
      border-bottom: 1px solid var(--color-grey-600);
      cursor: pointer;
      transition-duration: 0.2s;

      &:hover,
      &.active {
        font-weight: var(--font-weight-bold);
        background-color: var(--color-grey-800);

        @media (max-width: 768px) {
          background-color: var(--color-grey-600);
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }

    &.child-route {
      @media (max-width: 768px) {
        display: none;
      }
    }

    @media (max-width: 768px) {
      background-color: var(--color-grey-700);
    }
  }

  .setting-container {
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    box-shadow: none;
  }
}

.slide-fade-leave-to {
  transition: all 0s;
}
</style>
