<template>
  <v-progress-circular
    v-if="loading"
    class="loader"
    size="200"
    color="#FC7800"
    width="15"
    indeterminate
  />
</template>

<script>
import api from '@/api'

export default {
  name: 'CreateEvent',
  beforeRouteEnter(to, from, next) {
    if (from.name === 'CreateEventProcessing') {
      window.history.back()
      return
    }

    next()
  },
  data() {
    return {
      loading: true,
    }
  },
  created() {
    this.redirectToCreateEvent()
  },
  methods: {
    async redirectToCreateEvent() {
      const event = await api.events.addEvent()

      this.loading = false

      if (!event) {
        this.$router.push({ name: 'EventsPage' })
        return
      }

      this.$router.push({ name: 'CreateEventProcessing', params: { id: event.id } })
    },
  },
}
</script>

<style scoped>
.loader {
  display: flex;
  margin: 210px auto;
}
</style>
