<template>
  <!--      eslint-disable vuejs-accessibility/click-events-have-key-events-->
  <div
    :class="{ clickable: sparring.red.id && sparring.blue.id }"
    class="final-sparring"
    @click="toSparring"
  >
    <div class="participant">
      <v-icon
        v-if="getWinnerColor === 'red'"
        class="icon-gold"
      >
        {{ mdiCrownOutline }}
      </v-icon>
      <span>{{ sparring.red.name || '?' }}</span>
    </div>
    <div class="vs">VS</div>
    <div class="participant">
      <v-icon
        v-if="getWinnerColor === 'blue'"
        class="icon-gold"
      >
        {{ mdiCrownOutline }}
      </v-icon>
      <span>{{ sparring.blue.name || '?' }}</span>
    </div>
  </div>
  <!--      eslint-enable vuejs-accessibility/click-events-have-key-events-->
</template>

<script>
import { mdiCrownOutline } from '@mdi/js'

export default {
  name: 'BattleBracketsFinalSparring',
  props: {
    sparring: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mdiCrownOutline,
    }
  },
  computed: {
    getWinnerColor() {
      if (!this.sparring.winner || !this.sparring.red || !this.sparring.blue) return 'none'
      return this.sparring.red.id === this.sparring.winner ? 'red' : 'blue'
    },
  },
  methods: {
    toSparring() {
      this.$router.push({ name: 'Sparring', params: { id: this.sparring.sparringId } })
    },
  },
}
</script>

<style lang="scss" scoped>
.final-sparring {
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 20px 12px;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-500);
  border-radius: 6px;
  transition: border-color 0.5s ease;
  pointer-events: none;

  &.clickable {
    cursor: pointer;
    pointer-events: unset;
  }

  &:hover {
    border-color: var(--color-orange-500);
  }

  .vs {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    color: var(--color-white);
    font-weight: var(--font-weight-bold);
    background-color: var(--color-gold-900);
    border-radius: 120px;
  }

  .participant {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 110px;
    text-align: center;

    .icon-gold {
      color: var(--color-gold-900) !important;
    }
  }
}
</style>
