<template>
  <section class="purchase-item">
    <div
      v-if="item && !loading"
      class="item"
    >
      <div class="header">
        <!--        eslint-disable vuejs-accessibility/click-events-have-key-events-->
        <div
          v-if="breakdown"
          class="item-title breakdown"
          @click="isBreakdownOpen = !isBreakdownOpen"
        >
          <span>View breakdown</span>
          <span>
            <v-icon v-if="isBreakdownOpen">{{ mdiChevronUp }}</v-icon>
            <v-icon v-else>{{ mdiChevronDown }}</v-icon>
          </span>
          <!--        eslint-enable vuejs-accessibility/click-events-have-key-events-->
        </div>

        <h4 v-else>Order summary</h4>

        <div
          v-if="isShowFullInfo"
          class="item-title"
        >
          <span>{{ item.title }}</span>
          <span>{{ item.ticketCost }}</span>
        </div>
      </div>

      <div
        v-if="isShowFullInfo"
        class="calculate"
      >
        <div class="item-title">
          <span>Subtotal</span>
          <span>{{ item.ticketCost }}</span>
        </div>

        <div class="item-title">
          <span>Transaction fees</span>
          <span>{{ item.ticketFees }}</span>
        </div>

        <div class="item-title">
          <span>Taxes</span>
          <span>{{ item.ticketTaxes }}</span>
        </div>
      </div>

      <div class="total">
        <div class="item-total">
          <span>Total</span>
          <span>{{ item.ticketTotalSum }}</span>
        </div>
      </div>
    </div>

    <div
      v-if="loading"
      class="cart-icon-container"
    >
      <v-progress-circular
        v-if="loading"
        color="#FC7800"
        indeterminate
        size="50"
        width="5"
      />
    </div>

    <div
      v-if="!item && !loading"
      class="cart-icon-container"
    >
      <v-icon class="cart-icon">
        {{ mdiCartOutline() }}
      </v-icon>
    </div>
  </section>
</template>

<script>
import { mdiCartOutline, mdiChevronDown, mdiChevronUp } from '@mdi/js'

export default {
  name: 'EventSidebarPurchaseItem',
  props: {
    item: {
      validator: (prop) => typeof prop === 'object' || prop === null,
      default: () => ({}),
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    breakdown: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      mdiChevronUp,
      mdiCartOutline,
      mdiChevronDown,
      isBreakdownOpen: false,
    }
  },
  computed: {
    isShowFullInfo() {
      return !this.breakdown || (this.breakdown && this.isBreakdownOpen)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.purchase-item {
  width: 100%;
  max-width: 305px;
  padding: 20px;
  background-color: var(--color-grey-100);

  @media (max-width: $screen-md) {
    max-width: unset;
  }
}

.cart-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (max-width: $screen-md) {
    padding: 80px;
  }

  .cart-icon {
    @include font-h3;
  }
}

.item {
  .header,
  .calculate {
    padding-bottom: 15px;
    border-bottom: 1px solid var(--color-grey-700);
  }

  .item-title {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    color: var(--color-grey-300);
  }

  .item-total {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    font-weight: var(--font-weight-bold);
  }
}
</style>
