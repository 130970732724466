<script lang="ts" setup>
import { computed, ref } from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import SettingsProfile from '@/pages/ProfileSetting/ui/SettingsProfile.vue'
import SettingsSocial from '@/pages/ProfileSetting/ui/SettingsSocial.vue'
import SettingsPrivacy from '@/pages/ProfileSetting/ui/SettingsPrivacy.vue'
import SettingsPassword from '@/pages/ProfileSetting/ui/SettingsPassword.vue'
import SettingsOtherProfiles from '@/pages/ProfileSetting/ui/SettingsOtherProfiles.vue'
import { mdiArrowLeft } from '@mdi/js'
import { useCloned } from '@vueuse/core'
import { userApi } from '@/enitites/user'
import { ProfileSettingsLogoutButton } from '@/features/authorization'

type SettingsComponent =
  | typeof SettingsProfile
  | typeof SettingsSocial
  | typeof SettingsPrivacy
  | typeof SettingsPassword
  | typeof SettingsOtherProfiles

const settingsComponents: Record<string, SettingsComponent> = {
  SettingsProfile,
  SettingsSocial,
  SettingsPrivacy,
  SettingsPassword,
  SettingsOtherProfiles,
}

const currentForm = ref<SettingsComponent>(SettingsProfile)
const loading = ref(false)
const mobileMenuHidden = computed(() => store.state.mobileMenuHidden)

const getUserDB = computed(() => store.getters.getUserDB)
const userForChange = ref(useCloned(getUserDB.value).cloned)

function setComponents(
  name: 'SettingsProfile' | 'SettingsSocial' | 'SettingsPrivacy' | 'SettingsPassword' | 'SettingsOtherProfiles'
) {
  store.commit('TOGGLE_MENU_MOBILE')
  currentForm.value = settingsComponents[name]
}

async function saveChanges() {
  loading.value = true

  const updatedUser = await userApi.updateUser(userForChange.value)
  loading.value = false
  if (!updatedUser) return

  await store.dispatch('setUserDB', userForChange.value)
  await store.dispatch('showAlertToast', 'changesSaved')
}
</script>

<template>
  <section
    v-if="userForChange"
    class="account-setting"
  >
    <v-container class="account-setting__container">
      <h2
        v-if="$vuetify.breakpoint.smAndUp"
        class="account-setting__back ml-2 page-title"
      >
        <router-link
          :to="{ name: 'Profile', params: { id: userForChange.id } }"
          class="account-setting__title"
        >
          <v-icon>{{ mdiArrowLeft }}</v-icon>
          Settings
        </router-link>
      </h2>

      <v-row no-gutters>
        <v-col
          :class="{ 'account-setting__menu--mobile-hidden': mobileMenuHidden }"
          class="account-setting__menu"
          cols="12"
          sm="3"
        >
          <router-link
            v-if="$vuetify.breakpoint.xsOnly"
            :to="{ name: 'Profile', params: { id: userForChange.id } }"
            class="account-setting__title page-title"
          >
            <v-icon class="title-arrow">{{ mdiArrowLeft }}</v-icon>
            Settings
          </router-link>
          <v-sheet
            class="d-flex flex-column account-setting__menu-wrap"
            height="100%"
            min-height="670px"
            title
          >
            <div>
              <v-btn
                block
                class="account-setting__menu-button text-none"
                text
                @click="setComponents('SettingsProfile')"
              >
                <span class="mr-auto menu-item">Profile</span>
              </v-btn>
              <v-btn
                block
                class="account-setting__menu-button text-none"
                text
                @click="setComponents('SettingsSocial')"
              >
                <span class="mr-auto menu-item">Contact / Social</span>
              </v-btn>
              <v-btn
                block
                class="account-setting__menu-button text-none"
                text
                @click="setComponents('SettingsPrivacy')"
              >
                <span class="mr-auto menu-item">Stats</span>
              </v-btn>
              <v-btn
                block
                class="account-setting__menu-button text-none"
                text
                @click="setComponents('SettingsPassword')"
              >
                <span class="mr-auto menu-item">Change Password</span>
              </v-btn>
              <v-btn
                block
                class="account-setting__menu-button text-none"
                text
                @click="setComponents('SettingsOtherProfiles')"
              >
                <span class="mr-auto menu-item">Other profiles</span>
              </v-btn>
            </div>
            <ProfileSettingsLogoutButton class="logout-btn-container account-setting__menu-button" />
          </v-sheet>
        </v-col>
        <v-col
          :class="{ 'settings-container--mobile-hidden': !mobileMenuHidden }"
          cols="12"
          sm="9"
        >
          <v-sheet
            class="settings-container__wrap d-flex align-start justify-center"
            color="white"
          >
            <transition
              appear
              mode="out-in"
              name="slide-fade"
            >
              <component
                :is="currentForm"
                :loading="loading"
                :user.sync="userForChange"
                class="authentication__form pa-10"
                @save="saveChanges"
              />
            </transition>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<style lang="scss">
@import '@/assets/style/slideFade';

.account-setting .theme--light.v-btn:hover::before {
  display: none;
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}
</style>
<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.account-setting {
  height: 100%;
  margin-top: 80px;
  background-color: var(--color-grey-150);

  &__back {
    @include font-h2;

    font-weight: var(--font-weight-normal);
    cursor: pointer;
  }

  &__container {
    margin: 40px auto 120px;

    @media screen and (max-width: 600px) {
      margin: 0;
      padding: 0;
    }
  }

  &__title {
    @include font-h2;

    display: block;
    margin-bottom: 10px;
    color: inherit;

    .title-arrow {
      @include font-h2;

      margin-left: -40px;
      color: var(--color-black);
      cursor: pointer;

      @media (max-width: 768px) {
        margin-left: 0;
      }
    }

    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }

  &__menu {
    &--mobile-hidden {
      @media screen and (max-width: 600px) {
        display: none !important;
      }
    }

    @media screen and (max-width: 600px) {
      margin-top: 40px;
    }
  }

  &__menu-button {
    .menu-item {
      @include font-body2;

      @media screen and (max-width: 600px) {
        @include font-body1;

        font-weight: var(--font-weight-normal);
      }
    }

    @media screen and (max-width: 600px) {
      font-family: inherit;
      border-bottom: 1px solid var(--color-grey-600);
      border-radius: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    &.logout-btn-container {
      display: flex;
      align-items: center;

      @media screen and (max-width: 600px) {
        border-top: 1px solid var(--color-grey-600);
      }
    }

    @media screen and (max-width: 600px) {
      height: 57px !important;
    }
  }

  &__menu-wrap {
    justify-content: space-between;
    background-color: #eee !important;

    @media screen and (max-width: 600px) {
      justify-content: flex-start;
      background-color: inherit !important;
    }
  }

  &__alert {
    position: absolute;
    z-index: 1;
    width: 100%;

    &--fixed {
      position: fixed;
      top: 0;
    }
  }

  @media screen and (max-width: 600px) {
    background-color: var(--color-grey-700);
  }
}

.settings-container {
  &--mobile-hidden {
    @media screen and (max-width: 600px) {
      display: none !important;
    }
  }
}
</style>
