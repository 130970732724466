<template>
  <section class="agreements">
    <v-checkbox
      v-if="battle.hasAgreement"
      v-model="user.agreementSigned"
      class="mb-6 checkbox"
      color="var(--color-blue-800)"
      hide-details
      readonly
      @click="isShowAgreement = true"
    >
      <template v-slot:label>
        Must agree to the competitor
        <a
          href="#"
          @click.prevent
          >agreement</a
        >
        to register
      </template>
    </v-checkbox>
    <OrganizationAgreement
      :check-date="user.agreementSignedAt"
      :document="battle.orgLegalDocs.agreement"
      :is-checked="user.agreementSigned"
      :is-show-modal.sync="isShowAgreement"
      @sign="signAgreement"
    />

    <v-checkbox
      v-if="battle.hasWaiver"
      v-model="user.waiverSigned"
      class="my-4 checkbox"
      color="var(--color-blue-800)"
      hide-details
      readonly
      @click="isShowWaiver = true"
    >
      <template v-slot:label>
        Must agree to the
        <a
          href="#"
          @click.prevent
          >terms of this waiver</a
        >
        to register
      </template>
    </v-checkbox>

    <OrganizationWaiver
      :check-date="user.waiverSignedAt"
      :document="battle.orgLegalDocs.waiver"
      :is-checked="user.waiverSigned"
      :is-show-modal.sync="isShowWaiver"
      @sign="signWaiver"
    />
  </section>
</template>

<script>
import api from '@/api'
import { isZeroDate } from '@/services/date'
import OrganizationWaiver from '@/components/OrganizationWaiver.vue'
import OrganizationAgreement from '@/components/OrganizationAgreement.vue'

export default {
  name: 'BattleUserAgreements',
  components: {
    OrganizationWaiver,
    OrganizationAgreement,
  },
  props: {
    battle: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isShowAgreement: false,
      isShowWaiver: false,
    }
  },
  methods: {
    async signAgreement() {
      const data = await api.admin.signParticipantAgreement(this.battle.id, this.user.id)
      if (!data) return
      const date = new Date(data)
      this.$emit('update:user', {
        ...this.user,
        agreementSigned: !!isZeroDate(date),
        agreementSignedAt: date,
      })
    },
    async signWaiver() {
      const data = await api.admin.signParticipantWaiver(this.battle.id, this.user.id)
      if (!data) return
      const date = new Date(data)
      this.$emit('update:user', {
        ...this.user,
        waiverSigned: !!isZeroDate(date),
        waiverSignedAt: date,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.agreements {
  .checkbox ::v-deep .v-label {
    display: block;
  }
}
</style>
