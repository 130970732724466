<script lang="ts" setup>
import { defineProps } from 'vue'
import { OrganizationUser } from '@/modules/Organizations/types'
import { useImage } from '@/shared/composables/image'

const { getAvatar } = useImage()

defineProps<{
  member: OrganizationUser
}>()
</script>

<template>
  <router-link
    :to="{ name: 'Profile', params: { id: member.id } }"
    class="member"
  >
    <div class="user">
      <div class="place">
        <template v-if="member.points">
          <span
            v-if="member.rank === 1"
            class="icon-gold"
          />
          <span
            v-if="member.rank >= 2"
            class="icon-trophy"
          />
          <div class="number">
            {{ member.rank }}
          </div>
        </template>
      </div>

      <div class="user-info">
        <v-avatar size="32">
          <v-img :src="getAvatar(member.avatar, 64)" />
        </v-avatar>

        <div class="name">
          {{ member.dancerName }}
        </div>
      </div>
    </div>

    <div class="points">
      {{ member.points }}
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.member {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 12px;
  color: inherit;
  background-color: var(--color-white);
  border-radius: var(--border-radius-rounded);
  cursor: pointer;
}

.user {
  display: flex;
  align-items: center;
}

.place {
  display: flex;
  align-items: center;
  width: 66px;

  .icon-gold,
  .icon-trophy {
    width: 24px;
    height: 24px;
  }

  .icon-gold {
    background: url('/img/ic_Crown.svg') no-repeat center;
  }

  .icon-trophy {
    background: url('/img/ic_Trophy-silver.svg') no-repeat center;
  }

  .number {
    margin-left: 17px;
    font-weight: var(--font-weight-bold);
  }
}

.user-info {
  display: flex;
  align-items: center;

  .name {
    margin-left: 8px;
    color: var(--color-dark-800);
  }
}

.points {
  color: var(--color-dark-800);
}
</style>
