<template>
  <!--eslint-disable  vuejs-accessibility/click-events-have-key-events-->
  <div
    class="result"
    @click="$emit('click')"
  >
    <v-btn
      class="btn"
      icon
    >
      <v-icon class="white--text">
        {{ mdiEye }}
      </v-icon>
    </v-btn>
    <!-- TODO: isOrganizer -->
    <v-btn
      v-if="false"
      class="edit"
      icon
    >
      <v-icon class="white--text">
        {{ mdiPencil }}
      </v-icon>
    </v-btn>
    <p class="title-result">
      {{ battle.title }}
    </p>
    <span class="battle-type">{{ getBattleCategory(battle.crewSize) }}</span>
    <BattleOrgInfo
      v-if="battle.orgChip && battle.orgChip.id"
      :chip="battle.orgChip"
    />
  </div>
  <!--eslint-enable  vuejs-accessibility/click-events-have-key-events-->
</template>

<script>
import Battles from '@/mixins/battle'
import Images from '@/mixins/image'
import BattleOrgInfo from '@/components/Battle/BattleOrgInfo.vue'
import { mdiEye, mdiPencil } from '@mdi/js'

export default {
  name: 'EventBattlesResultsChip',
  components: { BattleOrgInfo },
  mixins: [Battles, Images],
  props: {
    battle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mdiPencil,
      mdiEye,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.result {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 72px;
  padding: 12px;
  color: var(--color-dark-800);
  background: var(--color-white);
  border-radius: 6px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 20%);

  .btn {
    position: absolute;
    top: 15px;
    right: 15px;
    border: var(--color-white) 2px solid;
  }

  .edit {
    position: absolute;
    top: 60px;
    right: 15px;
    border: var(--color-white) 2px solid;
  }

  .title-result {
    @include font-h4;

    margin-bottom: 0;
    color: var(--color-dark-800);
    font-weight: var(--font-weight-bold);
    text-align: center;
  }

  .battle-type {
    @include font-body1;

    margin-bottom: 0;
    color: var(--color-dark-800);
    text-align: center;
    text-transform: uppercase;
  }
}
</style>
