<template>
  <article class="round">
    <SparringRoundParticipantScore
      :active="winnerColor === 'red' || winnerColor === 'tie'"
      :expandable="!!round.sumRedPoints"
      :judgments="round.scores"
      :points="round.sumRedPoints"
      color="red"
    />
    <div class="name">
      <v-icon
        v-if="canEdit"
        class="edit-icon"
        @click="$emit('edit')"
      >
        {{ mdiPencil }}
      </v-icon>
      <span> {{ name }} </span>
    </div>
    <SparringRoundParticipantScore
      :active="winnerColor === 'blue' || winnerColor === 'tie'"
      :expandable="!!round.sumBluePoints"
      :judgments="round.scores"
      :points="round.sumBluePoints"
      color="blue"
    />
  </article>
</template>

<script>
import Images from '@/mixins/image'
import SparringRoundParticipantScore from '@/components/SparringRoundParticipantScore.vue'
import { mdiPencil } from '@mdi/js'

export default {
  name: 'SparringRoundScore',
  components: { SparringRoundParticipantScore },
  mixins: [Images],
  props: {
    round: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mdiPencil,
    }
  },
  computed: {
    winnerColor() {
      if (this.round.sumRedPoints === this.round.sumBluePoints) {
        return this.round.sumRedPoints + this.round.sumBluePoints === 0 ? 'none' : 'tie'
      }

      return this.round.sumRedPoints > this.round.sumBluePoints ? 'red' : 'blue'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.round {
  display: grid;
  grid-template-columns: 1fr 0.4fr 1fr;

  .name {
    @include font-body2;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 40px;
    margin: 0 auto;

    .edit-icon {
      position: absolute;
      top: 0;
    }

    span {
      display: block;
      text-align: center;
    }
  }
}
</style>
