<template>
  <BKModal
    :is-show="isShowForm"
    content-class="modal"
    modal-width="800"
    @update:isShow="$emit('update:isShowForm', $event)"
  >
    <div class="modal-content">
      <section class="editor">
        <div>
          <v-form
            ref="crewNameForm"
            v-model="isCrewNameValid"
          >
            <v-text-field
              v-model="userCrew.name"
              :rules="crewNameRules"
              autocomplete="off"
              background-color="#fff"
              class="battle-detail__text-field"
              label="Crew name*"
              outlined
            />
          </v-form>
          <BattleUserSelect
            :battle-id="battle.id"
            :disabled="userCrew.participants.length >= battle.crewSize"
            :exclude="disabledParticipants"
            @removeUser="removeUser"
            @selectUser="addUser"
          />
          <ul class="users-list">
            <li
              v-for="(user, index) in userCrew.participants"
              :key="user.id"
              class="users-list-item"
            >
              <BKBadge
                :value="user.checkedIn"
                bordered
                bottom
                color="var(--color-green)"
              >
                <template #badge>
                  <span
                    ><v-icon>{{ mdiCheck }}</v-icon></span
                  >
                </template>
                <v-avatar size="32">
                  <img
                    :alt="user.dancerName"
                    :src="getAvatar(user.avatar, 32)"
                  />
                </v-avatar>
              </BKBadge>
              {{ user.dancerName }}
              <button
                class="remove-button"
                @click="removeUser(user)"
              >
                <v-icon color="var(--rasberry-red)">
                  {{ mdiMinusCircle }}
                </v-icon>
              </button>
              <BattleUserAgreements
                v-if="battle.hasAgreement || battle.hasWaiver"
                :battle="battle"
                :user="user"
                class="user-agreements"
                @update:user="updateUser(index, $event)"
              />
            </li>
          </ul>
        </div>
      </section>
    </div>
    <template #actions>
      <BKButton
        :disabled="isDisabledBtn"
        :loading="loading"
        class="action-btn white--text"
        color="var(--color-blue-800)"
        @click="registerCrew"
      >
        {{ isEditMode ? 'Save edits' : 'Register Crew' }}
      </BKButton>
      <BKButton
        outlined
        @click="$emit('update:isShowForm', false)"
      >
        Cancel
      </BKButton>
    </template>
  </BKModal>
</template>

<script>
import Images from '@/mixins/image'
import { BKModal } from '@/shared/ui/modals'
import BKBadge from '@/components/AppShared/BKBadge.vue'
import api from '@/api'
import battleParticipantJsonReviver from '@/services/battleParticipantJsonReviver'
import BattleUserSelect from '@/components/Battle/BattleUserSelect.vue'
import BattleUserAgreements from '@/components/Battle/BattleUserAgreements.vue'
import { mdiCheck, mdiMinusCircle } from '@mdi/js'

export default {
  name: 'BattleCreateCrewForm',
  components: {
    BattleUserAgreements,
    BKModal,
    BKBadge,
    BattleUserSelect,
  },
  mixins: [Images],
  props: {
    isShowForm: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    battle: {
      type: Object,
      required: true,
    },
    crew: {
      type: Object,
      required: false,
      default: null,
    },
    isEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      mdiMinusCircle,
      mdiCheck,
      userCrew: {
        id: 0,
        name: '',
        participants: [],
        order: 0,
      },
      isCrewNameValid: false,
      loading: false,
      crewNameRules: [(v) => !!v || 'Crew name is required'],
    }
  },
  computed: {
    disabledParticipants() {
      let excludedIds = [
        ...this.event.staff.admins.map((a) => a.id),
        ...(this.event.staff.organizer ? [this.event.staff.organizer.id] : []),
        ...this.judgesIds,
        ...(this.getUserDB ? [this.getUserDB.id] : []),
      ]

      this.battle.crews.forEach((cr) => {
        if (!cr.participants.length) return
        excludedIds = [...excludedIds, ...cr.participants.map((p) => p.id)]
      })

      this.userCrew.participants.forEach((p) => {
        excludedIds = [...excludedIds, p.id]
      })

      return excludedIds
    },
    judgesIds() {
      return this.battle.judges.map((judge) => judge.id)
    },
    isDisabledBtn() {
      if (!this.userCrew.name || !this.userCrew.participants.length) {
        return true
      }

      return this.userCrew.participants.some((p) => {
        const needsAgreement = p.showAgreement && !p.agreementSigned
        const needsWaiver = p.showWaiver && !p.waiverSigned

        return needsAgreement || needsWaiver
      })
    },
  },
  watch: {
    crew() {
      this.userCrew = JSON.parse(JSON.stringify(this.crew), battleParticipantJsonReviver)
    },
  },
  mounted() {
    if (this.crew) {
      this.userCrew = JSON.parse(JSON.stringify(this.crew), battleParticipantJsonReviver)
    }
  },
  methods: {
    addUser(user) {
      if (this.userCrew.participants.length >= this.battle.crewSize) return
      this.userCrew.participants.push(user)
    },
    removeUser(user) {
      this.userCrew.participants = this.userCrew.participants.filter((selectedUser) => selectedUser.id !== user.id)
    },
    updateUser(index, user) {
      this.userCrew.participants = this.userCrew.participants.map((selectedUser, i) =>
        i === index ? user : selectedUser
      )
    },
    async registerCrew() {
      this.loading = true
      const registerPayload = {
        eventId: this.event.id,
        battleId: this.battle.id,
        name: this.userCrew.name,
        participants: this.userCrew.participants,
      }

      const updatePayload = Object.assign(registerPayload, { crewId: this.userCrew.id })
      // TODO: split old logic for register and update crew
      const updatedBattle = this.isEditMode
        ? await api.crews.updateCrew(updatePayload)
        : await api.crews.registerCrew(registerPayload)

      this.loading = false

      const updatedCrew = updatedBattle.crews.find((crew) => crew.id === this.userCrew.id)

      this.$emit('update:battle', updatedBattle)
      this.$emit('update:crew', updatedCrew)
      this.$emit('update:isShowForm', false)

      this.userCrew = {
        id: 0,
        name: '',
        participants: [],
        order: 0,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.modal-content {
  min-height: 500px;
  padding-top: 20px;

  .modal-header {
    @include font-h4;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
    color: var(--color-dark-800);
  }

  .modal-actions {
    display: grid;
    margin-top: auto;

    &::before {
      height: 1px;
      margin: 0 -24px 20px;
      background-color: var(--color-grey-600);
      content: '';
    }
  }

  .actions {
    display: flex;
    gap: 20px;

    .action {
      flex-shrink: 1;
      border-width: 2px;
      border-radius: var(--border-radius-rounded);
    }

    .action::v-deep .v-btn__content {
      font-weight: var(--font-weight-bold);
    }
  }

  .users-list {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .users-list-item {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid var(--color-grey-600);
  }

  .user-agreements {
    flex: 1 0 100%;
  }

  .remove-button {
    margin-left: auto;
    border-radius: 50%;

    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 2px var(--rasberry-red);
    }
  }
}
</style>
