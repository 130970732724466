<template>
  <section class="media-section">
    <h2 class="media-title">
      <span>Media</span>
      <BKButton
        v-if="canEdit"
        class="add-media-btn"
        outlined
        width="max-content"
        @click="isShowMediaEditor = true"
      >
        Add media
      </BKButton>

      <BKMediaEditor
        :event-id="eventId"
        :is-show-editor.sync="isShowMediaEditor"
        :org-id="orgId"
        @save="submitSaveMedia"
      />
    </h2>

    <ModalWrapper :is-show.sync="isShowMediaPlayer">
      <v-card
        v-if="selectedMedia && isShowMediaPlayer"
        height="440"
        max-width="830"
      >
        <iframe
          :src="embedSourceUrl"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          height="100%"
          title="YouTube video player"
          width="100%"
        />
      </v-card>
    </ModalWrapper>

    <div
      v-if="media && media.length"
      class="media-items"
    >
      <div
        v-for="item in media"
        :key="item.id"
        class="media-item"
      >
        <v-img
          :alt="item.name"
          :src="getPreviewImageUrl(item.link)"
          class="image"
          height="117"
          width="205"
          @click="showMedia(item.link)"
        >
          <img
            :src="'/img/icon-play.svg'"
            alt="Play icon"
            class="icon-play"
          />
        </v-img>
        <div class="media-name">
          {{ item.name }}
          <v-btn
            :loading="loadingDeleteMedia"
            color="var(--rasberry-red)"
            icon
            @click="removeMedia(item.id)"
          >
            <v-icon v-if="canEdit">
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BKMediaEditor from '@/components/BKMediaEditor.vue'
import { ModalWrapper } from '@/shared/ui/modals'
import api from '@/api'
import { mdiClose } from '@mdi/js'

export default {
  name: 'MediaPanel',
  components: { BKMediaEditor, ModalWrapper },
  props: {
    orgId: {
      type: Number,
      required: true,
    },
    eventId: {
      type: Number,
      required: false,
      default: 0,
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: 'Media',
    },
  },
  data() {
    return {
      mdiClose,
      media: [],
      isShowMediaEditor: false,
      isShowMediaPlayer: false,
      selectedMedia: '',
      loadingDeleteMedia: false,
    }
  },
  computed: {
    embedSourceUrl() {
      return `https://www.youtube.com/embed/${this.selectedMedia}?autoplay=1`
    },
  },
  mounted() {
    this.fetchMedia()
  },
  methods: {
    async fetchMedia() {
      const media = await api.media.getMedia({
        eventId: this.eventId,
        orgId: this.orgId,
      })

      if (media) this.media = media
    },
    submitSaveMedia(updatedMedia) {
      this.media = updatedMedia
      this.isShowMediaEditor = false
    },
    showMedia(link) {
      this.isShowMediaPlayer = true
      this.selectedMedia = link
    },
    async removeMedia(id) {
      this.loadingDeleteMedia = true
      const mediaUpdated = await api.media.removeMedia(id)
      if (mediaUpdated) this.media = mediaUpdated
      this.loadingDeleteMedia = false
    },
    getPreviewImageUrl(youtubeId) {
      return `https://img.youtube.com/vi/${youtubeId}/0.jpg`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.media-title {
  @include font-h4;

  display: flex;
  align-items: center;
  column-gap: 10px;
}

.add-media-btn {
  max-width: 200px;
}

.media-items {
  display: flex;
  flex-wrap: wrap;
  margin: 12px -10px;
}

.media-item {
  margin: 0 10px;

  .image {
    cursor: pointer;
  }

  .icon-play {
    position: absolute;
    top: 35px;
    right: 78px;
  }
}

.media-name {
  @include font-body2;

  margin-top: 8px;
  color: var(--color-black);
}
</style>
