<template>
  <v-autocomplete
    v-model="selectedCountry"
    :append-icon="mdiChevronDown"
    :dense="dense"
    :filter="countryFilter"
    :hide-details="hideDetails"
    :items="countries"
    autocomplete="none"
    background-color="#fff"
    item-text="name"
    item-value="name"
    label="Country"
    no-data-text="Country not found"
    outlined
    @change="updateCountry"
  >
    <template v-slot:item="{ item }">
      <v-list-item-avatar :size="itemFlagSize">
        <img
          :alt="item.name"
          :src="item.flag"
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.name" />
      </v-list-item-content>
    </template>
    <template v-slot:selection="{ item }">
      <v-list-item-avatar
        :size="selectFlagSize"
        class="ml-1 my-0"
      >
        <img
          :alt="item.name"
          :src="item.flag"
        />
      </v-list-item-avatar>
      {{ item.name }}
    </template>
  </v-autocomplete>
</template>

<script>
import { mdiChevronDown } from '@mdi/js'
import flags from '../../../public/flags/data.json'

export default {
  name: 'BKCountrySelect',
  props: {
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    itemFlagSize: {
      type: Number,
      default: 25,
    },
    selectFlagSize: {
      type: Number,
      default: 25,
    },
    isShowState: {
      type: Boolean,
      default: false,
    },
    country: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mdiChevronDown,
      selectedCountry: this.country,
      stateForCountry: ['Australia', 'Canada', 'United States'],
      aliasesForUS: ['us', 'u.s'],
      countries: flags || [{ name: '', flag: '' }],
    }
  },
  mounted() {
    this.$emit('update:isShowState', this.stateForCountry.includes(this.country))
  },
  methods: {
    countryFilter(item, queryString) {
      if (this.aliasesForUS.includes(queryString.toLocaleLowerCase())) {
        return item.name.includes('United States')
      }
      return item.name.toLocaleLowerCase().includes(queryString.toLocaleLowerCase())
    },
    updateCountry(country) {
      this.$emit('update:country', country)
      this.$emit('update:isShowState', this.stateForCountry.includes(country))
    },
  },
}
</script>
