<template>
  <div>
    <!-- if the event is paid and there is no ticket -->
    <template v-if="event.permissions.showPurchaseTicket">
      <BKButton
        :loading="loading"
        block
        class="purchase-button"
        color="var(--color-green)"
        height="48"
        white-text
        @click.stop="showPurchaseModal"
      >
        Purchase tickets
      </BKButton>
    </template>

    <!-- if the event is free or a ticket has been purchased -->
    <div
      v-if="event.permissions.checkedIn || event.permissions.showCheckin"
      :class="{ checked: event.permissions.checkedIn }"
      class="checkin-container"
    >
      <span
        v-if="event.permissions.checkedIn"
        class="checked-text"
      >
        <v-icon class="mr-2">{{ mdiCheck }}</v-icon>
        Checked in
      </span>

      <BKButton
        v-else
        :disabled="event.period !== 'now'"
        :loading="loading"
        class="checkin-button"
        color="var(--color-green)"
        height="48"
        white-text
        @click="checkinUser"
      >
        Check in at event
      </BKButton>
      <!--      eslint-disable vuejs-accessibility/click-events-have-key-events-->
      <span
        v-if="event.permissions.hasTicket"
        class="view-ticket-btn"
        @click="isShowTicketModal = true"
      >
        View ticket
      </span>
      <!--      eslint-утable vuejs-accessibility/click-events-have-key-events-->
    </div>

    <ModalWrapper
      :is-show.sync="isShowPurchaseModal"
      modal-width="880"
    >
      <EventSidebarPurchase
        :event="event"
        @ticketPurchased="confirmTicketPurchase"
      />
    </ModalWrapper>

    <EventTicket
      :event="event"
      :is-show-modal.sync="isShowTicketModal"
      :permissions="event.permissions"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mdiCheck } from '@mdi/js'

import EventSidebarPurchase from '@/components/EventSidebarPurchase.vue'
import { ModalWrapper } from '@/shared/ui/modals'
import EventTicket from '@/components/EventTicket.vue'

import api from '@/api'

export default {
  name: 'BKCheckinButton',
  components: {
    EventSidebarPurchase,
    ModalWrapper,
    EventTicket,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mdiCheck,
      loading: false,
      isShowPurchaseModal: false,
      isShowTicketModal: false,
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
  },
  methods: {
    ...mapActions(['showAlertToast']),
    async checkinUser() {
      this.redirectUnauthorized()

      this.loading = true
      const updatedEvent = await api.events.checkinCurrentUser(this.event.id)

      if (updatedEvent) {
        this.$emit('checkIn')
        this.$emit('update:event', updatedEvent)
      }

      this.loading = false
    },
    showPurchaseModal() {
      this.redirectUnauthorized()
      this.isShowPurchaseModal = true
    },
    async confirmTicketPurchase() {
      setTimeout(() => {
        this.$emit('purchase')
        this.isShowPurchaseModal = false
      }, 3000)

      this.showAlertToast('ticketPurchased')
    },
    redirectUnauthorized() {
      if (this.getUserDB) return
      this.$router.push({ name: 'Signup' })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.purchase-word {
  color: var(--color-green);
  cursor: pointer;
}

.checkin-button,
.purchase-button {
  color: white !important;
}

.checkin-container {
  &.checked {
    display: flex;
    justify-content: space-between;

    .view-ticket-btn {
      display: block;
      margin: 10px 0 0;
      color: var(--color-green);
      font-weight: 700;
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .checked-text,
  .checked-text .v-icon {
    color: var(--color-green);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
  }
}
</style>
