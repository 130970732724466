<script lang="ts" setup>
import { computed } from 'vue'

import { useImage } from '@/shared/composables/image'

interface IProps {
  imgId?: string
  src?: string
  srcset?: string
  type?: 'avatar' | 'event'
  height: number | string
  width: number | string
  lazy?: boolean
  contain?: boolean
  alt?: string
}

const props = withDefaults(defineProps<IProps>(), {
  imgId: '',
  src: '',
  type: 'event',
  lazy: false,
  contain: false,
  alt: '',
})

const { getImageById } = useImage()

const srcAttrs = computed(() => {
  if (props.src) {
    return {
      src: props.src,
      srcset: props.srcset,
    }
  }

  return {
    src: getImageById(props.imgId, +props.width, +props.height, props.type),
    srcset: `${getImageById(props.imgId, +props.width * 2, +props.height * 2, props.type)} 2x`,
  }
})
</script>

<template>
  <img
    :alt="alt"
    :class="['app-image', { 'app-image_id': imgId }, { 'app-image_contain': contain }]"
    :height="height"
    :loading="lazy ? 'lazy' : 'eager'"
    :width="width"
    v-bind="srcAttrs"
  />
</template>

<style lang="scss" scoped>
.app-image {
  object-fit: cover;

  &_id {
    width: 100%;
    height: 100%;
  }

  &_contain {
    object-fit: contain;
  }
}
</style>
