<template>
  <div>
    <div class="search">
      <v-text-field
        v-model="keyword"
        :append-icon="mdiMagnify"
        autocomplete="off"
        background-color="white"
        hide-details
        label="Add dancer to crew"
        outlined
        single-line
        @blur="handlerBlur"
        @focus="search"
        @keyup.prevent="search($event)"
      />
      <v-card class="search__list">
        <v-list-item
          v-for="user in users"
          :key="user.id"
          :disabled="isUserDisabled(user.id)"
          class="user-list px-5"
          @click="pickUser(user)"
        >
          <v-list-item-avatar>
            <img
              :src="getAvatar(user.avatar, 100)"
              alt="avatar"
            />
          </v-list-item-avatar>
          <v-list-item-content> {{ user.dancerName }}</v-list-item-content>
        </v-list-item>
      </v-card>
    </div>
    <v-chip
      v-for="(participant, i) in participants"
      :key="participant.id + i + participant.dancerName"
      class="chip search__users"
      color="transparent"
      large
      pill
    >
      <v-badge
        v-if="!participant.confirmed"
        bottom
        color="#ff6f00"
        offset-x="20"
        offset-y="17"
      >
        <span slot="badge">?</span>
        <v-avatar
          class="flex-shrink-0"
          left
        >
          <img
            :alt="participant.dancerName"
            :src="getAvatar(participant.avatar, 100)"
          />
        </v-avatar>
      </v-badge>
      <v-avatar
        v-else
        class="flex-shrink-0"
        left
      >
        <img
          :alt="participant.dancerName"
          :src="getAvatar(participant.avatar, 100)"
        />
      </v-avatar>
      <span class="text-chip">{{ participant.dancerName }}</span>
      <v-icon
        v-if="getIsUserRemovable(participant.id)"
        class="chip__close-icon"
        color="var(--rasberry-red)"
        @click="removeUser(participant)"
      >
        {{ mdiMinusCircle }}
      </v-icon>
    </v-chip>
  </div>
</template>

<script>
import Images from '@/mixins/image'
import { mdiMagnify, mdiMinusCircle } from '@mdi/js'
import { userApi } from '@/enitites/user'

export default {
  name: 'BattleParticipantSelect',
  mixins: [Images],
  props: {
    labelText: {
      type: String,
      default: 'Select user',
    },
    participants: {
      type: Array,
      default: () => [],
    },
    exclude: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Array,
      default: () => [],
    },
    nonRemovable: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mdiMinusCircle,
      mdiMagnify,
      keyword: '',
      loading: false,
      users: [],
    }
  },
  methods: {
    isUserDisabled(userId) {
      return this.disabled.some((id) => id === userId)
    },
    handlerBlur() {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.users = []), 300)
    },
    pickUser(user) {
      const exist = this.participants.findIndex((u) => u.id === user.id)
      if (exist === -1) {
        this.$emit('selectUser', user, this.role)
      }
      this.users = []
      this.keyword = ''
    },
    removeUser(user) {
      this.$emit('removeUser', user)
    },
    getIsUserRemovable(userId) {
      return !this.nonRemovable.some((uId) => uId === userId)
    },
    async search(key = '') {
      if (key.keyCode >= 37 && key.keyCode <= 40) return

      const searchParams = {
        pageNumber: 0,
        pageSize: 100,
        orgIds: 0,
        exclude: this.exclude.join(),
        keyword: this.keyword,
      }
      const users = await userApi.getPublicUsers(searchParams)
      // TODO: Make user filter by dancerName on BE in next PR

      this.$nextTick(() => {
        this.users = users
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/style/mixins';

.search {
  &__users {
    & .v-badge__badge {
      @include font-caption2;

      min-width: 17px !important;
      height: 17px !important;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.search {
  &.v-badge__badge {
    min-width: 15px !important;
    height: 15px !important;
  }

  position: relative;

  &__subtitle-email {
    @include font-h6;

    color: var(--color-grey-300);
  }

  &__list {
    position: absolute;
    z-index: 10;
    width: 100%;
    max-height: 450px;
    overflow-y: scroll;
  }
}

.chip.search__users {
  display: block;
  width: 100%;
  height: inherit !important;
  padding: 20px 16px;
  border-bottom: 1px solid;
  border-bottom-color: var(--color-grey-600) !important;
  border-radius: 0;

  .chip__close-icon {
    position: absolute;
    right: -2px;
  }
}

.text-chip {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
