<template>
  <article :class="['judging-row', { highlighted: isHighlighted, 'is-edit-mode': isEditMode }]">
    <div class="name-menu-wrap">
      <p
        v-if="isCrew"
        class="info-battle"
      >
        <span
          v-if="participant.randId"
          class="user-order"
        >
          {{ participant.randId }}.
        </span>
        {{ participant.name }}
      </p>

      <router-link
        v-else
        :to="{ name: 'Profile', params: { id: participant.id } }"
        class="user-link"
      >
        <span
          v-if="participant.randId"
          class="user-order"
        >
          {{ participant.randId }}.
        </span>
        <span class="participant-name">{{ participant.dancerName }}</span>
      </router-link>
      <div
        v-if="$route.name === 'Group'"
        class="btn-menu-container"
      >
        <v-btn
          v-if="event.permissions.canEdit"
          icon
          type="button"
          @click="isShowActions = !isShowActions"
        >
          <img
            v-if="!isShowActions"
            alt="open"
            src="/img/ic_Menu-Elipses-Horizontal.svg"
          />
          <img
            v-else
            alt="close"
            src="/img/service-worker.svg"
          />
        </v-btn>
      </div>
    </div>

    <div class="actions-container">
      <slot
        :is-show-actions="isShowActions"
        name="actions"
      />
    </div>
    <template v-if="isEditMode">
      <section class="points">
        <JudgingPoint
          v-for="point in availablePoints"
          :key="point"
          :active="pointsSelected === point"
          :number="point"
          @click="setPoint(point)"
        />

        <JudgingPoint
          :active="isStarActive"
          :icon="mdiStarOutline"
          @click="toggleStar"
        />
      </section>
    </template>

    <template v-else>
      <div class="average-box">
        <div class="average-content">
          <span class="average-text">{{ participant.pointsAverage || '-' }}</span>
          <v-icon
            v-if="participant.prelims.length"
            class="chevron-icon"
            size="30"
            @click="isPointDetailsOpened = !isPointDetailsOpened"
          >
            {{ isPointDetailsOpened ? mdiChevronUp : mdiChevronDown }}
          </v-icon>
        </div>

        <v-checkbox
          v-if="canSetGone"
          v-model="participant.isGone"
          color="var(--color-blue-800)"
          hide-details="auto"
          @change="$emit('gone', participant.id)"
        />
      </div>

      <section
        v-if="isPointDetailsOpened"
        class="points-details"
      >
        <div
          v-for="judge in participant.prelims"
          :key="judge.id"
          class="judge"
        >
          <span class="points-value">{{ transformPoint(judge.points) }}</span>
          <div class="judge-info">
            <v-avatar size="32">
              <v-img :src="getAvatar(judge.avatar, 50)" />
            </v-avatar>
            <span class="name">{{ judge.dancerName }}</span>
          </div>
        </div>

        <BKButton
          v-if="canEdit"
          :loading="loading"
          class="edit-btn"
          color="var(--color-blue-800)"
          white-text
          @click="toggleEditMode"
        >
          Edit points
        </BKButton>
      </section>
    </template>

    <section
      v-if="isEditMode && isShowDoneButton"
      class="actions"
    >
      <BKButton
        v-if="canEdit"
        :loading="loading"
        class="done-btn"
        color="var(--color-blue-800)"
        outlined
        @click="toggleEditMode"
      >
        Done
      </BKButton>
    </section>
  </article>
</template>

<script>
import { mapGetters } from 'vuex'
import Image from '@/mixins/image'
import JudgingPoint from '@/components/Battle/Prelims/JudgingPoint.vue'
import api from '@/api'
import { mdiChevronDown, mdiChevronUp, mdiStarOutline } from '@mdi/js'

export default {
  name: 'PrelimsScore',
  components: {
    JudgingPoint,
  },
  mixins: [Image],
  props: {
    participant: {
      type: Object,
      required: true,
    },
    battleId: {
      type: Number,
      required: true,
    },
    judgment: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    canSetGone: {
      type: Boolean,
      required: false,
      default: false,
    },
    isHighlighted: {
      type: Boolean,
      required: false,
      default: false,
    },
    isGrayedOut: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCrew: {
      type: Boolean,
      required: false,
      default: false,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mdiChevronUp,
      mdiChevronDown,
      mdiStarOutline,
      isEditMode: false,
      isPointDetailsOpened: false,
      loading: false,
      availablePoints: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      pointsSelected: Math.floor(this.judgment && this.judgment.points),
      isStarActive: this.judgment && this.judgment.points % 1 !== 0,
      isShowDoneButton: false,
      isShowActions: false,
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
  },
  mounted() {
    if (this.judgment && !this.judgment.points && this.canEdit) {
      this.isEditMode = true
      this.$emit('edit')
    }
  },
  methods: {
    updatePointsInList() {
      this.judgment.points = this.isStarActive ? this.pointsSelected + 0.5 : this.pointsSelected
    },
    setPoint(point) {
      this.pointsSelected = point === this.pointsSelected ? 0 : point

      if (!this.isShowDoneButton || !this.judgment.points) {
        this.updatePoints()
      }
    },
    toggleStar() {
      this.isStarActive = !this.isStarActive
      if (!this.isShowDoneButton || !this.judgment.points) {
        this.updatePoints()
      }
    },
    handleUpdate() {
      this.loading = true
      this.updatePointsInList()
      this.loading = false
    },
    async updatePoints() {
      this.handleUpdate()

      const updatedPrelims = await api.prelims.setJudgment(this.battleId, [this.judgment])

      this.loading = false
      if (!updatedPrelims) return
      this.$emit('update:prelims', updatedPrelims)
    },
    toggleEditMode() {
      if (this.isEditMode) {
        this.isEditMode = false
        this.isShowDoneButton = false
        this.$emit('cancel')
        this.updatePoints()
      } else {
        this.isEditMode = true
        this.isShowDoneButton = true
        this.$emit('edit')
      }
    },
    transformPoint(point) {
      if (Math.floor(point) < 10) {
        return `0${point.toFixed(1)}`
      }
      return point.toFixed(1)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.judging-row {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 40px 20px;
  background-color: inherit;

  &.is-edit-mode {
    background-color: transparent !important;
  }

  .info-battle {
    margin-bottom: 0;

    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }

  .name-menu-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-menu-container {
      @media (max-width: 768px) {
        padding: 0 20px;
      }
    }
  }

  &.highlighted {
    border: 2px solid var(--color-orange-500);
  }

  @media (max-width: 768px) {
    padding: 40px 0;
  }
}

.actions-container {
  padding: 0 20px;
}

.user-link {
  display: flex;
  align-items: center;
  width: max-content;
  color: var(--color-dark-800) !important;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}

.points {
  display: flex;
  padding: 0 20px;
  overflow-x: scroll;
  column-gap: 24px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.average-box {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .average-content {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  .average-text {
    color: var(--color-grey-300);
    font-weight: var(--font-weight-bold);
    font-size: 44px;
    line-height: 50px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}

.points-details {
  display: grid;
  row-gap: 16px;

  .points-value {
    color: var(--color-grey-300);
    font-size: 44px;
    line-height: 50px;
  }

  .judge {
    display: grid;
    grid-template-columns: 86px 1fr;
    gap: 18px;
    align-items: center;

    .name {
      color: var(--color-grey-300);
    }

    .judge-info {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}

.actions {
  padding: 0 20px;

  .done-btn {
    margin-top: 20px;
  }
}
</style>
