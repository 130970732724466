<template>
  <section class="authentication">
    <div class="background" />
    <div class="signup-form">
      <h2 class="signup-form__title page-title">Create Profile</h2>
      <v-card
        v-if="isIncomplete"
        class="mb-6"
        color="#FFEDDC"
        height="68"
      >
        <v-card-text class="signup-form__incomplete-text text-center">
          Hey it looks like your profile is incomplete.<br />
          Finish setting up your profile to access the site
        </v-card-text>
        <v-btn
          class="signup-form__incomplete-close"
          icon
          @click="isIncomplete = false"
        >
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-card>

      <component
        :is="currentForm"
        @signup="currentForm = 'OnboardingForm'"
      />
    </div>
  </section>
</template>

<script>
import OnboardingForm from '@/components/Onboarding/OnboardingForm.vue'
import { mapGetters } from 'vuex'
import { mdiClose } from '@mdi/js'
import StartFormSignup from './StartFormSignup.vue'

export default {
  components: {
    StartFormSignup,
    OnboardingForm,
  },
  data() {
    return {
      mdiClose,
      currentForm: 'StartFormSignup',
      isIncomplete: this.$route.query.incomplete,
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
  },
  watch: {
    getUserDB() {
      if (this.getUserDB.uid) {
        this.currentForm = 'OnboardingForm'
      }
    },
  },
  mounted() {
    if (!this.getUserDB) return
    this.currentForm = 'OnboardingForm'

    if (this.getUserDB.dancerName) {
      this.$router.push({ name: 'Home' })
    }
  },
  updated() {
    document.body.scrollIntoView()
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.authentication {
  @include profile-settings-bg;
}

.signup-form {
  position: relative;
  width: 100%;
  max-width: 460px;
  min-height: 500px;
  margin: 180px auto 0;
  padding: 0 20px;

  &__title {
    @include font-h1;

    margin-bottom: 40px;
    text-align: center;
    text-transform: uppercase;
  }

  &__incomplete-text {
    color: #fc7800 !important;
  }

  &__incomplete-close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: var(--color-grey-400);
  }
}
</style>
