import Vue from 'vue'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import './plugins/components'
import './registerServiceWorker'
import VueTheMask from 'vue-the-mask'
import InfiniteLoading from 'vue-infinite-loading'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSanitize from 'vue-sanitize'
import VueMoment from 'vue-moment'
import Bugsnag from '@bugsnag/js'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import App from './App.vue'
import vueSanitizeConfig from './common/vueSanitizeConfig'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/firebase'
import router from './router'

if (process.env.VUE_APP_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    appVersion: process.env.VUE_APP_CODE_VERSION,
    releaseStage: process.env.VUE_APP_STAGE,
    // eslint-disable-next-line consistent-return
    onError(event) {
      if (
        /Loading.*chunk.*failed./i.test(event.errors[0].errorMessage) ||
        /ChunkLoadError:.*failed./i.test(event.errors[0].errorMessage)
      ) {
        return false
      }
    },
  })

  const bugsnagVue = Bugsnag.getPlugin('vue')
  if (bugsnagVue) {
    bugsnagVue.installVueErrorHandler(Vue)
  }
}

Vue.config.productionTip = false

Vue.use(InfiniteLoading)
Vue.use(VueMoment)
Vue.use(VueTheMask)
Vue.config.devtools = true
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_API_KEY,
    libraries: ['geometry', 'places'],
  },
})

Vue.use(VueSanitize, vueSanitizeConfig)

const auth = getAuth()

let app

onAuthStateChanged(auth, () => {
  if (!app) {
    app = new Vue({
      store,
      router,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app')
  }
})
