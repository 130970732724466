<template>
  <ModalWrapper
    :is-show="isShowEditor"
    modal-width="550px"
    @update:isShow="$emit('update:isShowEditor', $event)"
  >
    <v-card class="pa-6">
      <h2 class="add-media-title">Add media</h2>
      <section class="content">
        <v-text-field
          v-if="!newMedia.link"
          v-model="videoUrl"
          autocomplete="off"
          background-color="#fff"
          hide-details="auto"
          outlined
          placeholder="https://www.youtube.com/watch?v=id"
        />

        <v-text-field
          v-else
          v-model="newMedia.name"
          autocomplete="off"
          background-color="#fff"
          outlined
          placeholder="Media name"
        />

        <iframe
          v-if="videoEmbedUrl"
          :src="videoEmbedUrl"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          height="300"
          title="Media editor"
          width="500"
        />
      </section>
      <section class="add-btn">
        <BKButton
          v-if="!newMedia.link"
          white-text
          @click="addMedia"
        >
          Add
        </BKButton>
      </section>
      <section
        v-if="newMedia.link"
        class="actions"
      >
        <BKButton
          color="var(--color-green)"
          white-text
          @click="saveMedia"
        >
          Save
        </BKButton>
        <BKButton
          color="var(--color-orange-500)"
          white-text
          @click="resetMediaEditor"
        >
          Cancel
        </BKButton>
      </section>
    </v-card>
  </ModalWrapper>
</template>

<script>
import api from '@/api'

import { ModalWrapper } from '@/shared/ui/modals'

export default {
  name: 'BKMediaEditor',
  components: {
    ModalWrapper,
  },
  props: {
    isShowEditor: {
      type: Boolean,
      required: true,
    },
    orgId: {
      type: Number,
      required: true,
    },
    eventId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      videoUrl: '',
      videoEmbedUrl: '',
      newMedia: {
        link: '',
      },
    }
  },
  watch: {
    isShowEditor(val) {
      if (!val) {
        this.resetMediaEditor()
      }
    },
  },
  methods: {
    resetMediaEditor() {
      this.videoUrl = ''
      this.videoEmbedUrl = ''
      this.newMedia = {
        link: '',
      }
    },
    addMedia() {
      try {
        const mediaId = this.getYouTubeMediaId(this.videoUrl)

        if (!mediaId) return

        this.videoEmbedUrl = `https://www.youtube.com/embed/${mediaId}`
        this.newMedia.link = mediaId
      } catch (err) {
        // TODO: handle this error
        console.log('err', err)
      }
    },
    getYouTubeMediaId(url) {
      // https://www.youtube.com/watch?v=32I0Qso4sDg&ab_channel=Hoonigan => 32I0Qso4sDg
      const [, id] = url.match(/(?:[?&]v=|\/embed\/|\/1\/|\/v\/|https:\/\/(?:www\.)?youtu\.be\/)([^&\n?#]+)/)
      return id
    },
    async saveMedia() {
      const mediaPayload = { ...this.newMedia, orgId: this.orgId, eventId: this.eventId }
      const updatedMedia = await api.media.addMedia(mediaPayload)
      if (updatedMedia) this.$emit('save', updatedMedia)
    },
  },
}
</script>

<style lang="scss" scoped>
.add-media-title {
  margin-bottom: 6px;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.content {
  @media (max-width: 768px) {
    padding: 20px 20px 0;
  }
}

.add-btn {
  margin-top: 20px;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}
</style>
