import { callPrivateApi } from '@/api/apiHelper'
import { initPrelimsResp } from '@/api/adapter'

export const setJudgment = async (battleId, payload) => {
  const data = await callPrivateApi('post', `/battles/${battleId}/prelims`, payload)
  return initPrelimsResp(data)
}

export const setGone = async (battleId, participantId) => {
  const data = await callPrivateApi('post', `/battles/${battleId}/prelims/gone/${participantId}`)
  return initPrelimsResp(data)
}

export const finishPrelims = async (battleId) => {
  const data = await callPrivateApi('post', `/battles/${battleId}/prelims/finish`)
  return initPrelimsResp(data)
}

export const getPrelims = async (battleId, sortBy) => {
  const data = await callPrivateApi('get', `/battles/${battleId}/prelims`, { params: { sb: sortBy } })
  return initPrelimsResp(data)
}

export const reshuffleParticipants = async (battleId) => {
  const data = await callPrivateApi('post', `/battles/${battleId}/reshuffle`)
  return initPrelimsResp(data)
}

export const createGroups = async (battleId, payload) => {
  const data = await callPrivateApi('post', `/battles/${battleId}/prelims/create-groups`, payload)
  return initPrelimsResp(data)
}

export const moveToAnotherGroup = async (battleId, participantId, selectedGroupId) => {
  const data = await callPrivateApi('post', `/battles/${battleId}/prelims/${participantId}/move/${selectedGroupId}`)
  return initPrelimsResp(data)
}

export const undoBrackets = async (battleId) => callPrivateApi('delete', `/battles/${battleId}/brackets`)
