<template>
  <div class="gmap-input">
    <gmap-autocomplete
      :placeholder="' '"
      :value="value.address || ''"
      class="gmap-input__autocomp"
      @place_changed="locationChanged"
    >
      <template v-slot:input="autocompleteSlotProps">
        <v-text-field
          ref="input"
          background-color="#fff"
          outlined
          @attrs="autocompleteSlotProps.attrs"
          @listeners="autocompleteSlotProps.listeners"
        />
      </template>
    </gmap-autocomplete>
    <!--    eslint-disable vuejs-accessibility/label-has-for-->
    <label class="gmap-input__placeholder">
      {{ placeholder }}
    </label>
    <!--    eslint-enable vuejs-accessibility/label-has-for-->
  </div>
</template>

<script>
export default {
  name: 'GmapsPlaceInput',
  props: {
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  methods: {
    locationChanged(location) {
      this.$emit('input', {
        address: location.formatted_address,
        lat: location.geometry.location.lat(),
        lng: location.geometry.location.lng(),
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/style/mixins.scss'

.gmap-input
  position: relative

  &__placeholder
    position: absolute
    left: 10px
    background-color: white
    pointer-events: none
    padding: 0 3px
    top: 15px
    transition: .3s
    color: rgba(0, 0, 0, .6)

  &__autocomp
    width: 100%
    border: 1px solid rgba(0, 0, 0, .38)
    padding: 15px 8px
    border-radius: 5px
    margin-bottom: 10px
    outline: none

    &:focus ~ .gmap-input__placeholder,
    &:not(:placeholder-shown) ~ .gmap-input__placeholder
      @include font-body2
      top: -10px

    &:focus ~ .gmap-input__placeholder
      color: #1976d2

    &:focus
      margin-bottom: 8px
      border-width: 2px
      border-color: #1976d2
</style>
