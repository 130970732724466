<template>
  <router-link
    class="user-link"
    :to="{ name: 'Profile', params: { id: user.id } }"
  >
    <v-avatar
      class="mr-1"
      size="32px"
    >
      <v-img :src="getAvatar(user.avatar, 32)" />
    </v-avatar>
    {{ user.dancerName }}
  </router-link>
</template>

<script>
import Images from '@/mixins/image'

export default {
  name: 'EventStaffUserChip',
  mixins: [Images],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.user-link {
  color: var(--color-black);
}
</style>
