<template>
  <section class="create-other-profile">
    <div class="background" />
    <div class="create-other-profile__form">
      <h2 class="create-other-profile__title page-title">Create Profile</h2>

      <OnboardingForm :add-to-existing="true" />
    </div>
  </section>
</template>

<script>
import OnboardingForm from '@/components/Onboarding/OnboardingForm.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    OnboardingForm,
  },
  computed: {
    ...mapGetters(['getUserDB']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global';
@import '@/assets/style/mixins';

.create-other-profile {
  @include profile-settings-bg;
}

.create-other-profile__form {
  position: relative;
  width: 100%;
  max-width: 460px;
  min-height: 500px;
  margin: 180px auto 0;
  padding: 0 20px;
}

.create-other-profile__title {
  @include font-h1;

  margin-bottom: 40px;
  text-align: center;
  text-transform: uppercase;
}
</style>
