import { BATTLE_CATEGORIES } from '@/common/constants'

const Battles = {
  methods: {
    getBattleCategory(crewSize) {
      const category = BATTLE_CATEGORIES.find((item) => item.value === crewSize)
      return category.text
    },
  },
}

export default Battles
