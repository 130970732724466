<template>
  <v-dialog
    v-model="isShowValue"
    max-width="500"
    overlay-color="var(--color-black)"
    overlay-opacity="0.9"
  >
    <div class="confirmation-dialog">
      <div class="icon-wrapper">
        <slot name="icon">
          <img
            alt="alert icon"
            aria-hidden="true"
            class="dialog-icon"
            height="40"
            src="/img/ic_Alert.svg"
            width="40"
          />
        </slot>
      </div>
      <div class="dialog-content">
        <h2 class="dialog-title">
          {{ title }}
        </h2>
        <div class="dialog-subtitle">
          <slot />
        </div>
      </div>
      <div class="dialog-actions">
        <BKButton
          class="action"
          white-text
          @click="emit('confirm')"
        >
          {{ confirmText }}
        </BKButton>
        <BKButton
          class="action"
          outlined
          @click="isShowValue = false"
        >
          Cancel
        </BKButton>
      </div>
      <button
        class="close-button"
        type="button"
        @click="isShowValue = false"
      >
        <v-icon
          color="var(--color-white)"
          size="24"
        >
          {{ mdiClose }}
        </v-icon>
        Close
      </button>
    </div>
  </v-dialog>
</template>

<script lang="ts" setup>
import { mdiClose } from '@mdi/js'
import { defineEmits, ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    isShow: boolean
    title?: string
    confirmText?: string
  }>(),
  {
    title: '',
    confirmText: 'Confirm',
  }
)
const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'confirm'): void
}>()
const isShowValue = ref(props.isShow)

watch(
  () => props.isShow,
  (newVal) => {
    isShowValue.value = newVal
  }
)

watch(isShowValue, (val) => {
  if (!val) {
    emit('cancel')
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

:deep(.v-dialog) {
  width: calc(100% - 40px);
  margin: 0 20px;
  overflow: visible;
}

.confirmation-dialog {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: max-content;
  width: 100%;
  padding: 40px 16px;
  background-color: var(--color-white);
  border-radius: var(--border-radius-rounded);

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
  }

  .dialog-icon {
    width: 40px;
    height: 40px;
  }

  .dialog-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    width: 100%;
  }

  .dialog-title {
    @include font-h3;

    color: var(--color-dark-800);
    text-align: center;
  }

  .dialog-subtitle {
    color: var(--color-dark-800);
    text-align: center;
  }

  .dialog-actions {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: stretch;
    justify-content: center;
    width: 100%;
  }

  .close-button {
    position: absolute;
    top: -40px;
    right: 0;
    display: flex;
    align-items: center;
    color: var(--color-white);
    font-weight: var(--font-weight-bold);

    &:focus-visible {
      border-radius: var(--border-radius-rounded);
      outline: 1px solid var(--color-white);
    }
  }
}
</style>
