<template>
  <article
    :class="{ disabled: disabled }"
    class="round"
  >
    <div class="points">
      <JudgingPoint
        v-for="point in availablePoints"
        :key="point"
        :active="point === Math.floor(redPoints)"
        :number="point"
        @click="handleClickPoint('red', point)"
      />
      <JudgingPoint
        :active="redHasStar"
        :icon="mdiStarOutline"
        @click="handleClickStar('red')"
      />
    </div>

    <div class="name">
      <span>{{ name }}</span>
    </div>

    <div class="points right">
      <JudgingPoint
        v-for="point in availablePoints"
        :key="point"
        :active="point === Math.floor(bluePoints)"
        :number="point"
        @click="handleClickPoint('blue', point)"
      />
      <JudgingPoint
        :active="blueHasStar"
        :icon="mdiStarOutline"
        @click="handleClickStar('blue')"
      />
    </div>
  </article>
</template>

<script>
import JudgingPoint from '@/components/Battle/Prelims/JudgingPoint.vue'
import { mdiStarOutline } from '@mdi/js'

export default {
  name: 'SparringRoundScoreEditor',
  components: { JudgingPoint },
  props: {
    round: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      mdiStarOutline,
      availablePoints: [6, 7, 8, 9, 10],
      redPoints: 0,
      bluePoints: 0,
      redHasStar: false,
      blueHasStar: false,
    }
  },
  computed: {
    winnerColor() {
      if (this.round.sumRedPoints === this.round.sumBluePoints) return 'none'
      return this.round.sumRedPoints > this.round.sumBluePoints ? 'red' : 'blue'
    },
  },
  mounted() {
    this.initScore()
  },
  methods: {
    initScore() {
      this.redPoints = Math.floor(this.round.redPoints)
      this.bluePoints = Math.floor(this.round.bluePoints)
      this.redHasStar = this.round.redPoints % 1 !== 0
      this.blueHasStar = this.round.bluePoints % 1 !== 0
    },
    handleClickPoint(color, value) {
      this[`${color}Points`] = value
      this.round[`${color}Points`] = this[`${color}Points`] + (this[`${color}HasStar`] ? 0.5 : 0)
      this.$emit('savePoints', this.round)
    },
    handleClickStar(color) {
      this[`${color}HasStar`] = !this[`${color}HasStar`]
      this.round[`${color}Points`] = this[`${color}Points`] + (this[`${color}HasStar`] ? 0.5 : 0)
      this.$emit('savePoints', this.round)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.round {
  display: grid;
  grid-template-columns: 1fr 0.4fr 1fr;

  &.disabled {
    filter: opacity(0.3);
    pointer-events: none;
  }

  .name {
    @include font-body2;

    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 40px;
    margin: 0 auto;

    span {
      display: block;
      text-align: center;
    }
  }

  .points {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    justify-content: flex-start;

    &.right {
      justify-content: flex-end;
    }
  }
}
</style>
