<template>
  <div class="dancer-card">
    <img
      v-if="avatarUrl"
      :src="avatarUrl"
      alt="instructor photo"
      class="dancer-card__photo"
    />
    <picture
      v-else-if="user.card.length"
      class="dancer-card__photo"
    >
      <source
        :srcset="`${getAvatar(user.card, 363, 'webp')} 1x,
          ${getAvatar(user.card, 726, 'webp')} 2x,
          ${getAvatar(user.card, 1090, 'webp')} 3x`"
      />
      <img
        :src="getAvatar(user.card, 363)"
        alt="instructor photo"
      />
    </picture>
    <img
      v-else
      alt="instructor photo"
      class="dancer-card__default-photo"
      src="/img/profile/default-avatar.svg"
    />
    <div class="dancer-card__overlay" />
    <div class="dancer-card__content">
      <div class="content-top">
        <!--        eslint-disable vuejs-accessibility/form-control-has-label-->
        <input
          v-if="user.canEdit"
          ref="inputFile"
          accept="image/png, image/jpeg"
          name="userImage"
          style="display: none"
          type="file"
          @change="handlerFileChange"
        />
        <!--        eslint-enable vuejs-accessibility/form-control-has-label-->
        <v-btn
          v-if="user.canEdit"
          class="image-button"
          height="32"
          @click="openFileDialog"
        >
          <span class="image-button__icon" />
          <span class="image-button__text">
            {{ user.card.length ? 'Edit image' : 'Add image' }}
          </span>
        </v-btn>
        <img
          :src="getFlag(user.country)"
          alt="instructor's country flag"
          class="flag"
        />
      </div>
      <div class="details">
        <div class="name">
          {{ user.dancerName }}
        </div>
        <div class="location">{{ user.city }}, {{ user.country }}</div>
        <ul class="stats">
          <li class="item events-attended">
            <div class="icon" />
            <div class="amount">
              {{ user.eventsAttended }}
            </div>
          </li>
          <li class="item battles-entered">
            <div class="icon" />
            <div class="amount">
              {{ user.battlesEntered }}
            </div>
          </li>
          <li class="item battles-won">
            <div class="icon" />
            <div class="amount">
              {{ user.battlesWon }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Images from '@/mixins/image'
import { ImageActions } from '@/services/image'

export default {
  mixins: [Images],
  props: {
    user: {
      type: Object,
      required: true,
    },
    avatarUrl: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    openFileDialog() {
      this.$refs.inputFile.click()
    },
    async handlerFileChange(e) {
      const filesImgAvatar = e.target.files || e.dataTransfer.files
      if (!filesImgAvatar.length > 0) return

      const reader = new FileReader()
      reader.onload = (event) => {
        this.$emit('avatarLoaded', event.target.result)
      }
      reader.readAsDataURL(filesImgAvatar[0])

      await this.saveAvatarChanges(filesImgAvatar[0])
    },
    async saveAvatarChanges(file) {
      const avatarId = await ImageActions.saveImage(file)
      this.$emit('changeAvatar', avatarId)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.dancer-card {
  position: relative;
  width: 100%;
  max-width: 305px;
  height: 363px;
  overflow: hidden;
  background: var(--color-grey-500);
  border-radius: 6px;
  filter: drop-shadow(0 1px 4px rgb(0 0 0 / 20%));

  &__overlay {
    $top-indent: 18.47%;

    position: absolute;
    top: $top-indent;
    width: 100%;
    height: calc(100% - #{$top-indent});
    background: linear-gradient(180deg, rgb(0 0 0 / 0.01%) 0%, rgb(0 0 0 / 90%) 100%);
    border-radius: 6px;
  }

  &__photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 6px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__default-photo {
    position: absolute;
    top: 12px;
    left: 50%;
    width: 196px;
    height: 196px;
    transform: translateX(-50%);
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
    padding: 12px;

    .content-top {
      display: flex;
      justify-content: space-between;
    }

    .image-button {
      margin-right: auto;

      &__icon {
        width: 16px;
        height: 16px;
        background: url('/img/ic_Image.svg') no-repeat center;
      }

      &__text {
        margin-bottom: -3px;
        font-weight: var(--font-weight-bold);
        letter-spacing: 0;
      }

      &::v-deep .v-btn__content {
        gap: 2px;
        align-items: center;
      }
    }

    .flag {
      box-sizing: content-box;
      width: 20px;
      height: 20px;
      margin-left: auto;
      border: 2px solid var(--color-white);
      border-radius: 50%;
    }

    .details {
      .name {
        @include font-h3;

        color: var(--color-white);
        font-weight: var(--font-weight-bold);
        text-align: center;
      }

      .location {
        margin-top: 4px;
        color: var(--color-white);
        text-align: center;
      }

      .stats {
        display: flex;
        justify-content: space-between;
        margin: 12px 0 20px;
        padding: 0 32px;

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 46px;

          .icon {
            width: 24px;
            height: 24px;
            background: white;
          }

          .amount {
            margin-top: 4px;
            color: var(--color-grey-600);
            text-align: center;
          }

          &.events-attended {
            .icon {
              mask: url('/img/ic_Calendar.svg') no-repeat center;
            }
          }

          &.battles-entered {
            .icon {
              mask: url('/img/ic_Compete.svg') no-repeat center;
            }
          }

          &.battles-won {
            .icon {
              mask: url('/img/ic_Trophy.svg') no-repeat center;
            }
          }
        }
      }
    }
  }
}
</style>
