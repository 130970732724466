<template>
  <OnboardingFormLayout
    :current-step="currentStep"
    :error="error"
    :steps="steps"
  >
    <template #default>
      <li
        :class="{ active: avatarUrl }"
        class="flip-container"
      >
        <div class="flipper">
          <ProfileDancerCard
            :user="user"
            class="front"
            @avatarLoaded="avatarLoaded($event)"
            @changeAvatar="$emit('changeCard', $event)"
          />
          <ProfileDancerCard
            :avatar-url="avatarUrl"
            :user="user"
            class="back"
            @avatarLoaded="avatarLoaded($event)"
            @changeAvatar="$emit('changeCard', $event)"
          />
        </div>
      </li>
    </template>
    <template #actions>
      <BKButton
        outlined
        @click="$emit('goBack')"
      >
        back
      </BKButton>

      <BKButton
        :disabled="loading"
        :loading="loading"
        white-text
        @click="$emit('submitForm', user.card)"
      >
        create profile
      </BKButton>
    </template>
  </OnboardingFormLayout>
</template>

<script>
import BKButton from '@/components/AppShared/BKButton.vue'
import OnboardingFormLayout from '@/components/Onboarding/OnboardingFormLayout.vue'
import ProfileDancerCard from '@/components/Profile/ProfileDancerCard.vue'

export default {
  components: {
    BKButton,
    OnboardingFormLayout,
    ProfileDancerCard,
  },
  props: {
    currentStep: {
      type: Number,
      default: 0,
    },
    error: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    steps: {
      type: Number,
      default: 0,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      avatarUrl: '',
    }
  },
  methods: {
    avatarLoaded(avatarUrl) {
      this.avatarUrl = avatarUrl
    },
  },
}
</script>

<style lang="scss" scoped>
.flip-container {
  width: 100%;
  max-width: 305px;
  height: 363px;
  margin: 0 auto;
  perspective: 1000px;
}

.flipper {
  position: relative;
  height: 100%;
  transform-style: preserve-3d;
  transition: 0.6s;
}

.flip-container.active .flipper {
  transform: rotateY(180deg);
}

.front,
.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
}

.front {
  z-index: 2;
  transform: rotateX(0deg);
}

.back {
  transform: rotateY(180deg);
}
</style>
