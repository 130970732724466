<template>
  <article class="participant">
    <v-avatar>
      <v-badge
        :value="!participant.confirmed"
        bottom
        color="#ff6f00"
        offset-x="20"
        offset-y="17"
      >
        <span slot="badge">?</span>
        <v-avatar
          class="mr-2"
          left
          size="32"
        >
          <v-img :src="getAvatar(participant.avatar, 100)" />
        </v-avatar>
      </v-badge>
    </v-avatar>
    <div class="name">
      {{ participant.dancerName }}
    </div>
    <div class="action">
      <div
        v-if="participant.checkedIn"
        class="checkin-label"
      >
        <v-icon class="check-icon">
          {{ mdiCheck }}
        </v-icon>
        Checked-in
      </div>

      <template v-if="canEdit && !participant.checkedIn">
        <v-btn
          :loading="loading"
          class="ml-auto white--text font-weight-bold"
          color="var(--color-green)"
          height="32"
          tile
          @click="checkInParticipant"
        >
          Check-In
        </v-btn>
      </template>
    </div>
  </article>
</template>

<script>
import Images from '@/mixins/image'
import api from '@/api'
import { mdiCheck } from '@mdi/js'

export default {
  name: 'BattleCrewDetailsParticipant',
  mixins: [Images],
  props: {
    participant: {
      type: Object,
      required: true,
    },
    eventId: {
      type: Number,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      mdiCheck,
      loading: false,
    }
  },
  methods: {
    async checkInParticipant() {
      this.loading = true

      const updatedEvent = await api.events.checkInUser(this.eventId, this.participant.id)
      if (updatedEvent) this.$emit('update:event', updatedEvent)

      this.$emit('reload:battle')

      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.participant {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-grey-600);

  .name {
    margin-left: 6px;
  }

  .action {
    margin-left: auto;
  }

  .checkin-label {
    color: var(--color-green);
  }

  .check-icon {
    margin-right: 9px;
    color: var(--color-green) !important;
  }
}
</style>
