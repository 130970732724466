import { render, staticRenderFns } from "./UserAlert.vue?vue&type=template&id=f1ed0c8e&scoped=true"
import script from "./UserAlert.vue?vue&type=script&lang=ts&setup=true"
export * from "./UserAlert.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./UserAlert.vue?vue&type=style&index=0&id=f1ed0c8e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1ed0c8e",
  null
  
)

export default component.exports