<template>
  <OnboardingFormLayout
    :current-step="currentStep"
    :error="error"
    :steps="steps"
    title="Full name"
  >
    <template #default>
      <v-form v-model="valid">
        <v-text-field
          v-model="firstName"
          :rules="[rules.required]"
          autocomplete="off"
          background-color="var(--color-white)"
          hide-details
          label="First name*"
          outlined
          @keyup.enter="$nextTick(() => $refs.lastname.focus())"
        />
        <v-text-field
          ref="lastname"
          v-model="lastName"
          :rules="[rules.required]"
          autocomplete="off"
          background-color="var(--color-white)"
          hide-details
          label="Last name*"
          outlined
          @keyup.enter="$emit('submitForm', fullName)"
        />
      </v-form>
    </template>
    <template #actions>
      <BKButton
        :disabled="loading || !valid"
        :loading="loading"
        white-text
        @click="$emit('submitForm', fullName)"
      >
        next
      </BKButton>
    </template>
  </OnboardingFormLayout>
</template>

<script>
import validationRules from '@/mixins/validation'
import BKButton from '@/components/AppShared/BKButton.vue'
import OnboardingFormLayout from '@/components/Onboarding/OnboardingFormLayout.vue'

export default {
  components: {
    BKButton,
    OnboardingFormLayout,
  },
  mixins: [validationRules],
  props: {
    currentStep: {
      type: Number,
      default: 0,
    },
    error: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    steps: {
      type: Number,
      default: 0,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      valid: true,
    }
  },
  computed: {
    fullName() {
      return `${this.firstName} ${this.lastName}`
    },
  },
  watch: {
    user: {
      handler() {
        // eslint-disable-next-line prefer-destructuring
        this.firstName = this.user.fullName.split(' ')[0]
        // eslint-disable-next-line prefer-destructuring
        this.lastName = this.user.fullName.split(' ')[1]
      },
      deep: true,
    },
  },
}
</script>
