<template>
  <section class="cardwall__cards cards">
    <div class="background" />
    <v-container class="wrapper">
      <v-row>
        <div class="cardwall__filter-bar filter-bar">
          <v-row class="filter-bar__row">
            <v-col
              class="px-0 mr-md-3 ml-md-0 mt-n1"
              cols="5"
              md="auto"
              sm="3"
            >
              <v-btn
                class="px-1 text-none d-flex align-center"
                color="var(--color-grey-500)"
                text
                @click="sortCards"
              >
                Sort by:&nbsp;
                <span class="white--text">{{ sortAZ ? 'A-Z' : 'Z-A' }}</span>
                &nbsp;
                <v-icon
                  class="mt-n1"
                  color="white"
                  size="19"
                >
                  {{ mdiSort }}
                </v-icon>
              </v-btn>
            </v-col>
            <!--<v-col cols="12" sm="3" md="auto" class="mt-n1 mx-md-3 px-0 d-none d-sm-block">
              <v-menu offset-y :close-on-content-click="false" min-width="250px">
                <template v-slot:activator="{ on }">
                  <v-btn color="#999999" class="text-none px-1" text v-on="on">
                    Location
                    <v-icon color="white">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list class="list" dense>
                  <v-list-item v-for="(location, index) in locationForSearch" :key="index">
                    <v-checkbox
                      off-icon="mdi-checkbox-blank-outline blue--text"
                      color="blue"
                      class="ma-0 mb-n2"
                      v-model="locationsSelects"
                      :label="location"
                      :value="location"
                    ></v-checkbox>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>-->
            <v-col
              class="ml-sm-auto px-0 pl-sm-7"
              cols="12"
              md="3"
              order="first"
              order-sm="last"
              sm="4"
            >
              <v-text-field
                id="search-cards"
                v-model="searchParams.keyword"
                :append-icon="mdiMagnify"
                :background-color="backgroundColor"
                :label="`Search ${countCards} cards`"
                class="search ml-auto mb-n5 mt-n3"
                color="#999999"
                dark
                solo
                @blur="blurSearch"
                @focus="focusSearch"
              />
            </v-col>
            <v-col
              class="d-none d-sm-block"
              cols="12"
              order-sm="last"
            >
              <v-chip
                v-for="(location, index) in locationsSelects"
                :key="index"
                class="white--text ma-1 text-uppercase"
                close
                color="var(--color-green)"
                @click:close="removeChip(index)"
              >
                {{ location }}
              </v-chip>
            </v-col>
            <v-col
              class="d-blok d-sm-none ml-auto mt-n2 px-0"
              cols="auto"
            >
              <!--<v-btn @click="isShowFilter = !isShowFilter" dark icon x-large>
                <v-icon size="30px">mdi-format-list-checks</v-icon>
              </v-btn>-->
            </v-col>
            <v-col
              class="d-sm-none"
              cols="12"
            >
              <v-card
                v-show="isShowFilter && false"
                class="filter-menu"
              >
                <v-row class="px-2">
                  <v-col
                    class="d-flex align-center justify-space-between"
                    cols="12"
                  >
                    <strong>Filters</strong>
                    <v-btn
                      icon
                      large
                      @click="isShowFilter = !isShowFilter"
                    >
                      <v-icon
                        color="black"
                        size="30px"
                      >
                        {{ mdiFormatListChecks }}
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-menu
                      :close-on-content-click="false"
                      min-width="280px"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          block
                          class="text-none"
                          color="#999999"
                          height="45"
                          outlined
                          v-on="on"
                        >
                          Location
                          <v-spacer />
                          <v-icon color="black">
                            {{ mdiChevronDown }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list
                        class="list"
                        dense
                      >
                        <v-list-item
                          v-for="(location, index) in locationForSearch"
                          :key="index"
                        >
                          <v-checkbox
                            v-model="locationsSelects"
                            :label="location"
                            :off-icon="mdiCheckboxBlankOutline"
                            :value="location"
                            class="ma-0 mb-n2"
                            color="blue"
                          />
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-chip
                      v-for="(location, index) in locationsSelects"
                      :key="index"
                      class="white--text ma-1 text-uppercase"
                      close
                      color="var(--color-green)"
                      @click:close="removeChip(index)"
                    >
                      {{ location }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-row>
      <div
        v-if="showNotFound"
        class="cards__not-found"
      >
        <img
          alt="Not found"
          class="cards__not-found--img"
          src="/img/not-found.svg"
        />
        No members exist with these parameters
      </div>
      <ul class="cards__row">
        <PlayerCard
          v-for="user in users"
          :key="user.id"
          :user="user"
        />
      </ul>
      <infinite-loading
        :distance="400"
        :identifier="infiniteId"
        spinner="bubbles"
        @infinite="infiniteHandler"
      >
        <template #spinner>
          <v-progress-circular
            color="#FC7800"
            indeterminate
            size="80"
            width="10"
          />
        </template>
        <span slot="no-more" />
        <span slot="no-results" />
      </infinite-loading>
    </v-container>
  </section>
</template>

<script>
import PlayerCard from '@/components/MembersPlayerCard.vue'
import { mdiCheckboxBlankOutline, mdiChevronDown, mdiFormatListChecks, mdiMagnify, mdiSort } from '@mdi/js'
import { userApi } from '@/enitites/user'

export default {
  components: {
    PlayerCard,
  },
  data() {
    return {
      mdiMagnify,
      mdiCheckboxBlankOutline,
      mdiFormatListChecks,
      mdiChevronDown,
      mdiSort,
      loading: false,
      searchParams: {
        pageNumber: 0,
        pageSize: 50,
        sortBy: '',
        keyword: '',
      },

      infiniteId: +new Date(),
      users: [],
      showNotFound: false,

      locationForSearch: [],
      sortAZ: true,
      isShowFilter: false,
      backgroundColor: '#343434',
      locationsSelects: [],
      searchTimeout: null,
      countCards: 0,
    }
  },
  computed: {
    searchKeyword() {
      return this.searchParams.keyword
    },
  },
  watch: {
    searchKeyword() {
      if (this.searchTimeout) clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.searchParams.pageNumber = 0
        this.users = []
        this.showNotFound = false
        this.infiniteId += 1
        this.searchTimeout = null
      }, 600)
    },
    sortAZ(value) {
      this.searchParams.sortBy = value ? 'name-asc' : 'name-desc'
      this.searchParams.pageNumber = 0
      this.users = []
      this.showNotFound = false
      this.infiniteId += 1
    },
  },
  async mounted() {
    this.countCards = await userApi.getUsersCount()
  },
  methods: {
    async infiniteHandler($state) {
      this.loading = true
      const data = await userApi.getPublicUsers(this.searchParams)
      this.loading = false
      this.searchParams.pageNumber += 1

      this.users = [...this.users, ...data]
      if (!this.users.length) this.showNotFound = true
      if (data.length < this.searchParams.pageSize) $state.complete()
      else $state.loaded()
    },
    focusSearch() {
      this.backgroundColor = '#fff'
    },
    blurSearch() {
      this.backgroundColor = '#343434'
    },
    sortCards() {
      if (this.loading) return
      this.sortAZ = !this.sortAZ
    },
    removeChip(index) {
      this.locationsSelects.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
#search-cards:focus {
  color: black;
}
</style>
<style lang="scss" scoped>
@import '@/assets/style/mixins';

.filter-bar {
  width: 100%;
  margin: 40px 0 0;

  &__row {
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
  }
}

.cards {
  background-color: var(--color-dark-900);

  .background {
    position: absolute;
    width: 100%;
    height: 1052px;
    background: #1b2432 url('/img/events.jpg') 50% 100% / cover no-repeat;
  }

  .wrapper {
    position: relative;
    box-sizing: border-box;
    padding: 142px 48px 0;
  }

  &__not-found {
    display: flex;
    flex-direction: column;
    max-width: 960px;
    margin: 0 auto;
    padding: 0 30px 60px;
    color: #999;
    font-size: 96px;
    line-height: 115px;
    text-align: center;

    @media (max-width: 495px) {
      font-size: 60px;
      line-height: 65px;
    }

    &--img {
      margin-bottom: 20px;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-between;
  }

  @media (max-width: $screen-sm) {
    &__row {
      justify-content: center;
    }
  }

  &__btn {
    color: #041e42;
    background-color: white !important;
    border: 2px solid #041e42;
    border-radius: 0;
    box-shadow: none;
  }

  &__card-photo,
  &__card-info {
    height: 100%;

    @media (max-width: 400px) {
      position: relative;
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
}
</style>
