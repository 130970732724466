<template>
  <BKLoader v-if="!event" />
  <section
    v-else
    class="event"
  >
    <!-- REMOVING -->

    <div class="event-container">
      <div class="details">
        <div class="background">
          <v-img
            :src="getEventImg(event.image, 800)"
            height="100%"
            width="100%"
          />
          <div class="gradient" />
        </div>
        <div
          :style="`background-image: url(${getEventImg(event.image, $vuetify.breakpoint.mdAndUp ? 1000 : 300)})`"
          class="image"
        >
          <!-- <div class="image-background" /> -->
        </div>

        <EventSidebar
          :event.sync="event"
          @reload:event="initEvent"
        />
      </div>
      <div class="information">
        <div class="desc">
          <h2
            v-if="event.battles && event.battles.length"
            class="information-title"
          >
            Battles
          </h2>
          <!-- Battles container -->
          <EventBattles :event="event" />

          <h2
            v-if="event.description"
            class="information-title"
          >
            Description
          </h2>
          <!--          eslint-disable-next-line-->
          <p
            class="information-description"
            v-html="$sanitize(event.description)"
          />
          <MediaPanel
            :can-edit="event.permissions.canEdit"
            :event-id="event.id"
            :org-id="event.orgId"
          />
        </div>
        <EventStaff :event="event" />
      </div>
    </div>
  </section>
</template>

<script>
import EventSidebar from '@/components/EventSidebar.vue'
import EventBattles from '@/components/EventBattles.vue'
import EventStaff from '@/components/EventStaff.vue'
import { mapActions, mapGetters } from 'vuex'
import { getDateRangeFormatted, getTimeRangeFormatted } from '@/services/date'
import Images from '@/mixins/image'
import api from '@/api'
import MediaPanel from '@/components/MediaPanel.vue'

export default {
  components: {
    MediaPanel,
    EventSidebar,
    EventBattles,
    EventStaff,
  },
  mixins: [Images],
  data() {
    return {
      event: null,
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),

    date() {
      return getDateRangeFormatted(this.event.dateStart, this.event.dateEnd)
    },
    time() {
      return getTimeRangeFormatted(this.event.dateStart, this.event.dateEnd)
    },
    eventId() {
      return this.$route.params.id
    },
  },
  watch: {
    eventId() {
      this.initEvent()
    },
  },
  async mounted() {
    this.initEvent()
  },
  methods: {
    ...mapActions(['setIsNotFound']),
    async initEvent() {
      this.event = null
      const event = await api.events.getEvent(this.eventId)
      if (!event) {
        this.setIsNotFound(!this.event)
        return
      }

      this.event = event
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.event {
  position: relative;
  padding: 80px 0 48px;
  background: var(--color-white);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 920px) {
    background: white !important;
  }

  .background {
    position: absolute;
    top: -40px;
    width: 120%;
    height: 120%;
    filter: blur(60px);
  }

  .gradient {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgb(0 0 0 / 90%), rgb(0 0 0 / 90%));
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
  }

  .alert {
    position: absolute;
    z-index: 1;
    width: 100%;
  }

  .event-container {
    width: 100%;
    height: 100%;
    min-height: 1000px;
    margin: 10px auto;
    background-color: var(--color-white);

    @media (max-width: 920px) {
      margin: 0 auto;
    }
  }

  .details {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 60px 0;
    overflow: hidden;

    @media (max-width: 920px) {
      flex-wrap: wrap;
      padding-bottom: 30px;
    }

    .image-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgb(0 0 0 / 62%) 0%, rgb(0 0 0 / 35%) 8%, rgb(255 255 255 / 0%) 20%);
    }

    .image {
      position: relative;
      width: 100%;
      max-width: 525px;
      height: 525px;
      background-position: center;
      background-size: cover;

      @media (max-width: 600px) {
        width: 270px;
        height: 270px;
        min-height: 100%;
      }
    }
  }

  .information {
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 780px;
    background: white;

    @media (max-width: 920px) {
      flex-wrap: wrap;
      width: 90%;
      margin: 0 auto;
    }

    .information-title {
      @include font-h4;

      margin-bottom: 8px;
    }
  }

  .desc {
    width: 100%;
    max-width: 525px;
    padding: 24px 0;

    @media (max-width: 920px) {
      max-width: unset;
    }
  }

  .battles,
  .information-title + p {
    margin-bottom: 20px;
  }
}
</style>
<style lang="scss">
.information-description {
  ul,
  li {
    list-style-type: inherit;
  }

  p {
    margin: 0 !important;
  }
}
</style>
