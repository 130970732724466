<template>
  <section class="bracket">
    <article
      v-for="(level, index) in bracket"
      :key="index"
      class="round"
    >
      <!--      eslint-disable vuejs-accessibility/click-events-have-key-events-->
      <article
        v-for="(sparring, i) in level"
        :key="i"
        :class="[`level-${index + 1}`, side, { clickable: sparring.red.id && sparring.blue.id }]"
        class="sparring"
        @click="toSparring(sparring.sparringId)"
      >
        <div
          :class="{
            winner: getSparringWinnerColor(sparring) === 'red',
            looser: getSparringWinnerColor(sparring) === 'blue',
            highlighted: participantToHighlightId && sparring.red.id === participantToHighlightId,
          }"
          class="participant p-red"
        >
          {{ sparring.red.name }}
        </div>
        <div
          :class="{
            winner: getSparringWinnerColor(sparring) === 'blue',
            looser: getSparringWinnerColor(sparring) === 'red',
            highlighted: participantToHighlightId && sparring.blue.id === participantToHighlightId,
          }"
          class="participant p-blue"
        >
          {{ sparring.blue.name }}
        </div>
      </article>
      <!--      eslint-enable vuejs-accessibility/click-events-have-key-events-->
    </article>
  </section>
</template>

<script>
export default {
  name: 'BKBracket',
  props: {
    side: {
      type: String,
      required: true,
    },
    bracket: {
      type: Array,
      required: true,
    },
    participantToHighlightId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  methods: {
    toSparring(sparringId) {
      this.$router.push({ name: 'Sparring', params: { id: sparringId } })
    },
    getSparringWinnerColor(sparring) {
      if (!sparring.winner || !sparring.red || !sparring.blue) return 'none'
      return sparring.red.id === sparring.winner ? 'red' : 'blue'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.bracket {
  display: flex;
  margin-bottom: 26px;
}

.round {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  row-gap: 16px;

  &:first-child .sparring::before {
    display: none;
  }

  &:last-child .sparring.right::after {
    top: unset;
    bottom: 50%;
    border-top-color: transparent;
    border-right-color: #ddd;
    border-bottom-color: #ddd;
    border-top-right-radius: 0;
    border-bottom-right-radius: 6px;
  }
}

.sparring {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  pointer-events: none;
  row-gap: 2px;

  &.clickable {
    pointer-events: unset;
  }

  &.clickable:hover .participant {
    border: 2px solid var(--color-orange-500);
  }

  // stylelint-disable no-descending-specificity
  // TODO: fix stylelint errors

  &::before {
    position: absolute;
    top: 50%;
    left: 2px;
    display: block;
    width: 10px;
    height: 0;
    margin-left: -2px;
    border-bottom: 2px solid var(--color-grey-600);
    content: '';
  }

  &:nth-child(odd)::after {
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 10px;
    border: 2px solid transparent;
    border-top-color: var(--color-grey-600);
    border-right-color: var(--color-grey-600);
    border-top-right-radius: 6px;
    content: '';
  }

  &:nth-child(even)::after {
    position: absolute;
    right: 0;
    bottom: 50%;
    display: block;
    width: 10px;
    border: 2px solid transparent;
    border-right-color: var(--color-grey-600);
    border-bottom-color: var(--color-grey-600);
    border-bottom-right-radius: 6px;
    content: '';
  }

  &.level-1 {
    .participant {
      margin-left: 0;
    }

    &::after {
      height: calc(95% + 1px);
    }
  }

  &.level-2::after {
    height: calc(95% * 2);
  }

  &.level-3::after {
    height: calc(95% * 4);
  }

  &.level-4::after {
    height: calc(95% * 8);
  }

  &.level-5::after {
    height: calc(95% * 16);
  }

  &.level-6::after {
    height: calc(95% * 32);
  }

  &.level-7::after {
    height: calc(95% * 64);
  }
}

.participant {
  @include font-body2;

  position: relative;
  display: flex;
  width: 160px;
  height: 32px;
  margin: 0 10px;
  padding: 6px 8px;
  background-color: var(--color-white);
  border: 2px solid var(--color-grey-600);
  border-radius: 3px;
  transition: 0.3s border;

  &.winner {
    color: var(--color-white);
    background-color: var(--color-green);
    border: 2px solid var(--color-green);
  }

  &.looser {
    color: var(--color-grey-500);
    background-color: var(--color-grey-600);
  }

  &.highlighted {
    border: 2px solid var(--color-orange-500);
  }
}

// stylelint-enable no-descending-specificity
</style>
