const useValidationRules = () => ({
  required: (value: unknown) => (typeof value !== 'boolean' ? !!value?.toString().trim() : value) || '',
  email: (value: string) => {
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !value || emailPattern.test(value.toString()) || 'Invalid e-mail'
  },
  birthday: (value: string) => {
    const pattern = /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/
    return pattern.test(value) || 'Date format mm/dd/yyyy'
  },
})

export default useValidationRules
