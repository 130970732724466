<script lang="ts" setup>
import { defineEmits, defineProps, getCurrentInstance, ref } from 'vue'
import { VueEditor } from 'vue2-editor'
import BKEditorConfig from '@/common/vue2Config'
import { Organization, OrganizationDocument } from '@/modules/Organizations/types'

const instance = getCurrentInstance()

const props = defineProps<{
  org: Organization
  isLoading: boolean
}>()

const emit = defineEmits<{
  (e: 'update'): void
}>()

const editorConfig = ref(BKEditorConfig)

const updateWaiver = () => {
  const plainText = props.org.waiver.text.replace(/<[^>]*>?/gm, '').trim()
  if (!plainText && !props.org.waiver.title && !props.org.waiver.subtitle && !props.org.waiver.version) {
    props.org.waiver = {} as OrganizationDocument
    emit('update')
    return
  }
  props.org.waiver.text = instance?.proxy?.$sanitize(props.org.waiver.text)
  emit('update')
}
</script>

<template>
  <div class="settings-waiver">
    <div class="fields">
      <div class="field">
        <v-text-field
          v-model.trim="org.waiver.title"
          hide-details
          label="Title"
          outlined
        />
      </div>

      <div class="field">
        <v-text-field
          v-model.trim="org.waiver.subtitle"
          hide-details
          label="Subtitle"
          outlined
        />
      </div>

      <div class="field">
        <v-text-field
          v-model.trim="org.waiver.version"
          hide-details
          label="Version"
          outlined
        />
      </div>

      <div class="field">
        <VueEditor
          v-model="org.waiver.text"
          :editor-toolbar="editorConfig"
          label="Text"
        />
      </div>
    </div>

    <div class="options">
      <v-btn
        :loading="isLoading"
        color="var(--color-blue-800)"
        dark
        height="52"
        width="180"
        @click="updateWaiver"
      >
        Save
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.settings-waiver {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.fields {
  padding: 20px;

  .field {
    margin-bottom: 16px;

    ::v-deep .ql-container {
      max-height: 205px;
      overflow: auto;
    }
  }
}

.options {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  padding: 20px;
  border-top: 1px solid var(--color-grey-600);
}
</style>
