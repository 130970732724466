<script lang="ts" setup>
import { computed } from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { mdiArrowLeft, mdiFacebook, mdiInstagram, mdiLinkVariant, mdiTwitter, mdiYoutube } from '@mdi/js'
import { UserDB } from '@/enitites/user'

const props = defineProps<{
  loading?: boolean
  user: UserDB
}>()
const emit = defineEmits<{
  (e: 'update:user', value: UserDB): void
  (e: 'save'): void
}>()

const getUser = computed({
  get: () => props.user,
  set: (value) => emit('update:user', value),
})
</script>

<template>
  <div class="d-flex flex-column justify-space-between h100">
    <div>
      <v-row
        class="social"
        justify="start"
        no-gutters
      >
        <v-col
          class="mb-4"
          cols="12"
        >
          <button
            class="back-button"
            type="button"
            @click="store.commit('TOGGLE_MENU_MOBILE')"
          >
            <v-icon v-show="$vuetify.breakpoint.xsOnly">
              {{ mdiArrowLeft }}
            </v-icon>
            Contact / Social
          </button>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="getUser.socials.email"
            autocomplete="off"
            background-color="#fff"
            dense
            label="Email"
            outlined
            required
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="getUser.socials.facebook"
            autocomplete="off"
            background-color="#fff"
            dense
            label="Facebook"
            outlined
            required
          >
            <template #prepend-inner>
              <span class="social__prefix"> https://www.facebook.com/ </span>
            </template>
            <template #append>
              <v-icon class="indigo--text text--darken-4">
                {{ mdiFacebook }}
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="getUser.socials.instagram"
            autocomplete="off"
            background-color="#fff"
            dense
            label="Instagram"
            outlined
            required
          >
            <template #prepend-inner>
              <span class="social__prefix"> https://www.instagram.com/ </span>
            </template>
            <template #append>
              <v-icon class="indigo--text text--darken-4">
                {{ mdiInstagram }}
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="getUser.socials.twitter"
            autocomplete="off"
            background-color="#fff"
            dense
            label="Twitter"
            outlined
            required
          >
            <template #prepend-inner>
              <span class="social__prefix"> https://twitter.com/ </span>
            </template>
            <template #append>
              <v-icon class="indigo--text text--darken-4">
                {{ mdiTwitter }}
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="getUser.socials.youtube"
            autocomplete="off"
            background-color="#fff"
            dense
            label="Youtube"
            outlined
            required
          >
            <template #prepend-inner>
              <span class="social__prefix"> https://www.youtube.com/channel/ </span>
            </template>
            <template #append>
              <v-icon class="indigo--text text--darken-4">
                {{ mdiYoutube }}
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="getUser.socials.website"
            autocomplete="off"
            background-color="#fff"
            dense
            label="Website"
            outlined
            required
          >
            <template #prepend-inner>
              <span class="social__prefix"> https:// </span>
            </template>
            <template #append>
              <v-icon class="indigo--text text--darken-4">
                {{ mdiLinkVariant }}
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-end">
      <v-btn
        :loading="loading"
        class="social__btn-save mt-8"
        color="var(--color-blue-800)"
        height="43"
        outlined
        tile
        @click="$emit('save')"
      >
        save
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.social {
  .back-button {
    @include font-h3;

    color: var(--color-dark-800);
    font-weight: var(--font-weight-medium);
  }

  &__btn-save {
    width: 180px;
    font-weight: var(--font-weight-bold);
    border: 2px solid;

    @media screen and (max-width: 600px) {
      width: 100%;
      margin: 8px 0;
    }
  }

  &__prefix {
    margin-top: 5px;
    margin-right: -4px;
  }
}
</style>
