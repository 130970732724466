<template>
  <div class="battle-org-info">
    <v-avatar size="40">
      <img
        :src="getOrgImage(chip.image, 80)"
        alt=""
      />
    </v-avatar>
  </div>
</template>

<script>
import Images from '@/mixins/image'

export default {
  name: 'BattleOrgInfo',
  mixins: [Images],
  props: {
    chip: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.battle-org-info {
  position: absolute;
  right: 15px;
  bottom: 15px;
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
