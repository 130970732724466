<template>
  <!--  eslint-disable vuejs-accessibility/click-events-have-key-events-->
  <li
    :class="{ active: isActive }"
    class="flip-container user"
    @click="isActive = !isActive"
  >
    <div class="flipper">
      <div class="card front">
        <img
          :class="{ default: !user.card.length }"
          :src="getCard(user.card, 405)"
          alt="instructor photo"
          class="photo"
        />
        <div class="overlay" />
        <div class="content">
          <div class="add-info">
            <PatreonLabel
              v-if="user.isPatron"
              transparent="60%"
            />
            <div class="country">
              <img
                :src="getFlag(user.country)"
                alt="instructor's country flag"
                class="flag"
              />
            </div>
          </div>
          <div class="details">
            <h3 class="name">
              {{ user.dancerName }}
            </h3>
            <div class="location">{{ user.city }}, {{ user.country }}</div>
            <ul class="stats">
              <li class="item events-attended">
                <div class="icon" />
                <div class="amount">
                  {{ user.eventsAttended }}
                </div>
              </li>
              <li class="item battles-entered">
                <div class="icon" />
                <div class="amount">
                  {{ user.battlesEntered }}
                </div>
              </li>
              <li class="item battles-won">
                <div class="icon" />
                <div class="amount">
                  {{ user.battlesWon }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-item back">
        <v-card class="card-info card-info">
          <div class="add-info-back">
            <PatreonLabel
              v-if="user.isPatron"
              is-only-icon
              style="padding: 7px; border-radius: 50%"
            />
          </div>
          <div class="avatar-row">
            <v-avatar
              class="avatar"
              size="100"
            >
              <v-img
                :src="getAvatar(user.avatar, 100)"
                alt="Person name"
              />
            </v-avatar>
            <v-avatar
              class="flag"
              size="30"
            >
              <v-img
                :src="getFlag(user.country)"
                alt="USA"
                class="flag-img"
              />
            </v-avatar>
          </div>
          <h4 class="name">
            {{ user.dancerName }}
          </h4>
          <span class="full-name-age">
            {{ user.fullName }}
            <span v-if="user.fullName && user.birthday">,</span> {{ getAge }}</span
          >
          <span class="person-from">
            {{ user.city }} {{ user.state }}
            <span v-if="(user.city || user.state) && user.country">,</span>
            {{ user.country }}</span
          >
          <div class="footer">
            <v-btn
              :to="{ name: 'Profile', params: { id: user.id } }"
              class="view-profile-btn"
              height="38"
              small
            >
              VIEW PROFILE
            </v-btn>
            <v-divider />
            <v-card-actions class="d-flex py-3 px-0">
              <p class="mb-1">
                <small>Follow</small>
              </p>
              <v-spacer />

              <a
                v-if="user.socials && user.socials.email"
                :href="'mailto:' + user.socials.email"
                class="social-link"
                target="_blank"
                title="Email"
              >
                <v-icon
                  class="social-link-icon"
                  color="black"
                  >{{ mdiEmailOutline }}
                </v-icon>
              </a>
              <a
                v-if="user.socials && user.socials.instagram"
                :href="'https://www.instagram.com/' + user.socials.instagram"
                class="social-link"
                target="_blank"
                title="Instagram"
              >
                <v-icon
                  class="social-link-icon"
                  color="black"
                  >{{ mdiInstagram }}
                </v-icon>
              </a>
              <a
                v-if="user.socials && user.socials.facebook"
                :href="'https://www.facebook.com/' + user.socials.facebook"
                class="social-link"
                target="_blank"
                title="Facebook"
              >
                <v-icon
                  class="social-link-icon"
                  color="black"
                  >{{ mdiFacebook }}
                </v-icon>
              </a>
              <a
                v-if="user.socials && user.socials.twitter"
                :href="'https://twitter.com/' + user.socials.twitter"
                class="social-link"
                target="_blank"
                title="Twitter"
              >
                <v-icon
                  class="social-link-icon"
                  color="black"
                  >{{ mdiTwitter }}
                </v-icon>
              </a>
              <a
                v-if="user.socials && user.socials.youtube"
                :href="'https://www.youtube.com/channel/' + user.socials.youtube"
                target="_blank"
                title="youtube"
              >
                <v-icon
                  class="social-link-icon"
                  color="black"
                  >{{ mdiYoutube }}
                </v-icon>
              </a>
              <a
                v-if="user.socials && user.socials.website"
                :href="'https://' + user.socials.website"
                target="_blank"
                title="website"
              >
                <v-icon
                  class="social-link-icon"
                  color="black"
                  >{{ mdiLinkVariant }}
                </v-icon>
              </a>
            </v-card-actions>
          </div>
        </v-card>
      </div>
    </div>
  </li>
  <!--  eslint-enable vuejs-accessibility/click-events-have-key-events-->
</template>

<script>
import Images from '@/mixins/image'
import { mdiEmailOutline, mdiFacebook, mdiInstagram, mdiLinkVariant, mdiTwitter, mdiYoutube } from '@mdi/js'
import { PatreonLabel } from '@/shared/ui/labels'

export default {
  components: { PatreonLabel },
  mixins: [Images],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mdiFacebook,
      mdiTwitter,
      mdiInstagram,
      mdiEmailOutline,
      mdiLinkVariant,
      mdiYoutube,
      isActive: false,
    }
  },
  computed: {
    getAge() {
      if (!this.user.birthday) return
      const birthday = new Date(this.user.birthday)
      const ageMs = Date.now() - birthday.getTime()
      const ageDate = new Date(ageMs)
      // eslint-disable-next-line consistent-return
      return Math.abs(ageDate.getUTCFullYear() - 1970)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

// --- rotate animation ---
.flip-container {
  perspective: 1000px;
}

@media (max-width: 768px) {
  .flip-container.active .flipper {
    transform: rotateY(180deg);
  }
}

@media (min-width: 768px) {
  .flip-container:hover .flipper {
    transform: rotateY(180deg);
  }
}

.flipper {
  position: relative;
  height: 100%;
  transform-style: preserve-3d;
  transition: 0.6s;
}

.front,
.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
}

.front {
  z-index: 2;
  background-color: var(--color-grey-500);
  transform: rotateX(0deg);
}

.back {
  transform: rotateY(180deg);
}

.add-info-back {
  display: flex;
  justify-content: flex-end;
}

// --- rotate animatin end ---

.card {
  position: relative;
  width: 305px;
  height: 363px;
  border-radius: 6px;

  @media (max-width: $screen-md) {
    width: 340px;
    height: 405px;
  }

  $top-indent: 18.47%;

  .overlay {
    position: absolute;
    top: $top-indent;
    width: 100%;
    height: calc(100% - #{$top-indent});
    background: linear-gradient(180deg, rgb(0 0 0 / 0.01%) 0%, rgb(0 0 0 / 90%) 100%);
    border-radius: 6px;
  }

  .photo {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;

    &.default {
      top: 12px;
      left: calc(50% - 98px);
      width: 196px;
      height: 196px;
      object-position: top;
    }
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
    padding: 12px;

    .add-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .country {
      box-sizing: content-box;
      width: 20px;
      height: 20px;
      margin-left: auto;
      border: 2px solid var(--color-white);
      border-radius: 50%;

      .flag {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }

    .details {
      .name {
        @include font-h3;

        color: var(--color-white);
        text-align: center;
      }

      .location {
        margin-top: 4px;
        color: var(--color-white);
        text-align: center;
      }

      .stats {
        display: flex;
        justify-content: space-between;
        margin: 12px 0 20px;
        padding: 0 44px;

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 46px;

          .icon {
            width: 24px;
            height: 24px;
            background: white;
          }

          .amount {
            margin-top: 4px;
            color: var(--color-grey-600);
            text-align: center;
          }

          &.events-attended {
            .icon {
              mask: url('/img/ic_Calendar.svg') no-repeat center;
            }
          }

          &.battles-entered {
            .icon {
              mask: url('/img/ic_Compete.svg') no-repeat center;
            }
          }

          &.battles-won {
            .icon {
              mask: url('/img/ic_Trophy.svg') no-repeat center;
            }
          }
        }
      }
    }
  }
}

.card-item {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.view-profile-btn {
  width: 100%;
  margin-bottom: 16px;
  color: #041e42;
  background-color: white !important;
  border: 2px solid #041e42;
  border-radius: 0;
  box-shadow: none;
}

.card-photo,
.card-info {
  height: 100%;

  @media (max-width: 400px) {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

.card-info {
  box-sizing: border-box;
  padding: 28px 20px 20px;
  text-align: center;

  .avatar-row {
    position: relative;
    display: inline-block;
  }

  .avatar {
    max-width: 184px;

    @media (max-width: 400px) {
      max-width: 96px;
    }
  }

  // stylelint-disable no-descending-specificity
  // TODO: fix stylelint errors

  .flag {
    position: absolute;
    top: 0;
    right: -10px;

    @media (max-width: 400px) {
      top: -5px;
      right: 2px;
    }

    .flag-img {
      border: 2px solid var(--color-white);
    }
  }

  .name {
    @include font-h4;

    margin: 8px 0 0;
  }

  // stylelint-enable no-descending-specificity

  .full-name-age,
  .person-from {
    @include font-body2;

    display: inline-block;
    width: 100%;
  }

  .social-link {
    margin: -4px 5px 0 0;
    text-decoration: none;
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 20px;
  }
}
</style>
