<script lang="ts" setup>
import { BKModal } from '@/shared/ui/modals'
import { ref } from 'vue'
import SearchEvents from '@/components/Events/SearchEvents.vue'
import { mdiMagnify } from '@mdi/js'

const isShow = ref(false)
defineEmits<{ (e: 'changed-tab', tab: string): void }>()
</script>

<template>
  <div
    aria-label="Dialog container"
    class="search-events-dialog"
  >
    <BKButton
      aria-label="Open dialog"
      color="var(--color-black)"
      height="24"
      icon
      width="24"
      @click="isShow = true"
    >
      <v-icon>
        {{ mdiMagnify }}
      </v-icon>
    </BKButton>
    <BKModal
      :is-show.sync="isShow"
      aria-label="Dialog"
    >
      <template #default>
        <div class="dialog-content">
          <SearchEvents
            is-need-search
            is-show-amount
            @changed-tab="$emit('changed-tab', $event)"
          />
        </div>
      </template>
    </BKModal>
  </div>
</template>
<style lang="scss" scoped>
.dialog-content {
  display: grid;
  gap: 40px;
  padding-top: 16px;
}
</style>
