<script lang="ts" setup>
import { OrganizationUser } from '@/modules/Organizations/types'
import { useImage } from '@/shared/composables/image'

defineProps<{
  user: OrganizationUser
}>()
const { getAvatar } = useImage()
</script>

<template>
  <div class="user">
    <v-img
      :src="getAvatar(user.avatar, 200)"
      class="avatar"
    />

    <div class="name">
      {{ user.dancerName }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.user {
  width: 155px;
  height: 152px;
  padding: 10px 0;
  border-radius: var(--border-radius-rounded);
  transition-duration: 0.3s;

  &:hover {
    // TODO: To globals
    box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  }

  .avatar {
    width: 100px;
    height: 100px;
    margin: 0 auto 8px;
    border-radius: 104px;
  }

  .name {
    display: block;
    font-weight: var(--font-weight-bold);
    text-align: center;
  }
}
</style>
