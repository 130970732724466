<script lang="ts" setup>
import { computed, ref } from 'vue'
import api from '@/api'
import { mdiClose, mdiCrownOutline } from '@mdi/js'
import { IBattle } from '@/types/battle'
import { IBracketParticipant, IBrackets } from '@/components/Battle/Brackets/types'

interface IProps {
  brackets: IBrackets
  battle: IBattle
}

const props = defineProps<IProps>()

const emit = defineEmits<{
  (e: 'update:brackets', brackets: IBrackets): void
  (e: 'cancel'): void
}>()
const brackets = ref(JSON.parse(JSON.stringify(props.brackets)))
const isShowError = ref(false)
const loading = ref(false)

const currentLeftBracket = computed(() => {
  let index = brackets.value.left.findIndex((round) => round.some((match) => match.winner === 0))
  index = index === -1 ? brackets.value.left.length - 1 : index
  return { participants: brackets.value.left[index], round: index }
})

const currentRightBracket = computed(() => {
  let index = brackets.value.right.findIndex((round) => round.some((match) => match.winner === 0))
  index = index === -1 ? brackets.value.left.length - 1 : index
  return { participants: brackets.value.right[index], round: index }
})

const isFinalRound = computed(
  () => !!(brackets.value.final.red.id && brackets.value.final.blue.id) || props.battle.bracketSize === 2
)
const currentBrackets = computed(() => {
  const isSameRound = currentLeftBracket.value.round === currentRightBracket.value.round
  if (isFinalRound.value) {
    return {
      left: [],
      right: [],
      final: brackets.value.final,
    }
  }
  return {
    left: isSameRound
      ? currentLeftBracket.value.participants
      : brackets.value.left[Math.min(currentLeftBracket.value.round, currentRightBracket.value.round)],
    right: isSameRound
      ? currentRightBracket.value.participants
      : brackets.value.right[Math.min(currentLeftBracket.value.round, currentRightBracket.value.round)],
    final: [],
  }
})

const participants = computed(() => {
  const participantsList: IBracketParticipant[] = [{ name: '', id: 0, isDisabled: false }]

  const source = props.battle.crewSize > 1 ? props.battle.crews : props.battle.participants
  const sparrings = [...(currentBrackets.value.left || []), ...(currentBrackets.value.right || [])]

  const additionalParticipants = source.map((item) => {
    const isInSparringWithWinner = sparrings.some(
      (sparring) => (sparring.red.id === item.id || sparring.blue.id === item.id) && !!sparring.winner
    )

    if ('name' in item) {
      // item is ICrew
      return {
        id: item.id,
        name: item.name,
        isDisabled: !!item.place || isInSparringWithWinner,
      }
    }
    // item is IBracketParticipant
    return {
      id: item.id,
      name: item.dancerName,
      isDisabled: !!item.place || isInSparringWithWinner,
    }
  })

  participantsList.push(...additionalParticipants)

  return participantsList
})

const duplicatedParticipant = ref<number>()

const checkUniqueSparrings = (sparringList: { sparringId: number; red: number; blue: number }[]) => {
  const seenIds = new Set<number>()

  return sparringList.every(({ red, blue }) => {
    const isDuplicatedInSparring = red && blue && red === blue
    if (isDuplicatedInSparring) {
      duplicatedParticipant.value = red
      return false
    }

    // Check if red participant is duplicated in different sparrings
    const isRedDuplicated = red && seenIds.has(red)
    if (isRedDuplicated) {
      duplicatedParticipant.value = red
      return false
    }

    //  Check if blue participant is duplicated in different sparrings
    const isBlueDuplicated = blue && seenIds.has(blue)
    if (isBlueDuplicated) {
      duplicatedParticipant.value = blue
      return false
    }

    if (red) seenIds.add(red)
    if (blue) seenIds.add(blue)
    return true
  })
}

const updateSeed = async () => {
  loading.value = true
  const sparrings = [...currentBrackets.value.left, ...currentBrackets.value.right, currentBrackets.value.final]
    .filter((sparring) => sparring.winner === 0)
    .map((sparring) => {
      return {
        sparringId: sparring.sparringId,
        red: sparring.red.id,
        blue: sparring.blue.id,
      }
    })

  const isUniqueObjects = checkUniqueSparrings(sparrings)
  if (!isUniqueObjects) {
    isShowError.value = true
    loading.value = false
    return
  }

  isShowError.value = false

  const updatedBrackets = await api.battles.seedParticipants(props.battle.id, sparrings)
  if (!updatedBrackets) {
    loading.value = false
    return
  }

  emit('update:brackets', updatedBrackets)
  emit('cancel')
  loading.value = false
}
</script>

<template>
  <section>
    <div
      v-if="isShowError"
      class="bracket-error"
    >
      <span>The same {{ battle.crewSize > 1 ? 'crew' : 'participant' }} is in two spots</span>
      <button
        aria-label="Close error popup"
        type="button"
        @click="
          () => {
            isShowError = false
            duplicatedParticipant = undefined
          }
        "
      >
        <v-icon color="var(--color-white)">
          {{ mdiClose }}
        </v-icon>
      </button>
    </div>
    <section
      v-if="isFinalRound"
      class="final-round-editor"
    >
      <h4>Final</h4>
      <div class="autocompletes">
        <v-autocomplete
          :disabled="loading || !!currentBrackets.final.winner"
          :error="duplicatedParticipant === currentBrackets.final.red.id"
          :items="participants"
          :value="currentBrackets.final.red"
          background-color="white"
          class="final-participant left"
          hide-details
          item-disabled="isDisabled"
          item-text="name"
          item-value="id"
          outlined
          return-object
          @change="currentBrackets.final.red = $event"
        />
        <div class="crown">
          <v-icon>{{ mdiCrownOutline }}</v-icon>
        </div>
        <v-autocomplete
          :disabled="loading || !!currentBrackets.final.winner"
          :error="duplicatedParticipant === currentBrackets.final.blue.id"
          :items="participants"
          :value="currentBrackets.final.blue"
          background-color="white"
          class="final-participant right"
          hide-details
          item-disabled="isDisabled"
          item-text="name"
          item-value="id"
          outlined
          return-object
          @change="currentBrackets.final.blue = $event"
        />
      </div>
    </section>
    <section
      v-else
      class="editor"
    >
      <article class="bracket">
        <h6 class="section-title">Left Bracket</h6>

        <section
          v-for="sparring in currentBrackets.left"
          :key="sparring.sparringId"
          class="sparring"
        >
          <v-autocomplete
            :disabled="loading || !!sparring.winner"
            :error="duplicatedParticipant === sparring.red.id"
            :items="participants"
            :value="sparring.red"
            background-color="white"
            class="participant left"
            hide-details
            item-disabled="isDisabled"
            item-text="name"
            item-value="id"
            outlined
            return-object
            @change="sparring.red = $event"
          />
          <v-autocomplete
            :disabled="loading || !!sparring.winner"
            :error="duplicatedParticipant === sparring.blue.id"
            :items="participants"
            :value="sparring.blue"
            background-color="white"
            class="participant left"
            hide-details
            item-disabled="isDisabled"
            item-text="name"
            item-value="id"
            outlined
            return-object
            @change="sparring.blue = $event"
          />
        </section>
      </article>

      <div class="crown">
        <v-icon>{{ mdiCrownOutline }}</v-icon>
      </div>

      <article class="bracket">
        <h5 class="section-title">Right Bracket</h5>
        <section
          v-for="sparring in currentBrackets.right"
          :key="sparring.sparringId"
          class="sparring"
        >
          <v-autocomplete
            :disabled="loading || !!sparring.winner"
            :error="duplicatedParticipant === sparring.red.id"
            :items="participants"
            :value="sparring.red"
            background-color="white"
            class="participant right"
            hide-details
            item-disabled="isDisabled"
            item-text="name"
            item-value="id"
            outlined
            return-object
            @change="sparring.red = $event"
          />
          <v-autocomplete
            :disabled="loading || !!sparring.winner"
            :error="duplicatedParticipant === sparring.blue.id"
            :items="participants"
            :value="sparring.blue"
            background-color="white"
            class="participant right"
            hide-details
            item-disabled="isDisabled"
            item-text="name"
            item-value="id"
            outlined
            return-object
            @change="sparring.blue = $event"
          />
        </section>
      </article>
    </section>
    <section class="actions">
      <BKButton
        :loading="loading"
        white-text
        @click="updateSeed"
      >
        Submit bracket
      </BKButton>

      <BKButton
        outlined
        @click="$emit('cancel')"
      >
        Cancel
      </BKButton>
    </section>
  </section>
</template>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.editor {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: $screen-sm) {
    flex-direction: column;
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  column-gap: 8px;
}

.bracket {
  width: 100%;
  max-width: 325px;

  @media (max-width: $screen-sm) {
    max-width: unset;
  }
}

.crown {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 20px auto;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-600);
  border-radius: 4px;

  & .v-icon {
    color: var(--color-gold-800);
  }
}

.section-title {
  @include font-h5;

  margin-bottom: 8px;
}

.sparring {
  display: grid;
  row-gap: 8px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.participant {
  &.left {
    &:nth-child(odd)::after {
      position: absolute;
      top: 50%;
      right: -10px;
      display: block;
      width: 10px;
      height: 60%;
      border: 2px solid transparent;
      border-top-color: var(--color-grey-600);
      border-right-color: var(--color-grey-600);
      border-top-right-radius: 6px;
      content: '';
    }

    &:nth-child(even)::after {
      position: absolute;
      right: -10px;
      bottom: 50%;
      display: block;
      width: 10px;
      height: 60%;
      border: 2px solid transparent;
      border-right-color: var(--color-grey-600);
      border-bottom-color: var(--color-grey-600);
      border-bottom-right-radius: 6px;
      content: '';
    }
  }

  &.right {
    &:nth-child(odd)::after {
      position: absolute;
      top: 50%;
      right: -10px;
      display: block;
      width: 10px;
      height: 60%;
      border: 2px solid transparent;
      border-top-color: var(--color-grey-600);
      border-right-color: var(--color-grey-600);
      border-top-right-radius: 6px;
      content: '';
    }

    &:nth-child(even)::after {
      position: absolute;
      right: -10px;
      bottom: 50%;
      display: block;
      width: 10px;
      height: 60%;
      border: 2px solid transparent;
      border-right-color: var(--color-grey-600);
      border-bottom-color: var(--color-grey-600);
      border-bottom-right-radius: 6px;
      content: '';
    }

    @media (min-width: $screen-sm) {
      &:nth-child(odd)::after {
        position: absolute;
        top: 50%;
        left: -10px;
        display: block;
        width: 10px;
        height: 60%;
        border: 2px solid transparent;
        border-top-color: var(--color-grey-600);
        border-left-color: var(--color-grey-600);
        border-top-left-radius: 6px;
        content: '';
      }

      &:nth-child(even)::after {
        position: absolute;
        bottom: 50%;
        left: -10px;
        display: block;
        width: 10px;
        height: 60%;
        border: 2px solid transparent;
        border-bottom-color: var(--color-grey-600);
        border-left-color: var(--color-grey-600);
        border-bottom-left-radius: 6px;
        content: '';
      }
    }
  }
}

.bracket-error {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 14px 12px;
  color: var(--color-white);
  background-color: #ff2d53;
  border-radius: 6px;
}

.final-round-editor {
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }

  .autocompletes {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 194px 1fr;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .final-participant {
      grid-column: 1 / 2;
    }

    .left {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }

    .right {
      grid-row: 2 / 3;
      grid-column: 3 / 4;
    }

    .crown {
      grid-column: 2 / 3;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}
</style>
