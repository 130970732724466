<template>
  <div class="search">
    <BKInput
      v-model="key"
      :append-icon="mdiMagnify"
      :disabled="disabled"
      autocomplete="off"
      background-color="var(--color-white"
      hide-details
      label="Search dancers"
      outlined
      single-line
      @blur="handlerBlur"
      @focus="search"
      @keyup.prevent="search($event)"
    />
    <v-card class="search-list">
      <v-list-item
        v-for="user in users"
        :key="user.id"
        :disabled="exclude.includes(user.id)"
        :inactive="exclude.includes(user.id)"
        class="search-list-item"
        @click="pickUser(user)"
      >
        <v-list-item-avatar>
          <img
            :src="getAvatar(user.avatar, 100)"
            alt="user.dancerName"
          />
        </v-list-item-avatar>
        <v-list-item-content> {{ user.dancerName }}</v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import api from '@/api'
import Images from '@/mixins/image'
import BKInput from '@/components/AppShared/BKInput.vue'
import { mdiMagnify } from '@mdi/js'

export default {
  name: 'BattleUserSelect',
  components: {
    BKInput,
  },
  mixins: [Images],
  props: {
    battleId: {
      type: Number,
      required: true,
    },
    exclude: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiMagnify,
      key: '',
      loading: false,
      users: [],
      isFirstLoading: false,
    }
  },
  methods: {
    handlerBlur() {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.users = []), 300)
    },
    pickUser(user) {
      this.$emit('selectUser', user)
      this.users = []
      this.key = ''
    },
    async search(key = '') {
      if (key.keyCode >= 37 && key.keyCode <= 40) return

      const searchParams = {
        pageNumber: 0,
        pageSize: 50,
        keyword: this.key,
        battleId: this.battleId,
      }
      const users = await api.admin.getAvailableDancers(searchParams)

      this.$nextTick(() => {
        this.users = users
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.search {
  position: relative;
}

.search-list {
  position: absolute;
  z-index: 10;
  width: 100%;
  max-height: 450px;
  overflow-y: scroll;
}

.search-list-item {
  padding: 0 20px;
  cursor: pointer;
}
</style>
