import { ImageActions } from '@/services/image'
import flags from '../../public/flags/data.json'

const Images = {
  methods: {
    getAvatar(id, size) {
      return ImageActions.getImageById(id, size, size, 'avatar')
    },
    getEventImg(id, size) {
      return ImageActions.getImageById(id, size, size, 'event')
    },
    getCard(id, size) {
      return ImageActions.getImageById(id, size, size, 'card')
    },
    getOrgImage(id, size) {
      return ImageActions.getImageById(id, size, size, 'org')
    },
    getFlag(countryName) {
      const countryFlag = flags.find((flag) => flag.name === countryName)
      if (!countryFlag) return '/img/flag-earth.png'
      return countryFlag.flag
    },
  },
}

export default Images
