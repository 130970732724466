<template>
  <!--  eslint-disable  vuejs-accessibility/click-events-have-key-events-->
  <div
    :class="[{ winner: active }, { expandable }]"
    class="scoring"
    @click="isExpanded = !isExpanded"
  >
    <div
      v-if="isExpanded"
      class="judges"
    >
      <article
        v-for="judgment in judgments"
        :key="judgment.id"
        class="judge"
      >
        <v-avatar
          class="judge-avatar"
          size="24"
        >
          <v-img :src="getAvatar(judgment.avatar, 48)" />
        </v-avatar>

        <span class="judge-points">{{ Math.floor(judgment[`${color}Points`]) }} pts</span>

        <v-icon
          v-if="judgment[`${color}Points`] % 1 !== 0"
          class="judge-star"
        >
          {{ mdiStarOutline }}
        </v-icon>
      </article>
    </div>
    <span
      v-else
      class="score-number"
    >
      {{ points }}
    </span>
  </div>
  <!--  eslint-enable vuejs-accessibility/click-events-have-key-events-->
</template>

<script>
import Images from '@/mixins/image'
import { mdiStarOutline } from '@mdi/js'

export default {
  name: 'SparringRoundParticipantScore',
  mixins: [Images],
  props: {
    judgments: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    points: {
      type: Number,
      required: false,
      default: 0,
    },
    expandable: {
      type: Boolean,
      required: false,
      default: false,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      mdiStarOutline,
      isExpanded: false,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.judges {
  display: grid;
  row-gap: 10px;
  padding: 10px 0;

  .judge {
    display: flex;
    grid-gap: 13px;
    font-weight: var(--font-weight-normal);

    .judge-avatar {
      border: 1px solid var(--color-white);
      border-radius: 100%;
    }
  }
}

.scoring {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  min-height: 92px;
  font-weight: var(--font-weight-bold);
  background-color: var(--color-grey-700);
  border-radius: 6px;

  &.expandable {
    cursor: pointer;
  }

  &.expanded {
    padding: 10px 8px;
  }

  &.winner {
    background-color: var(--color-green);

    .judges .judge,
    .judges .judge .judge-star,
    .score-number {
      color: var(--color-white);
    }
  }
}
</style>
