<template>
  <div class="information">
    <v-img
      :src="srcImgEvent"
      class="information__image d-flex align-center"
    >
      <v-btn
        v-if="!srcImgEvent"
        class="information__btn-upload"
        color="var(--color-white)"
        outlined
        tile
        @click="openFileDialog"
      >
        Upload image
        <v-icon class="ml-4">
          {{ mdiUpload }}
        </v-icon>
      </v-btn>
      <v-btn
        v-else
        class="information__btn-edit white--text"
        color="var(--color-blue-800)"
        tile
        @click="openFileDialog"
      >
        Edit Image
        <v-icon class="ml-4">
          {{ mdiLeadPencil }}
        </v-icon>
      </v-btn>
      <!--      eslint-disable vuejs-accessibility/form-control-has-label-->
      <input
        ref="inputFile"
        accept="image/png, image/jpeg"
        name="eventImage"
        style="display: none"
        type="file"
        @change="handlerFileChange"
      />
      <!--      eslint-enable vuejs-accessibility/form-control-has-label-->
    </v-img>
    <p class="information__title">General information *</p>

    <BKOrganizationSelect
      :orgs="getUserDB.organizations"
      :selected-org.sync="eventData.orgId"
      class="mb-3"
    />
    <v-text-field
      v-model="eventData.title"
      autocomplete="off"
      background-color="#fff"
      class="information__field"
      hide-details="auto"
      label="Event name"
      maxlength="150"
      outlined
    />
    <GmapsPlaceInput
      v-model="location"
      class="information__location"
      placeholder="Location"
    />
    <v-text-field
      v-model="eventData.website"
      :rules="[validateUrlRule]"
      autocomplete="off"
      background-color="#fff"
      class="information__field"
      hide-details="auto"
      label="Website (optional)"
      outlined
    />
    <VueEditor
      v-model="eventData.description"
      :editor-toolbar="editorConfig"
      placeholder="Give a short description of the event."
    />
    <p class="information__title">Add Admins</p>
    <!--      eslint-disable vuejs-accessibility/aria-role-->
    <BKUserSelect
      :exclude="adminIds"
      :non-removable="[eventData.userId]"
      :select-user-chip.sync="eventData.staff.admins"
      label-text="Find and add Admins"
      role="admin"
      @removeUser="removeEventRole"
      @selectUser="addEventRole"
    />

    <!--      eslint-enable vuejs-accessibility/aria-role-->
    <p class="information__title">Add Host</p>
    <!--      eslint-disable vuejs-accessibility/aria-role-->
    <BKUserSelect
      :exclude="hostIds"
      :select-user-chip.sync="eventData.staff.hosts"
      label-text="Find and add Host"
      role="host"
      @removeUser="removeEventRole"
      @selectUser="addEventRole"
    />
    <!--      eslint-enable vuejs-accessibility/aria-role-->
    <p class="information__title">Add DJs</p>
    <p class="information__title information__title--description">
      You can also add DJs to individual battles when you create the battles
    </p>
    <!--      eslint-disable vuejs-accessibility/aria-role-->
    <BKUserSelect
      :exclude="djIds"
      :select-user-chip.sync="eventData.staff.djs"
      label-text="Find and add Djs"
      role="dj"
      @removeUser="removeEventRole"
      @selectUser="addEventRole"
    />
    <!--      eslint-enable vuejs-accessibility/aria-role-->
    <div class="information__navigation navigation">
      <div class="navigation__wrap-dots mt-6">
        <span
          v-for="i in 5"
          v-show="isNew"
          :key="i"
          :class="i === currentDotNav ? 'navigation__dot--active' : ''"
          class="navigation__dot"
        />
      </div>
      <div
        v-show="error"
        class="navigation__text-error error--text text-center"
      >
        {{ error }}
      </div>
      <div class="navigation__wrap-btn mb-10">
        <div class="d-flex flex-column flex-sm-row-reverse">
          <v-btn
            :disabled="!isInfoFilledOut"
            :loading="loadingNextBtn"
            class="navigation__btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="handlerClickNext"
          >
            {{ backStep ? 'save' : 'next' }}
          </v-btn>
          <v-btn
            :disabled="!backStep"
            class="navigation__btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="handlerClickCancelEdit"
          >
            {{ backStep ? 'cancel' : 'back' }}
          </v-btn>
        </div>
        <v-btn
          v-if="!backStep"
          block
          class="navigation__btn-cancel"
          color="var(--color-blue-800)"
          height="48"
          max-width="194"
          text
          @click="toggleCancelEventCreation"
        >
          cancel
        </v-btn>
        <CreateEventCancel
          :event="eventData"
          @saveAsDraft="saveAsDraft"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'

import { mapActions, mapGetters } from 'vuex'
import { VueEditor } from 'vue2-editor'

import BKUserSelect from '@/components/BKUserSelect.vue'
import BKOrganizationSelect from '@/components/BKOrganizationSelect.vue'
import GmapsPlaceInput from '@/components/GmapsPlaceInput.vue'
import CreateEventCancel from '@/components/CreateEventCancel.vue'

import { ImageActions } from '@/services/image'
import Images from '@/mixins/image'

import { mdiLeadPencil, mdiUpload } from '@mdi/js'
import BKEditorConfig from '../common/vue2Config'

export default {
  components: {
    BKUserSelect,
    BKOrganizationSelect,
    CreateEventCancel,
    GmapsPlaceInput,
    VueEditor,
  },
  mixins: [Images],
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    eventData: {
      type: Object,
      default: () => ({}),
    },
    backStep: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiUpload,
      mdiLeadPencil,
      validateUrlRule: (value) => this.isValidUrl(value) || 'URL should be in the format https://yourwebsite.com',
      isShowImg: this.backStep,
      location: {},
      filesImgEvent: null,
      srcImgEvent: '',
      loadingNextBtn: false,
      currentDotNav: 1,
      error: null,
      isCancel: false,
      editorConfig: BKEditorConfig,
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    isInfoFilledOut() {
      return !!this.eventData.title
    },
    adminIds() {
      return this.eventData.staff.admins.map((user) => user.id)
    },
    djIds() {
      return this.eventData.staff.djs.map((user) => user.id)
    },
    hostIds() {
      return this.eventData.staff.hosts.map((user) => user.id)
    },
  },
  async mounted() {
    if (this.eventData.image) this.srcImgEvent = this.getEventImg(this.eventData.image, 800)

    this.location = {
      address: this.eventData.locationAddress,
      lat: this.eventData.locationLat,
      lng: this.eventData.locationLng,
    }

    // this.userOrgs = await api.orgs.getOrgs({ pageNumber: 0, pageSize: 100, userId: this.getUserDB.id })
  },
  methods: {
    ...mapActions(['toggleCancelEventCreation']),
    isValidUrl(str) {
      try {
        const url = new URL(str)
        return url.protocol === 'https:'
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        return false
      }
    },
    async saveAsDraft() {
      if (this.filesImgEvent) await this.setImageToEvent()
      this.setLocationToEvent()

      this.$emit('saveAsDraft')
      this.loadingNextBtn = false
    },
    async handlerClickNext() {
      this.error = null
      this.loadingNextBtn = true

      if (this.filesImgEvent) await this.setImageToEvent()
      this.setLocationToEvent()

      this.$emit('updateEvent')
      this.loadingNextBtn = false
      this.$parent.isClickBtnNext = true
      this.$emit('update:screen', this.backStep ? 'CreateEventTotal' : 'CreateEventDate')
    },
    setLocationToEvent() {
      this.eventData.locationAddress = this.location.address
      this.eventData.locationLat = this.location.lat
      this.eventData.locationLng = this.location.lng
    },
    async setImageToEvent() {
      const image = await ImageActions.saveImage(this.filesImgEvent[0])
      this.eventData.image = image
      this.isShowImg = true
    },
    handlerClickCancelEdit() {
      this.$emit('update:screen', 'CreateEventTotal')
    },
    openFileDialog() {
      this.$refs.inputFile.click()
    },
    handlerFileChange(evt) {
      const filesImg = evt.target.files || evt.dataTransfer.files // file list with info
      if (!filesImg.length > 0) return

      const reader = new FileReader()
      reader.onload = (event) => {
        this.srcImgEvent = event.target.result // picture date
        this.filesImgEvent = filesImg
      }
      reader.readAsDataURL(filesImg[0])
    },
    async addEventRole(user, role) {
      const resp = await api.events.addRole(this.eventData.id, user.id, role)
      if (resp) this.eventData.staff = resp
    },
    async removeEventRole(user, role) {
      const resp = await api.events.removeRole(this.eventData.id, user.id, role)
      if (resp) this.eventData.staff = resp
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';
@import '@/assets/style/navigationEventCreate';

.information {
  max-width: 400px;
  margin: 0 auto;

  &__image {
    position: relative;
    width: 100%;
    height: 400px;
    background: #6cace4;

    @media (max-width: $screen-sm) {
      height: 320px;
    }
  }

  &__btn-upload {
    margin-left: 25%;
  }

  &__btn-edit {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-weight: var(--font-weight-bold);
  }

  &__title {
    margin: 12px 0;
    color: var(--color-dark-800);

    &--description {
      color: #999;
    }
  }

  &__field {
    width: 100%;
    margin-bottom: 10px !important;
  }

  &__location {
    width: 100%;
  }
}
</style>
