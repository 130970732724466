export const getDateFormatted = (date) => date.toLocaleDateString('en-US')

export const getTimeFormatted = (date) =>
  date.toLocaleTimeString(navigator.language, {
    hour: '2-digit',
    minute: '2-digit',
  })

export const getTimeFormatted12Hours = (date) =>
  date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    hour12: true,
  })

export const getDateFormattedForPicker = (date) =>
  date.toLocaleDateString('sv-SE', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })

export const getDateFormattedHuman = (date, isLongForm) =>
  date.toLocaleDateString('en-US', {
    month: isLongForm ? 'long' : 'short',
    day: '2-digit',
    year: 'numeric',
  })

export const getDateRangeFormatted = (dateStart, dateEnd, isLongForm = true) => {
  if (dateStart === dateEnd) return getDateFormattedHuman(dateStart, isLongForm)
  return `${getDateFormattedHuman(dateStart, isLongForm)} - ${getDateFormattedHuman(dateEnd, isLongForm)}`
}

export const getTimeRangeFormatted = (dateStart, dateEnd) => {
  if (getTimeFormatted12Hours(dateStart) === getTimeFormatted12Hours(dateEnd)) {
    return getTimeFormatted12Hours(dateStart)
  }

  return `${getTimeFormatted12Hours(dateStart)} - ${getTimeFormatted12Hours(dateEnd)}`
}

export const isZeroDate = (date) => date.getFullYear() > 2000
