<template>
  <div class="battle-heading">
    <div class="battle-heading__top">
      <div class="breadcrumbs">
        <router-link :to="{ name: 'Event', params: { id: eventId } }">
          <v-icon class="arrow-icon">
            {{ mdiArrowLeft }}
          </v-icon>
          Event
        </router-link>
      </div>

      <div class="title-wrapper">
        <div>
          <h2 class="section-title">
            {{ battle.title }}
          </h2>
          <h3 class="category">
            {{ getBattleCategory(battle.crewSize) }}
          </h3>
        </div>

        <button
          class="info-toggle"
          @click="isInfoOpened = !isInfoOpened"
        >
          <v-icon color="var(--color-black)">
            {{ isInfoOpened ? mdiCloseCircleOutline : mdiInformationOutline }}
          </v-icon>
        </button>
      </div>

      <BattleDetails
        :battle="battle"
        :class="{ open: isInfoOpened }"
        :is-there-groups="isThereGroups"
        class="battle-details"
      />
    </div>

    <div class="battle-heading__bottom">
      <nav
        v-if="availableTabs.length > 1"
        class="tabs"
      >
        <button
          v-for="tab in availableTabs"
          :key="tab.name"
          :class="{ active: $route.name === tab.route || parentRoute === tab.route }"
          class="tab-item"
          type="button"
          @click="toRoute(tab.route)"
        >
          {{ tab.name }}
        </button>
      </nav>
    </div>
  </div>
</template>

<script>
import Battles from '@/mixins/battle'
import BattleDetails from '@/components/Battle/BattleDetails.vue'
import { mdiArrowLeft, mdiCloseCircleOutline, mdiInformationOutline } from '@mdi/js'

export default {
  name: 'BattleHeading',
  components: { BattleDetails },
  mixins: [Battles],
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    battle: {
      type: Object,
      required: true,
    },
    isThereGroups: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      mdiCloseCircleOutline,
      mdiInformationOutline,
      mdiArrowLeft,
      isInfoOpened: false,
      tabs: [
        { name: 'Registration', route: 'Battle' },
        { name: 'Prelims', route: 'Prelims' },
        { name: 'Brackets', route: 'Brackets' },
      ],
    }
  },
  computed: {
    availableTabs() {
      if (this.battle.style === 'bracket') return this.tabs
      return [this.tabs[0]]
    },
    parentRoute() {
      return this.$route.matched[0].name
    },
  },
  methods: {
    toRoute(route) {
      if (this.$route.name === route) return
      if (route === 'Prelims') {
        this.$router.push({ name: route, query: { prelimsViewMode: 'list' } })
      } else {
        this.$router.push({ name: route })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.battle-heading {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 40px;

  @media (min-width: $screen-sm) {
    margin-top: 28px;
  }
}

.battle-heading__top {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 28px 20px 20px;
  background-color: var(--color-white);

  @media (min-width: $screen-sm) {
    padding: 20px;
  }
}

.battle-heading__bottom {
  padding: 0 20px;
}

.title-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.tabs {
  display: flex;

  .tab-item {
    flex: 1 1 0;
    padding-bottom: 16px;
    color: var(--color-dark-800);
    border-bottom: 2px solid var(--color-grey-600);

    &.active,
    &:focus-visible,
    &:hover {
      font-weight: var(--font-weight-bold);
      border-bottom-color: var(--color-dark-800);
    }
  }
}

.arrow-icon {
  margin-right: 5px;
  color: var(--sky-blue) !important;
  font-size: 16px !important;
}

.breadcrumbs a {
  display: flex;
  align-items: center;
  color: var(--sky-blue) !important;
  font-weight: var(--font-weight-bold);
}

.section-title {
  @include font-h4;

  text-transform: uppercase;
}

.category {
  @include font-h4;

  text-transform: uppercase;
}

.info-toggle {
  display: block;

  @media (min-width: $screen-sm) {
    display: none;
  }
}

.battle-details {
  display: none;

  &.open {
    display: flex;

    @media (min-width: $screen-sm) {
      display: grid;
    }
  }

  @media (min-width: $screen-sm) {
    display: grid;
  }
}
</style>
