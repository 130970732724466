<template>
  <OnboardingFormLayout
    :current-step="currentStep"
    :error="error"
    :steps="steps"
  >
    <template #default>
      <div class="onboarding-avatar-form">
        <button
          type="button"
          @click="openFileDialog"
        >
          <img
            :class="{ 'onboarding-avatar-form__img--active': filesImgAvatar }"
            :src="avatarUrl"
            alt="avatar"
            class="onboarding-avatar-form__img"
            height="140"
            width="140"
          />
        </button>
        <!--        eslint-disable  vuejs-accessibility/form-control-has-label-->
        <input
          ref="inputFile"
          accept="image/png, image/jpeg"
          name="userImage"
          style="display: none"
          type="file"
          @change="handlerFileChange"
        />
        <!--        eslint-enable  vuejs-accessibility/form-control-has-label-->
        <p class="onboarding-avatar-form__title">Add a profile picture</p>
      </div>
    </template>
    <template #actions>
      <BKButton
        outlined
        @click="$emit('goBack')"
      >
        back
      </BKButton>

      <BKButton
        :disabled="loading"
        :loading="loading"
        white-text
        @click="$emit('submitForm', avatarUrl)"
      >
        next
      </BKButton>
    </template>
  </OnboardingFormLayout>
</template>

<script>
import { ImageActions } from '@/services/image'
import BKButton from '@/components/AppShared/BKButton.vue'
import OnboardingFormLayout from '@/components/Onboarding/OnboardingFormLayout.vue'

export default {
  components: {
    BKButton,
    OnboardingFormLayout,
  },
  props: {
    currentStep: {
      type: Number,
      default: 0,
    },
    error: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    steps: {
      type: Number,
      default: 0,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      avatarUrl: '/img/addImageProfile.svg',
      filesImgAvatar: null,
    }
  },
  methods: {
    openFileDialog() {
      this.$refs.inputFile.click()
    },
    async handlerFileChange(e) {
      this.filesImgAvatar = e.target.files || e.dataTransfer.files
      if (!this.filesImgAvatar.length > 0) return
      const reader = new FileReader()
      // eslint-disable-next-line no-return-assign
      reader.onload = (event) => (this.avatarUrl = event.target.result)
      reader.readAsDataURL(this.filesImgAvatar[0])

      const avatarId = await ImageActions.saveImage(this.filesImgAvatar[0])
      this.$emit('changeAvatar', avatarId)
    },
  },
}
</script>

<style lang="scss" scoped>
.onboarding-avatar-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
}

.onboarding-avatar-form__img {
  margin-bottom: 20px;
  object-fit: cover;
  object-position: center;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  perspective: 1000px;
  transition: transform 0.3s ease-in-out;

  &--active {
    border-radius: 50%;
    transform: rotateY(360deg);
  }
}
</style>
