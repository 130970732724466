// eslint-disable-next-line no-restricted-globals
self.addEventListener('install', () => {
  // Skip over the "waiting" lifecycle state, to ensure that our
  // new service worker is activated immediately, even if there's
  // another tab open controlled by our older service worker code.
  // eslint-disable-next-line no-restricted-globals
  self.skipWaiting()
})

// eslint-disable-next-line no-restricted-globals
self.addEventListener('activate', () => {
  // Optional: Get a list of all the current open windows/tabs under
  // our service worker's control, and force them to reload.
  // This can "unbreak" any open windows/tabs as soon as the new
  // service worker activates, rather than users having to manually reload.
  // eslint-disable-next-line no-restricted-globals
  self.clients
    .matchAll({
      type: 'window',
    })
    .then((windowClients) => {
      windowClients.forEach((windowClient) => {
        windowClient.navigate(windowClient.url)
      })
    })
})
