import { render, staticRenderFns } from "./ProfileEventsTab.vue?vue&type=template&id=aa3baf96&scoped=true"
import script from "./ProfileEventsTab.vue?vue&type=script&lang=ts&setup=true"
export * from "./ProfileEventsTab.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./ProfileEventsTab.vue?vue&type=style&index=0&id=aa3baf96&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa3baf96",
  null
  
)

export default component.exports