var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bracket"},_vm._l((_vm.bracket),function(level,index){return _c('article',{key:index,staticClass:"round"},_vm._l((level),function(sparring,i){return _c('article',{key:i,staticClass:"sparring",class:[`level-${index + 1}`, _vm.side, { clickable: sparring.red.id && sparring.blue.id }],on:{"click":function($event){return _vm.toSparring(sparring.sparringId)}}},[_c('div',{staticClass:"participant p-red",class:{
          winner: _vm.getSparringWinnerColor(sparring) === 'red',
          looser: _vm.getSparringWinnerColor(sparring) === 'blue',
          highlighted: _vm.participantToHighlightId && sparring.red.id === _vm.participantToHighlightId,
        }},[_vm._v(" "+_vm._s(sparring.red.name)+" ")]),_c('div',{staticClass:"participant p-blue",class:{
          winner: _vm.getSparringWinnerColor(sparring) === 'blue',
          looser: _vm.getSparringWinnerColor(sparring) === 'red',
          highlighted: _vm.participantToHighlightId && sparring.blue.id === _vm.participantToHighlightId,
        }},[_vm._v(" "+_vm._s(sparring.blue.name)+" ")])])}),0)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }