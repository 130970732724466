<template>
  <!--  eslint-disable vuejs-accessibility/click-events-have-key-events-->
  <article
    class="tier"
    @click="$emit('select')"
  >
    <div class="header">
      <h3 class="tier-title">
        {{ tier.title }}
      </h3>
      <div class="status">
        <v-icon v-if="active">
          {{ mdiRadioboxMarked }}
        </v-icon>
        <v-icon v-else>
          {{ mdiRadioboxBlank }}
        </v-icon>
      </div>
    </div>

    <h4 class="cost">{{ tier.ticketCost }} + fees</h4>

    <div class="description">
      {{ tier.description }}
    </div>
  </article>
  <!--  eslint-enable vuejs-accessibility/click-events-have-key-events-->
</template>

<script>
import { mdiRadioboxBlank, mdiRadioboxMarked } from '@mdi/js'

export default {
  name: 'EventSidebarPurchaseTier',
  props: {
    tier: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      mdiRadioboxMarked,
      mdiRadioboxBlank,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.tier {
  min-height: 132px;
  padding: 12px;
  border-radius: var(--border-radius-rounded);
  box-shadow: 0 1px 4px rgb(0 0 0 / 20%);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tier-title {
    @include font-h3;
  }

  .cost {
    margin-top: 8px;
    font-weight: var(--font-weight-bold);
  }

  .description {
    @include font-body2;

    margin-top: 8px;
    color: var(--color-grey-300);
  }
}
</style>
