<script lang="ts" setup>
import { ISparring } from '@/types/sparring'
import { computed, ref } from 'vue'
import { IBattle } from '@/types/battle'
import api from '@/api'
import ScorelessSparring from '@/components/Battle/Sparring/ScorelessSparring.vue'

const emit = defineEmits<{ (e: 'update:sparring', value: ISparring): void }>()

interface IProps {
  sparring: ISparring
  battle: IBattle
}

const props = defineProps<IProps>()
const selectedWinner = ref<number>(props.sparring.winnerId)
const isFinishedSparring = computed(() => props.sparring.status === 'finished')
const resetLoadingBtn = ref(false)
const finishLoadingBtn = ref(false)

async function setWinner() {
  finishLoadingBtn.value = true
  const data = await api.admin.setSparringWinner({
    sparringId: props.sparring.sparringId,
    winnerId: selectedWinner.value,
  })
  finishLoadingBtn.value = false
  emit('update:sparring', data)
}

async function resetSparring() {
  resetLoadingBtn.value = true
  selectedWinner.value = props.sparring.winnerId
  const data = await api.sparrings.reset(props.sparring.sparringId)
  resetLoadingBtn.value = false
  emit('update:sparring', data)
}
</script>

<template>
  <div class="sparring-admin-view">
    <h3
      v-if="!isFinishedSparring"
      class="admin-view-title"
    >
      Choose the winner
    </h3>
    <ScorelessSparring
      :battle="battle"
      :disabled="finishLoadingBtn"
      :selected-winner="selectedWinner"
      :sparring="sparring"
      @select-winner="selectedWinner = $event"
    >
      <template #actions>
        <BKButton
          v-if="isFinishedSparring"
          :loading="resetLoadingBtn"
          class="finish-btn"
          white-text
          @click="resetSparring"
        >
          Reset
        </BKButton>
        <BKButton
          v-else
          :disabled="!selectedWinner"
          :loading="finishLoadingBtn"
          class="finish-btn"
          white-text
          @click="setWinner"
        >
          Finish
        </BKButton>
      </template>
    </ScorelessSparring>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.sparring-admin-view {
  .admin-view-title {
    @include font-h3;

    margin-bottom: 50px;
    text-align: center;
  }

  .finish-btn {
    max-width: 400px;

    &.v-btn.v-btn--disabled.v-btn--has-bg {
      color: var(--color-white) !important;
      background-color: #c9ced5 !important;
    }
  }
}
</style>
