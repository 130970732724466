import ax from 'axios'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { getAuth } from 'firebase/auth'

const api = ax.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      store.dispatch('showAlertToast', error.response.data)
    }

    return Promise.reject(error)
  }
)

api.interceptors.request.use(
  async (config) => {
    const userFB = await getAuth().currentUser
    if (!userFB) return config

    const token = await userFB.getIdToken()
    const requestConfig = config
    requestConfig.headers['X-Auth-Token'] = token

    if (localStorage.getItem('currentProfileId')) {
      requestConfig.headers['Caller-Id'] = localStorage.getItem('currentProfileId')
    }

    return requestConfig
  },

  (error) => Promise.reject(error)
)

const imgApi = ax.create({
  baseURL: `${process.env.VUE_APP_IMG_URL}`,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

imgApi.interceptors.request.use(
  async (config) => {
    const { currentUser } = getAuth()
    const token = await currentUser.getIdToken()
    const requestConfig = config

    requestConfig.headers['X-Auth-Token'] = token

    return requestConfig
  },

  (error) => Promise.reject(error)
)

export { api, imgApi }
