<template>
  <div class="date-time">
    <p class="date-time__title">Date and time *</p>
    <v-row>
      <v-col cols="6">
        <v-menu
          ref="dateStart"
          v-model="menuDateStart"
          :close-on-content-click="false"
          min-width="290px"
          offset-overflow
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateStart"
              :append-icon="mdiCalendarCheckOutline"
              background-color="#fff"
              class="date-time__date mb-n6"
              hide-details
              label="Start date"
              outlined
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dateStart"
            class="date-time__menu"
            no-title
            scrollable
            @input="menuDateStart = false"
          />
        </v-menu>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="timeStart"
          :append-icon="mdiClockOutline"
          background-color="#fff"
          class="date-time__time mb-n6"
          outlined
          type="time"
        />
      </v-col>
      <v-col cols="6">
        <v-menu
          ref="dateEnd"
          v-model="menuDateEnd"
          :close-on-content-click="false"
          min-width="290px"
          offset-overflow
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateEnd"
              :append-icon="mdiCalendarCheckOutline"
              background-color="#fff"
              class="date-time__date"
              hide-details
              label="End date"
              outlined
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dateEnd"
            class="date-time__menu"
            no-title
            scrollable
            @input="menuDateEnd = false"
          />
        </v-menu>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="timeEnd"
          :append-icon="mdiClockOutline"
          background-color="#fff"
          class="date-time__time"
          outlined
          type="time"
        />
      </v-col>
    </v-row>
    <div class="date-time__navigation navigation">
      <div class="navigation__wrap-dots mt-6">
        <span
          v-for="i in 5"
          v-show="isNew"
          :key="i"
          :class="i === currentDotNav ? 'navigation__dot--active' : ''"
          class="navigation__dot"
        />
      </div>
      <p
        v-show="error"
        class="navigation__text-error text-center mb-2"
      >
        {{ error }}
      </p>
      <div class="navigation__wrap-btn mb-10">
        <div class="d-flex flex-column flex-sm-row-reverse">
          <v-btn
            :loading="loadingNextBtn"
            class="navigation__btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="handlerClickNext"
          >
            {{ backStep ? 'save' : 'next' }}
          </v-btn>
          <v-btn
            class="navigation__btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="backStep ? handlerClickCancelEdit() : handlerClickBack()"
          >
            {{ backStep ? 'cancel' : 'back' }}
          </v-btn>
        </div>
        <v-btn
          v-if="!backStep"
          block
          class="navigation__btn-cancel"
          color="var(--color-blue-800)"
          height="48"
          max-width="194"
          text
          @click="toggleCancelEventCreation"
        >
          cancel
        </v-btn>
      </div>
    </div>
    <CreateEventCancel
      :event="eventData"
      @saveAsDraft="$emit('saveAsDraft')"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getDateFormattedForPicker, getTimeFormatted } from '@/services/date'
import CreateEventCancel from '@/components/CreateEventCancel.vue'
import { mdiCalendarCheckOutline, mdiClockOutline } from '@mdi/js'

export default {
  components: { CreateEventCancel },
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    screen: {
      type: String,
      default: '',
    },
    eventData: {
      type: Object,
      default: () => ({}),
    },
    backStep: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiClockOutline,
      mdiCalendarCheckOutline,
      menuDateStart: false,
      menuDateEnd: false,
      dateStart: '',
      dateEnd: '',
      timeStart: '12:00',
      timeEnd: '12:00',
      loadingNextBtn: false,
      currentDotNav: 2,
      error: null,
      isCancel: false,
    }
  },
  computed: {
    isInvalidDate() {
      return (
        this.createDateTime(this.dateEnd, this.timeEnd).getTime() <
        this.createDateTime(this.dateStart, this.timeStart).getTime()
      )
    },
  },
  created() {
    this.setDateAndTimeForPicker()
  },
  methods: {
    ...mapActions(['toggleCancelEventCreation']),
    setDateAndTimeForPicker() {
      this.dateStart = getDateFormattedForPicker(this.eventData.dateStart)
      this.dateEnd = getDateFormattedForPicker(this.eventData.dateEnd)
      this.timeStart = getTimeFormatted(this.eventData.dateStart)
      this.timeEnd = getTimeFormatted(this.eventData.dateEnd)
    },
    async handlerClickNext() {
      this.error = null

      if (this.isInvalidDate) {
        this.error = "End Date can't be before the Start Date"
      }

      this.eventData.dateStart = this.createDateTime(this.dateStart, this.timeStart)
      this.eventData.dateEnd = this.createDateTime(this.dateEnd, this.timeEnd)

      this.loadingNextBtn = true
      this.$emit('updateEvent')
      this.$parent.isClickBtnNext = true
      this.$emit('update:screen', this.backStep ? 'CreateEventTotal' : 'CreateEventTiers')
      this.loadingNextBtn = false
    },
    handlerClickBack() {
      this.$parent.isClickBtnNext = false
      this.$emit('update:screen', 'CreateEventInfo')
    },
    handlerClickCancelEdit() {
      this.$emit('update:screen', 'CreateEventTotal')
    },
    createDateTime(date, time) {
      return new Date(`${date.replace(/-/g, '/')} ${time}`)
    },
  },
}
</script>

<style scoped>
.date-time__date >>> .v-input__append-inner,
.date-time__time >>> .v-input__append-inner {
  position: absolute;
  right: 10px;
  background: white;
  pointer-events: none;
}

.date-time__menu >>> .v-btn--active {
  color: white !important;
}
</style>

<style lang="scss" scoped>
@import '@/assets/style/mixins';
@import '@/assets/style/navigationEventCreate';

.date-time {
  max-width: 400px;
  margin: 60px auto 0;

  &__title {
    margin: 12px 0;
    color: var(--color-dark-800);
  }
}
</style>
