<script lang="ts" setup>
import { useImage } from '@/shared/composables/image'
import { useRoute, useRouter } from 'vue-router/composables'
import { IBattle, ICrew, IGroupCrews, IGroupParticipants, IParticipant } from '@/types/battle'
import { computed, onMounted, ref } from 'vue'
import api from '@/api'
import { IEvent } from '@/types/event'
import store from '@/store'
import BKInput from '@/components/AppShared/BKInput.vue'
import { mdiMagnify, mdiPlus } from '@mdi/js'
import { BKConfirmationDialog } from '@/shared/ui/modals'

const { getAvatar } = useImage()
const router = useRouter()
const route = useRoute()

interface IProps {
  battle: IBattle
  groupsList: IGroupCrews[] | IGroupParticipants[] | []
  event: IEvent
}

const props = defineProps<IProps>()

const confirmationDialog = ref({
  isShow: false,
  title: '',
  subtitle: '',
  confirmText: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  confirm: () => {},
})

const sortBy = ref('')
const prelims = ref<Record<string, unknown>[] | null>(null)
const loading = ref(false)
const searchText = ref('')
const filteredGroups = computed(() => {
  if (!props.groupsList) return []
  // eslint-disable-next-line array-callback-return,consistent-return
  return props.groupsList.filter((group) => {
    if (group.participants) {
      return group.participants.some((participant: IParticipant) =>
        participant.name.toLowerCase().includes(searchText.value.toLowerCase())
      )
    }

    if (group.crews) {
      return group.crews.some((participant: ICrew) =>
        participant.name.toLowerCase().includes(searchText.value.toLowerCase())
      )
    }
  })
})

async function finishPrelims() {
  loading.value = true

  const updatedPrelims = await api.prelims.finishPrelims(props.battle.id)
  loading.value = false
  if (!updatedPrelims) return
  prelims.value = updatedPrelims
  router.push({ query: { prelimsViewMode: 'list' } })
}

async function fetchPrelims() {
  const prelimsData = await api.prelims.getPrelims(route.params.id, sortBy.value)
  if (!prelimsData) {
    store.commit('setIsNotFound', true)
    return
  }

  prelims.value = prelimsData
}

function openGroup(groupId: string): void {
  router.push({ name: 'Group', params: { groupId } })
}

async function undoBrackets() {
  loading.value = true
  await api.prelims.undoBrackets(props.battle.id)
  await fetchPrelims()
  loading.value = false
}

function showUndoBracketsConfirmationDialog() {
  confirmationDialog.value = {
    isShow: true,
    title: 'Undo brackets',
    subtitle: `Are you sure you want to undo brackets?`,
    confirmText: 'Undo',
    confirm: () => {
      undoBrackets()
      confirmationDialog.value.isShow = false
    },
  }
}

function showAddToBracketsConfirmationDialog() {
  confirmationDialog.value = {
    isShow: true,
    title: 'Add to bracket',
    subtitle: `Are you sure you want to add to brackets?`,
    confirmText: 'Add',
    confirm: () => {
      finishPrelims()
      confirmationDialog.value.isShow = false
    },
  }
}

onMounted(() => {
  fetchPrelims()
})
</script>

<template>
  <section class="prelims-groups">
    <div class="search-wrapper">
      <BKInput
        v-model="searchText"
        :append-icon="mdiMagnify"
        class="search"
        label="Search dancers"
      />
    </div>
    <v-expansion-panels
      v-if="filteredGroups.length"
      class="panels"
      multiple
    >
      <v-expansion-panel
        v-for="item in filteredGroups"
        :key="item.id"
        class="group"
      >
        <v-expansion-panel-header class="panel-header">
          <button
            class="name"
            type="button"
            @click.prevent.self="openGroup(item.id)"
          >
            {{ item.name }}
          </button>
          <span class="amount">
            {{ item.amountMembers }}
            {{ item.participants ? 'participants' : 'crews' }}
          </span>
          <template #actions>
            <v-icon
              color="var(--color-grey-500)"
              size="20"
            >
              {{ mdiPlus }}
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="panel-content">
          <div class="participants-list">
            <template v-if="item.participants && item.participants.length">
              <div
                v-for="(participant, index) in item.participants"
                :key="index"
                class="participant"
              >
                <div class="name-container">
                  <span class="name">{{ participant.name }}</span>
                </div>
                <v-avatar
                  class="d-inline-block"
                  size="32"
                >
                  <v-img :src="getAvatar(participant.avatar, 64)" />
                </v-avatar>
              </div>
            </template>
            <template v-if="item.crews && item.crews.length">
              <div
                v-for="(crew, index) in item.crews"
                :key="index"
                class="participant"
              >
                <div class="name-container">
                  <span class="name">{{ crew.name }}</span>
                </div>
                <div>
                  <v-avatar
                    v-for="participant in crew.participants"
                    :key="participant.id"
                    class="avatar"
                    size="24"
                  >
                    <v-img :src="getAvatar(participant.avatar, 64)" />
                  </v-avatar>
                </div>
              </div>
            </template>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <section
      v-if="event.permissions.canEdit"
      class="actions"
    >
      <template v-if="prelims && prelims.permissions.canFinishPrelims">
        <BKButton
          :disabled="prelims.permissions.canShuffle || !!prelims.permissions.error"
          :loading="loading"
          color="var(--color-blue-800)"
          white-text
          @click="showAddToBracketsConfirmationDialog"
        >
          Add to bracket
        </BKButton>

        <span
          v-if="prelims.permissions.error"
          class="submit-text err"
          >{{ prelims.permissions.error }}</span
        >
      </template>
      <BKButton
        v-if="prelims && prelims.permissions.canUndoBrackets"
        ref="addBracketBtn"
        :loading="loading"
        white-text
        @click="showUndoBracketsConfirmationDialog"
      >
        Undo bracket
      </BKButton>
      <BKButton
        v-if="route.name === 'Prelims'"
        :to="{ name: 'CreatingPrelimsGroups' }"
        class="create-group-btn"
        outlined
      >
        <img
          alt="Groups"
          src="/img/ic_Groups.svg"
        />
        Edit groups
      </BKButton>
    </section>
    <BKConfirmationDialog
      :confirm-text="confirmationDialog.confirmText"
      :is-show="confirmationDialog.isShow"
      :title="confirmationDialog.title"
      @cancel="confirmationDialog.isShow = false"
      @confirm="confirmationDialog.confirm"
    >
      {{ confirmationDialog.subtitle }}
    </BKConfirmationDialog>
  </section>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.prelims-groups {
  display: grid;
  gap: 10px;
  padding: 0 20px;

  .panels {
    gap: 10px;
  }

  .group {
    padding: 30px 16px;
    background-color: var(--color-grey-700) !important;
    border: 1px solid var(--color-grey-600) !important;
    border-radius: 6px;

    &::before {
      box-shadow: none;
    }

    &::after {
      display: none;
    }

    .panel-header {
      min-height: 26px;
      padding: 0;

      &.v-expansion-panel-header--active {
        .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate) {
          .v-icon {
            transform: rotate(-135deg);
          }
        }
      }

      .name {
        @include font-h4;

        margin-bottom: 0;
        font-weight: var(--font-weight-medium);
        text-align: left;
      }

      .amount {
        @include font-body2;

        display: inline-block;
        margin-right: 4px;
        color: var(--color-grey-300);
        text-align: right;
      }
    }

    .panel-content {
      ::v-deep .v-expansion-panel-content__wrap {
        padding: 14px 0 0;
      }

      .participants-list {
        display: grid;

        .participant {
          display: flex;
          justify-content: space-between;
          padding: 12px 0;
          border-bottom: 1px solid var(--color-grey-600);

          .name-container {
            max-width: 160px;

            .name {
              display: block;
              overflow: hidden;
              color: var(--color-grey-300);
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .avatar {
            margin-left: -10px;
          }
        }

        .participant:last-child {
          border-width: 0;
        }
      }
    }
  }

  .actions {
    display: grid;
    gap: 12px;

    .create-group-btn {
      & ::v-deep .button-text {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }
}
</style>
