<template>
  <div class="content">
    <div class="wrapper">
      <BattleHeading
        :battle="battle"
        :event-id="event.id"
      />

      <div class="battle-wrapper">
        <BattleRegister
          v-if="battle.permissions.registerButtonVisible"
          :battle="battle"
          :event="event"
          class="register-card"
          @update:battle="$emit('update:battle', $event)"
          @reload:battle="$emit('reload:battle')"
          @update:event="$emit('update:event', $event)"
          @reload:event="$emit('reload:event')"
        />

        <BattleWinners
          v-if="battle.winners && !isShowWinnersEditor"
          :can-edit="event.permissions.canEdit && battle.style === 'classic'"
          :winners="battle.winners"
          class="winners-editor"
          @editWinners="isShowWinnersEditor = true"
        />

        <BattleWinnerEditor
          v-if="
            battle.style === 'classic' && event.permissions.canEdit && event.period === 'past' && isShowWinnersEditor
          "
          :battle="battle"
          :battle-winners="battle.winners || []"
          class="winners-editor"
          @cancelEditWinners="isShowWinnersEditor = false"
          @closeEditWinners="isShowWinnersEditor = false"
          @update:battle="$emit('update:battle', $event)"
        />

        <BattleParticipants
          :battle="battle"
          :event="event"
          @update:battle="$emit('update:battle', $event)"
          @reload:battle="$emit('reload:battle')"
        />

        <BattleRegisterParticipantsModal
          :battle="battle"
          :event="event"
          :is-show.sync="isShowAddCompetitors"
          @update:battle="$emit('update:battle', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BattleHeading from '@/components/Battle/BattleHeading.vue'
import BattleRegister from '@/components/Battle/BattleRegister.vue'
import BattleParticipants from '@/components/Battle/BattleParticipants.vue'
import BattleWinnerEditor from '@/components/Battle/BattleWinnersEditor.vue'
import BattleWinners from '@/components/Battle/BattleWinners.vue'
import BattleRegisterParticipantsModal from '@/components/Battle/BattleRegisterParticipantsModal.vue'

export default {
  name: 'BKBattle',
  components: {
    BattleWinners,
    BattleWinnerEditor,
    BattleHeading,
    BattleRegister,
    BattleParticipants,
    BattleRegisterParticipantsModal,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    battle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShowAddCompetitors: false,
      isShowWinnersEditor: !this.battle.winners,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.content {
  @include page-with-content;

  padding-top: 78px;
}

.winners-editor {
  margin: 16px;
  padding: 16px;
}

.register-card {
  padding: 0 20px;
}
</style>
