<template>
  <div class="purchase-container">
    <section
      v-if="loadingStatus === 'loading'"
      class="loader"
    >
      <div class="loader-status">
        <div class="status">Securely processing your order</div>
        <v-progress-circular
          :size="100"
          color="var(--color-green)"
          indeterminate
        >
          <v-icon>{{ mdiLockOutline }}</v-icon>
        </v-progress-circular>
      </div>
    </section>
    <section
      v-if="loadingStatus === 'successful'"
      class="loader"
    >
      <div class="loader-status">
        <div class="status">Payment successful</div>
        <v-progress-circular
          :size="100"
          color="var(--color-green)"
          value="100"
        >
          <v-icon color="var(--color-green)">
            {{ mdiCheck }}
          </v-icon>
        </v-progress-circular>

        <div class="status-description">
          Ticket has been sent to {{ email }} and you can also views your ticket from the event detail page.
        </div>
      </div>
    </section>
    <section
      v-show="loadingStatus === 'none'"
      class="purchase"
    >
      <section class="information">
        <div class="header">
          <div class="event-info">
            <div class="img">
              <img
                :src="getEventImg(event.image, 800)"
                alt="event img"
              />
            </div>
            <div class="details">
              <div class="title">
                {{ event.title }}
              </div>
              <div class="date">
                {{ date }}
              </div>
              <div class="date">
                {{ time }}
              </div>
            </div>
          </div>
        </div>

        <div
          v-show="!isUserOnCheckoutStep"
          class="tickets"
        >
          <h4 class="tickets-title">Choose your ticket</h4>
          <EventSidebarPurchaseTier
            v-for="tier in event.tiers"
            :key="tier.id"
            :active="tier.id === selectedTierId"
            :tier="tier"
            class="tier"
            @select="selectedTierId = tier.id"
          />

          <EventSidebarPurchaseItem
            :item="selectedTier"
            :loading="loading.tier"
            class="purchase-overview-mobile"
          />
        </div>

        <div
          v-show="isUserOnCheckoutStep"
          class="checkout"
        >
          <EventSidebarPurchaseItem
            :item="selectedTier"
            :loading="loading.tier"
            breakdown
            class="purchase-overview-mobile breakdown"
          />
          <h3>Contact information</h3>
          <v-text-field
            v-model="fullName"
            class="checkout-field"
            hide-details
            label="Full name"
            outlined
          />
          <v-text-field
            v-model="email"
            class="checkout-field"
            hide-details
            label="Email"
            outlined
            @input="$nextTick(() => (email = email.trim()))"
          />
          <h3 class="mt-6">Credit card info</h3>
          <v-text-field
            v-model="cardHolderName"
            class="checkout-field"
            hide-details
            label="Name on card"
            outlined
          />
          <!--          eslint-disable vuejs-accessibility/click-events-have-key-events-->
          <div
            id="card-element"
            class="card"
            @click="focusOnCard"
          />
          <!--          eslint-enable vuejs-accessibility/click-events-have-key-events-->
          <div class="payment-error">
            {{ error }}
          </div>
        </div>

        <div
          v-if="!isUserOnCheckoutStep"
          class="actions"
        >
          <v-btn
            :disabled="!selectedTier || loading.tier"
            color="var(--color-blue-800)"
            height="52"
            width="100%"
            @click="isUserOnCheckoutStep = true"
          >
            <span class="action-text">Continue to Check out</span>
          </v-btn>
        </div>

        <div
          v-else
          class="actions"
        >
          <v-btn
            :width="$vuetify.breakpoint.mdAndDown ? '100%' : '244'"
            color="var(--color-blue-800)"
            height="52"
            outlined
            @click="isUserOnCheckoutStep = false"
          >
            <span>Go back</span>
          </v-btn>

          <v-btn
            :disabled="!isUserCanCheckout"
            :width="$vuetify.breakpoint.mdAndDown ? '100%' : '244'"
            class="mb-2"
            color="var(--color-green)"
            height="52"
            @click="confirmPayment"
          >
            <span class="action-text">Purchase ticket</span>
          </v-btn>
        </div>
      </section>

      <EventSidebarPurchaseItem
        :item="selectedTier"
        :loading="loading.tier"
        class="purchase-overview"
      />
    </section>
  </div>
</template>

<script>
import api from '@/api'
import Images from '@/mixins/image'
import { getDateRangeFormatted, getTimeRangeFormatted } from '@/services/date'
import EventSidebarPurchaseTier from '@/components/EventSidebarPurchaseTier.vue'
import EventSidebarPurchaseItem from '@/components/EventSidebarPurchaseItem.vue'
import { mapGetters } from 'vuex'
import { mdiCheck, mdiLockOutline } from '@mdi/js'

export default {
  name: 'EventSidebarPurchase',
  components: {
    EventSidebarPurchaseTier,
    EventSidebarPurchaseItem,
  },
  mixins: [Images],
  props: {
    event: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      mdiCheck,
      mdiLockOutline,
      fullName: '',
      loadingStatus: 'none',
      loading: {
        tier: false,
      },
      email: '',
      cardHolderName: '',
      cardElement: null,
      selectedTierId: this.event.tiers[0].id,
      isUserOnCheckoutStep: false,
      isCardInfoComplete: false,
      paymentSecret: '',
      error: '',
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    isUserCanCheckout() {
      return this.isCardInfoComplete && this.email && this.fullName && this.cardHolderName
    },
    date() {
      return getDateRangeFormatted(this.event.dateStart, this.event.dateEnd)
    },
    time() {
      return getTimeRangeFormatted(this.event.dateStart, this.event.dateEnd)
    },
    selectedTier() {
      return this.event.tiers.find((t) => t.id === this.selectedTierId)
    },
  },
  watch: {
    isUserOnCheckoutStep(value) {
      if (value) {
        this.mountCardElement()
        return
      }

      this.paymentSecret = ''
      this.isCardInfoComplete = false
      this.cardElement.destroy()
    },
  },
  mounted() {
    this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY, { locale: 'en' })
    this.email = this.getUserDB.email
    this.fullName = this.getUserDB.fullName
  },
  beforeDestroy() {
    if (this.cardElement) this.cardElement.destroy()
  },
  methods: {
    async confirmPayment() {
      this.loadingStatus = 'loading'
      if (!this.paymentSecret) await this.createTicketPayment()

      this.stripe
        .confirmCardPayment(this.paymentSecret, {
          receipt_email: this.email,
          payment_method: {
            card: this.cardElement,
            billing_details: {
              name: this.cardHolderName,
              email: this.email,
            },
          },
        })

        .then((result) => {
          if (result.error) {
            this.error = result.error.message
            this.loadingStatus = 'none'
            return
          }

          this.loadingStatus = 'successful'
          this.$emit('ticketPurchased')
        })
    },

    focusOnCard() {
      if (this.cardElement) {
        this.cardElement.focus()
      }
    },

    async createTicketPayment() {
      const paymentSecret = await api.events.createTicketPayment(this.event.id, this.selectedTier.id)
      if (!paymentSecret) return
      this.paymentSecret = paymentSecret
    },

    mountCardElement() {
      this.cardElement = this.stripe.elements().create('card')
      this.cardElement.mount('#card-element')
      this.cardElement.on('change', (event) => {
        this.isCardInfoComplete = event.complete
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.purchase-container {
  @media (max-width: $screen-md) {
    padding-top: 50px;
  }
}

.purchase {
  display: flex;
  background-color: var(--color-white);
}

.information {
  width: 100%;
}

.event-info,
.tickets,
.actions,
.checkout {
  padding: 20px 40px;

  @media (max-width: $screen-md) {
    padding: 20px;
  }
}

.tickets,
.checkout {
  max-height: 575px;
  overflow-y: auto;

  @media (max-width: $screen-md) {
    max-height: unset;
  }
}

.header {
  border-bottom: 1px solid var(--color-grey-700);

  .event-info {
    display: flex;
  }

  img {
    width: 72px;
    height: 72px;
    margin-right: 16px;
    border-radius: 4px;
  }

  .date {
    @include font-body2;
  }
}

.tickets-title {
  margin-bottom: 15px;
  font-weight: var(--font-weight-normal);
}

.tier {
  margin-bottom: 8px;
  cursor: pointer;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  border-top: 1px solid var(--color-grey-700);

  @media (max-width: $screen-md) {
    flex-direction: column-reverse;
    padding-top: 0;
    border-top: unset;
  }
}

.purchase-overview {
  @media (max-width: $screen-md) {
    display: none;
  }

  &-mobile {
    display: none;
    margin-top: 15px;

    &.breakdown {
      margin-bottom: 15px;
    }

    @media (max-width: $screen-md) {
      display: block;
    }
  }
}

.action-text {
  color: var(--color-white);
}

.checkout {
  margin-bottom: 110px;

  @media (max-width: $screen-md) {
    margin-bottom: unset;
  }

  .checkout-field {
    margin-top: 8px;
  }

  .card {
    margin-top: 8px;
    padding: 20px;
    border: 1px solid rgb(0 0 0 / 38%);
    border-radius: 4px;
  }

  .payment-error {
    margin-top: 20px;
    color: var(--color-orange-500);
  }
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  background-color: var(--color-white);

  .loader-status {
    text-align: center;
  }

  .status {
    margin-bottom: 20px;
    font-weight: var(--font-weight-bold);
  }

  .status-description {
    max-width: 380px;
    margin-top: 20px;
  }
}
</style>
