const BATTLE_CATEGORIES = [
  { text: '1 vs 1', value: 1 },
  { text: '2 vs 2', value: 2 },
  { text: '3 vs 3', value: 3 },
  { text: '4 vs 4', value: 4 },
  { text: '5 vs 5', value: 5 },
  { text: '6 vs 6', value: 6 },
  { text: '7 vs 7', value: 7 },
  { text: '8 vs 8', value: 8 },
  { text: '9 vs 9', value: 9 },
  { text: '10 vs 10', value: 10 },
  { text: 'Unlimited', value: 10000 },
]
Object.freeze(BATTLE_CATEGORIES)

const BATTLE_PLACES = [
  { id: 1, title: '1st Place', fields: [] },
  { id: 2, title: '2nd Place', fields: [] },
  { id: 4, title: 'Top 4', fields: [] },
  { id: 8, title: 'Top 8', fields: [] },
  { id: 16, title: 'Top 16', fields: [] },
  { id: 32, title: 'Top 32', fields: [] },
]
Object.freeze(BATTLE_PLACES)

const EVENT_STATUSES = {
  eventPublished: 'published',
  eventDraft: 'draft',
}
Object.freeze(EVENT_STATUSES)

const BATTLE_ROLES = {
  judge: 'judge',
  dj: 'dj',
}
Object.freeze(BATTLE_ROLES)

export { BATTLE_CATEGORIES, BATTLE_PLACES, EVENT_STATUSES, BATTLE_ROLES }
