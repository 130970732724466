<template>
  <ModalWrapper
    :is-show="isShowDelete"
    @update:isShow="updateShowModal($event)"
  >
    <v-card tile>
      <div class="col-10 py-10 mx-auto">
        <v-container>
          <v-row>
            <v-card-title class="cancel__title"> Delete Event</v-card-title>
          </v-row>
          <v-card-text class="pa-1">
            Are you sure you want to delete <strong v-if="event.Title">{{ event.Title }}</strong>
            <strong v-else>this event</strong>?
          </v-card-text>
        </v-container>

        <v-card-actions
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
          class="d-flex"
        >
          <v-btn
            class="px-10 mx-3 my-2"
            color="#000000"
            height="48"
            outlined
            tile
            width="220"
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            class="px-10 mx-3 my-2"
            color="#f21849"
            height="48"
            outlined
            tile
            width="220"
            @click="removeEvent"
          >
            Delete event
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </ModalWrapper>
</template>

<script>
import api from '@/api'

import { ModalWrapper } from '@/shared/ui/modals'

export default {
  components: {
    ModalWrapper,
  },
  props: {
    isShowDelete: {
      type: Boolean,
      required: true,
    },
    redirectUser: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updateShowModal() {
      this.$emit('update:isShowDelete', false)
    },
    async removeEvent() {
      const resp = await api.events.deleteEvent(this.event.id)
      if (!resp) return
      this.$emit('update:isShowDelete', false)
      this.$emit('eventDeleted')

      if (this.redirectUser) {
        this.$router.push({ name: 'EventsPage' })
      }
    },
    close() {
      this.$emit('update:isShowDelete', false)
    },
  },
}
</script>
<style lang="scss" scoped>
.cancel {
  &__title {
    text-transform: uppercase;
  }
}
</style>
