<template>
  <button
    :class="{ active: active && !icon, 'active-icon': active && icon }"
    :title="number"
    class="point"
    type="button"
    @click="$emit('click')"
  >
    <v-icon v-if="icon">
      {{ icon }}
    </v-icon>

    <span v-else>{{ number }}</span>
  </button>
</template>

<script>
export default {
  name: 'JudgingPoint',
  props: {
    number: {
      type: Number,
      required: false,
      default: 0,
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.point {
  @include font-body2;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  min-width: 44px;
  height: 44px;
  color: var(--color-grey-300);
  font-weight: var(--font-weight-bold);
  border: 1px solid var(--color-grey-600);
  border-radius: 50%;

  &.active {
    color: var(--color-white);
    background: var(--color-dark-800);
  }

  &.active-icon {
    background: var(--color-gold-900);

    .v-icon {
      color: var(--color-white);
    }
  }
}
</style>
