<template>
  <section class="invitations">
    <h3 class="invitations-title">Invitations</h3>
    <article
      v-for="invite in invitations"
      :key="invite.id"
    >
      <BKCrewChip :crew="invite" />
      <div class="actions">
        <BKButton
          :disabled="!battle.permissions.registerButtonEnabled"
          :loading="loading.accept"
          class="action-btn white--text"
          color="var(--color-green)"
          @click="acceptInvitation(invite.id)"
        >
          Accept
        </BKButton>

        <BKButton
          :loading="loading.decline"
          class="action-btn"
          color="#FF2D53"
          outlined
          @click="declineInvitation(invite.id)"
        >
          Decline
        </BKButton>
      </div>
    </article>

    <section>
      <Agreements
        v-if="battle.permissions.showAgreement || battle.permissions.showWaiver"
        :battle="battle"
        @update:battle="$emit('update:battle', $event)"
        @reload:battle="$emit('reload:battle')"
      />
    </section>
  </section>
</template>

<script>
import api from '@/api'

import BKCrewChip from '@/components/BKCrewChip.vue'
import Agreements from '@/components/AgreementsPage.vue'

export default {
  name: 'BattleInvitations',
  components: {
    BKCrewChip,
    Agreements,
  },
  props: {
    invitations: {
      type: Array,
      required: true,
    },
    battle: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: {
        accept: false,
        decline: false,
      },
    }
  },
  methods: {
    async acceptInvitation(crewId) {
      this.loading.accept = true

      const updatedBattle = await api.crews.acceptInvitation(this.battle.id, crewId)
      if (updatedBattle) this.$emit('update:battle', updatedBattle)

      this.$emit('acceptInvite')
      this.loading.accept = false
    },
    async declineInvitation(crewId) {
      this.loading.decline = true
      const updatedBattle = await api.crews.declineInvitation(this.battle.id, crewId)
      if (updatedBattle) this.$emit('update:battle', updatedBattle)

      this.loading.decline = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.invitations {
  max-width: 450px;
  padding: 16px 20px;
}

.invitations-title {
  @include font-h3;

  display: block;
  margin-bottom: 10px;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
}

.action-btn {
  display: block;
  margin: 10px 10px 0 0;
}
</style>
