// eslint-disable-next-line import/no-cycle
import { imgApi } from '@/plugins/axios'
import store from '@/store'

const BASE_URL = process.env.VUE_APP_IMG_URL

const defaultImg = {
  avatar: '/img/default-avatar.png',
  card: '/img/profile/default-avatar.svg',
  event: '/img/org-default.svg',
  flag: '/img/flag-earth.png',
  org: '/img/org-default.svg',
}

class ImageActions {
  static async saveImage(file) {
    // TODO: discuss about add image logic (on save/on upload)
    if (file.size > 10000000) {
      store.commit('SHOW_ALERT_TOAST_MESSAGE', "Use an image smaller than 10Mb, otherwise, it won't be saved.")
      setTimeout(() => store.commit('SHOW_ALERT_TOAST_MESSAGE', ''), 5000)
      return
    }
    const formData = new FormData()
    formData.append('file', file)

    const { data } = await imgApi.post('/', formData)

    // eslint-disable-next-line consistent-return
    return data
  }

  static getImageById(id, width, height, type = 'avatar') {
    if (!id) return defaultImg[type]
    return `${BASE_URL}/${id}/${width}/${height}`
  }
}

export { ImageActions, defaultImg }
