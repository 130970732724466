<template>
  <ModalWrapper
    :is-show="isShowModal"
    modal-width="442"
    @update:isShow="$emit('update:isShowModal', false)"
  >
    <section
      v-if="getCrew"
      class="checkin-crew"
    >
      <h2
        v-if="getCrew.name"
        class="crew-name"
      >
        {{ getCrew.name }}
      </h2>
      <BattleCrewDetailsParticipant
        v-for="participant in getCrew.participants"
        :key="participant.id"
        :can-edit="event.permissions.canEdit"
        :event-id="event.id"
        :participant="participant"
        class="participant"
        @update:event="$emit('update:event', $event)"
        @reload:battle="$emit('reload:battle')"
      />
      <div>
        <Agreements
          v-if="battle.permissions.showAgreement || battle.permissions.showWaiver"
          :battle="battle"
          @update:battle="$emit('update:battle', $event)"
          @reload:battle="$emit('reload:battle')"
        />
        <v-btn
          v-if="!isUserInCrew"
          :disabled="isJoinCrewDisabled"
          :loading="loading"
          class="join-crew-button"
          color="var(--color-blue-800)"
          height="52"
          @click="join"
        >
          Join crew
        </v-btn>
        <v-btn
          v-else
          :loading="loading"
          class="join-crew-button"
          color="var(--color-blue-800)"
          height="52"
          @click="leave"
        >
          Leave crew
        </v-btn>
        <BKCheckinButton
          :event="event"
          @checkIn="$emit('reload:battle')"
          @purchase="$emit('reload:event')"
          @update:event="$emit('update:event', $event)"
        />
      </div>
    </section>
  </ModalWrapper>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
import { ModalWrapper } from '@/shared/ui/modals'
import Agreements from '@/components/AgreementsPage.vue'
import BKCheckinButton from '@/components/BKCheckinButton.vue'
import BattleCrewDetailsParticipant from '@/components/Battle/Crew/BattleCrewDetailsParticipant.vue'

export default {
  name: 'BattleCrewDetailsModal',
  components: {
    ModalWrapper,
    Agreements,
    BattleCrewDetailsParticipant,
    BKCheckinButton,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    crewId: {
      type: Number,
      required: true,
    },
    battle: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    getCrew() {
      return this.battle.crews.find((crew) => crew.id === this.crewId)
    },
    isJoinCrewDisabled() {
      if (!this.battle.permissions.registerButtonEnabled) return true
      return this.getCrew.participants.length >= this.battle.crewSize
    },
    isUserInCrew() {
      return this.getCrew.participants.some((p) => p.id === this.getUserDB && this.getUserDB.id)
    },
  },
  methods: {
    async join() {
      if (!this.getUserDB) {
        this.$router.push({ name: 'Login' })
        return
      }

      this.loading = true
      const updatedBattle = await api.crews.joinToCrew(this.battle.id, this.crewId)
      if (updatedBattle) this.$emit('update:battle', updatedBattle)

      this.$emit('join')
      this.loading = false
    },
    async leave() {
      this.loading = true
      const updatedBattle = await api.crews.unregister(this.battle.id, this.crewId)
      this.$emit('update:isShowModal', false)
      if (updatedBattle) this.$emit('update:battle', updatedBattle)

      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.checkin-crew {
  padding: 20px;
  background-color: var(--color-white);

  .participant {
    padding: 17px 0;
  }

  .join-crew-button {
    width: 100%;
    margin: 10px 0;
    color: var(--color-white);
  }
}
</style>
