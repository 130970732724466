<script lang="ts" setup>
import { IEventPreview } from '@/types/event'
import EventCardDesktop from '@/components/Events/EventCardDesktop.vue'
import EventCardMobile from '@/components/Events/EventCardMobile.vue'
import { useVuetify } from '@/services/useVuetify'
import { computed } from 'vue'

const vuetify = useVuetify()
const isDesktopTablet = computed(() => vuetify.breakpoint.smAndUp)

interface IProps {
  previewEvent: IEventPreview
}

defineProps<IProps>()
</script>

<template>
  <EventCardDesktop
    v-if="isDesktopTablet"
    :preview-event="previewEvent"
  />
  <EventCardMobile
    v-else
    :preview-event="previewEvent"
  />
</template>
