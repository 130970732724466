<template>
  <section class="authentication">
    <div class="authentication__background" />
    <div class="magic-link">
      <h2 class="magic-link__title">Magic Link</h2>
      <v-form
        v-if="!isShowFinishMessage"
        v-model="valid"
        class="wrap"
        @submit.prevent="sendLink"
      >
        <v-text-field
          v-model="email"
          :rules="[rules.required, rules.email]"
          autocomplete="off"
          background-color="#fff"
          class="magic-link__field"
          dense
          label="Email*"
          outlined
          @input="$nextTick(() => (email = email.trim()))"
          @keyup.enter="sendLink"
        />
        <span
          v-show="error"
          class="magic-link__text-error"
          >{{ error }}</span
        >
        <span class="magic-link__text-separate">Enter email address on the account</span>
        <v-btn
          :disabled="btnDisabled"
          :loading="btnLoading"
          block
          class="magic-link__btn"
          color="var(--color-blue-800)"
          height="48"
          outlined
          tile
          @click="sendLink"
        >
          Submit
        </v-btn>
      </v-form>
      <div
        v-else
        class="wrap"
      >
        <span class="magic-link__text-separate">Check your inbox for the magic link</span>
        <br />
        <router-link
          class="magic-link__link"
          to="/login"
        >
          login page
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import validationRules from '@/mixins/validation'
import { userApi } from '@/enitites/user'

export default {
  mixins: [validationRules],
  data() {
    return {
      valid: true,
      isShowFinishMessage: false,
      email: null,
      error: null,
      btnDisabled: true,
      btnLoading: false,
    }
  },
  watch: {
    valid(value) {
      this.btnDisabled = !value
    },
  },
  methods: {
    async sendLink() {
      if (!this.email) {
        this.error = 'Field is required'
        return
      }
      this.btnLoading = true
      const { data: isEmailExist } = await userApi.magicLink(this.email)
      this.btnLoading = false
      if (!isEmailExist) {
        this.btnLoading = false
        this.email = null
        return
      }
      window.localStorage.setItem('emailForMagicLink', this.email)
      this.isShowFinishMessage = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.authentication {
  height: 100%;
  background-color: rgb(255 255 255);

  &__background {
    height: 488px;
    background: #fff url('/img/HeroBG.jpg') 50% 95% / cover no-repeat;
  }
}

.magic-link {
  position: relative;
  width: 100%;
  max-width: 410px;
  min-height: 500px;
  margin: 0 auto;
  margin-top: -300px;
  padding: 0 20px;
  text-align: center;

  &__title {
    @include font-h2;

    margin-bottom: 80px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }

  &__field.v-text-field {
    margin-bottom: -20px;
  }

  &__btn {
    margin-bottom: 12px;
    font-weight: bold;
    border: 2px solid;
  }

  &__text-separate {
    display: inline-block;
    margin-top: 12px;
    margin-bottom: 20px;
  }

  &__text-error {
    display: block;
    margin-bottom: 15px;
    color: red;
  }

  &__link {
    color: #38a1f3;
    text-decoration: none;
  }
}
</style>
