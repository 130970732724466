<template>
  <div class="content">
    <div class="wrapper">
      <BattleHeading
        :battle="battle"
        :event-id="event.id"
        class="battle-heading"
      />

      <div class="battle-wrapper">
        <BattleInvitations
          v-if="invitationsToCrew.length"
          :battle="battle"
          :event="event"
          :invitations="invitationsToCrew"
          @acceptInvite="isShowRegister = true"
          @update:battle="$emit('update:battle', $event)"
          @reload:battle="$emit('reload:battle')"
        />

        <template v-if="battle.permissions.registerButtonVisible">
          <BattleJoinOrRegister
            v-if="!isShowRegister && !invitationsToCrew.length"
            class="join-or-register"
            @join="scrollToCrews"
            @register="showRegister"
          />

          <BattleRegisterCrew
            v-if="isShowRegister"
            :battle="battle"
            :event="event"
            class="create"
            @checkIn="$emit('checkIn')"
            @update:battle="$emit('update:battle', $event)"
            @reload:battle="$emit('reload:battle')"
            @update:event="$emit('update:event', $event)"
            @reload:event="$emit('reload:event')"
          />
        </template>

        <BattleWinners
          v-if="battle.winners && !isShowWinnersEditor"
          :can-edit="event.permissions.canEdit && battle.style === 'classic'"
          :winners="battle.winners"
          class="winners-editor"
          @editWinners="isShowWinnersEditor = true"
        />

        <BattleWinnerEditorForCrews
          v-if="
            battle.style === 'classic' && event.permissions.canEdit && event.period === 'past' && isShowWinnersEditor
          "
          :battle="battle"
          :battle-winners="battle.winners || []"
          class="winners-editor"
          @cancelEditWinners="isShowWinnersEditor = false"
          @closeEditWinners="isShowWinnersEditor = false"
          @update:battle="$emit('update:battle', $event)"
        />

        <BattleCrews
          ref="crews"
          :battle="battle"
          :event="event"
          :is-admin="event.permissions.canEdit"
          @join="isShowRegister = true"
          @update:battle="$emit('update:battle', $event)"
          @reload:battle="$emit('reload:battle')"
          @update:event="$emit('update:event', $event)"
          @reload:event="$emit('reload:event')"
        />

        <BattleCreateCrewForm
          :battle="battle"
          :event="event"
          :is-show-form.sync="isShowForm"
          @update:battle="$emit('update:battle', $event)"
          @reload:battle="$emit('reload:battle')"
        />

        <div class="register-btn-wrap">
          <BKButton
            v-if="event.permissions.canEdit"
            white-text
            @click="isShowForm = true"
          >
            Register crew
          </BKButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BattleWinners from '@/components/Battle/BattleWinners.vue'
import BattleHeading from '@/components/Battle/BattleHeading.vue'
import BattleInvitations from '@/components/Battle/Info/BattleInvitations.vue'
import BattleJoinOrRegister from '@/components/Battle/Info/BattleJoinOrRegister.vue'
import BattleRegisterCrew from '@/components/Battle/Info/BattleRegisterCrew.vue'
import BattleCrews from '@/components/Battle/Info/BattleCrews.vue'
import BattleCreateCrewForm from '@/components/Battle/Info/BattleCreateCrewForm.vue'
import BattleWinnerEditorForCrews from '@/components/Battle/Info/BattleWinnersEditorForCrews.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'BattleCrew',
  components: {
    BattleWinners,
    BattleWinnerEditorForCrews,
    BattleHeading,
    BattleInvitations,
    BattleJoinOrRegister,
    BattleRegisterCrew,
    BattleCrews,
    BattleCreateCrewForm,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    battle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShowRegister: false,
      isShowForm: false,
      isShowWinnersEditor: !this.battle.winners,
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    invitationsToCrew() {
      if (!this.getUserDB) return []
      return this.battle.crews.filter((c) => !!c.participants.find((p) => p.id === this.getUserDB.id && !p.confirmed))
    },
  },
  mounted() {
    if (this.battle.permissions.registered) this.isShowRegister = true
  },
  methods: {
    scrollToCrews() {
      this.$nextTick(() => this.$refs.crews.$el.scrollIntoView())
    },
    showRegister() {
      if (!this.getUserDB) {
        this.$router.push({ name: 'Login' })
        return
      }

      this.isShowRegister = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.content {
  @include page-with-content;

  padding-top: 78px;
}

.join-or-register,
.create {
  max-width: 450px;
}

.join-or-register {
  padding: 0 20px;
}

.winners-editor {
  margin: 16px;
  padding: 16px;
}

.register-btn-wrap {
  padding: 20px 20px 0;
}
</style>
