<template>
  <article class="level">
    <span class="level-chip"> {{ level.name }}</span>

    <div class="level-counter">{{ level.rounds }} rounds</div>
  </article>
</template>

<script>
export default {
  name: 'BattleBracketsLevelChip',
  props: {
    level: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.level-chip {
  @include font-h3;

  display: block;
  width: 160px;
  margin-bottom: 12px;
  font-weight: var(--font-weight-bold);
}

.level-counter {
  padding: 16px 12px;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-700);
  border-radius: 6px;
}
</style>
