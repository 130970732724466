<script lang="ts" setup>
import { Organization } from '@/modules/Organizations/types'
import { useImage } from '@/shared/composables/image'

const { getOrgImage } = useImage()
defineProps<{
  org: Organization
}>()
</script>

<template>
  <v-card
    :to="{ name: 'Organization', params: { id: org.id } }"
    class="org"
  >
    <v-col
      class="d-flex align-center"
      cols="4"
    >
      <img
        :alt="org.name"
        :src="getOrgImage(org.image, 300)"
        class="image"
      />
      <span class="org-name">{{ org.name }}</span>
    </v-col>

    <v-col
      class="d-flex align-center"
      cols="1"
    >
      <span class="org-members">{{ org.membersCount }}</span>
    </v-col>
  </v-card>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.org {
  display: flex;
  width: 100%;
  margin: 14px 20px;
  background-color: var(--color-white);
  border-radius: var(--border-radius-rounded) !important;

  .image {
    width: 72px;
    height: 72px;
    margin-right: 12px;
  }
}

.org-name,
.org-members {
  display: block;
  margin-top: 8px;
  color: var(--color-dark-800);
  font-weight: var(--font-weight-bold);
  text-align: center;
}

.org-members {
  width: 100%;
}
</style>
