<template>
  <section class="orgs">
    <div class="info-row">
      <h1 class="orgs-title page-title">Organizations</h1>
      <v-btn
        class="create-btn"
        color="var(--color-white)"
        height="42"
        width="205"
        @click="createOrg"
      >
        <v-icon class="icon-plus">
          {{ mdiPlus }}
        </v-icon>
        Create Organization
      </v-btn>
    </div>

    <div
      v-if="currentUserOrgs.length"
      class="section-info-row"
    >
      <h3 class="section-title">My organizations</h3>
      <div class="view-switch">
        <v-icon
          :class="{ active: !isGridViewCurrentUserOrgs }"
          @click="isGridViewCurrentUserOrgs = false"
        >
          {{ mdiFormatListBulleted }}
        </v-icon>
        <v-icon
          :class="{ active: isGridViewCurrentUserOrgs }"
          @click="isGridViewCurrentUserOrgs = true"
        >
          {{ mdiViewGrid }}
        </v-icon>
      </div>
    </div>

    <div
      v-if="isGridViewCurrentUserOrgs"
      class="orgs-wrap"
    >
      <OrganizationsGridChip
        v-for="org in currentUserOrgs"
        :key="org.id"
        :org="org"
      />
    </div>

    <div
      v-if="!isGridViewCurrentUserOrgs"
      class="list-view-header"
    >
      <v-col cols="4"> Organization name</v-col>
      <v-col cols="2"> # of Members</v-col>
    </div>

    <div
      v-if="!isGridViewCurrentUserOrgs"
      class="orgs-wrap"
    >
      <OrganizationsListChip
        v-for="org in currentUserOrgs"
        :key="org.id"
        :org="org"
      />
    </div>

    <div class="section-info-row">
      <h3 class="section-title">All organizations</h3>
      <div class="view-switch">
        <v-icon
          :class="{ active: !isGridViewAllOrgs }"
          @click="isGridViewAllOrgs = false"
        >
          {{ mdiFormatListBulleted }}
        </v-icon>
        <v-icon
          :class="{ active: isGridViewAllOrgs }"
          @click="isGridViewAllOrgs = true"
        >
          {{ mdiViewGrid }}
        </v-icon>
      </div>
    </div>

    <div
      v-if="isGridViewAllOrgs"
      class="orgs-wrap"
    >
      <OrganizationsGridChip
        v-for="org in orgs"
        :key="org.id"
        :org="org"
      />
    </div>

    <div
      v-if="!isGridViewAllOrgs"
      class="list-view-header"
    >
      <v-col cols="4"> Organization name</v-col>
      <v-col cols="2"> # of Members</v-col>
    </div>

    <div
      v-if="!isGridViewAllOrgs"
      class="orgs-wrap"
    >
      <OrganizationsListChip
        v-for="org in orgs"
        :key="org.id"
        :org="org"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { mdiFormatListBulleted, mdiPlus, mdiViewGrid } from '@mdi/js'

import OrganizationsGridChip from '@/modules/Organizations/components/OrganizationsGridChip.vue'
import OrganizationsListChip from '@/modules/Organizations/components/OrganizationsListChip.vue'
import { createOrganization, fetchOrganizationsList } from '@/modules/Organizations/endpoints'
import { Organization } from '@/modules/Organizations/types'

const router = useRouter()
const route = useRoute()

const orgs = ref<Organization[]>([])
const currentUserOrgs = ref<Organization[]>([])
const isGridViewAllOrgs = ref(true)
const isGridViewCurrentUserOrgs = ref(true)

const getUserDB = computed(() => store.getters.getUserDB)

const createOrg = async () => {
  if (!getUserDB.value) {
    await router.push({
      name: 'Signup',
      query: { redirect: route.path },
    })
    return
  }

  const orgId = await createOrganization()
  if (orgId) {
    await router.push({ name: 'CreateOrganization', params: { id: orgId } })
  }
}

const fetchOrgs = async () => {
  const response = await fetchOrganizationsList({
    pageNumber: 0,
    pageSize: 100,
    userId: 0,
  })
  if (response) {
    orgs.value = response.items
  }
}
onMounted(() => {
  if (getUserDB.value) {
    currentUserOrgs.value = getUserDB.value.organizations
  }
  fetchOrgs()
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.orgs {
  padding: 140px 50px;
  background: var(--color-dark-900);
  background-position: top;
}

.info-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;

  .orgs-title {
    @include font-h1;

    color: var(--color-white);
    text-transform: uppercase;
  }

  .create-btn {
    font-weight: var(--font-weight-bold);

    .icon-plus {
      margin-right: 4px;
      margin-bottom: 2px;
    }
  }
}

.section-info-row {
  display: flex;
  justify-content: space-between;

  .view-switch .v-icon {
    margin-left: 12px;
    color: var(--color-white);
    opacity: 0.2;

    &:hover,
    &.active {
      opacity: 1;
    }
  }
}

.section-title {
  @include font-h3;

  color: var(--color-white);
}

.orgs-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}

.list-view-header {
  display: flex;
  color: var(--color-white);
}
</style>
