<template>
  <div class="error-page">
    <div class="error-page__content-wrapper">
      <h1 class="error-page__title">404</h1>
      <h2 class="error-page__subtitle">Page not found</h2>
      <p class="error-page__text">Sorry, we can’t find the page you’re looking for.</p>
      <router-link
        class="error-page__back-btn"
        to="/"
      >
        Back to home
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.error-page {
  display: flex;
  align-items: center;
  height: 100vh;
  background: #1b2432 url('/img/background2-home.jpg') 50% 100% / cover no-repeat;

  &__content-wrapper {
    z-index: 1;
    max-width: 335px;
    margin: 0 auto;
    color: var(--color-white);
    text-align: center;
  }

  &__title {
    font-size: 100px;
    line-height: 110px;
  }

  &__subtitle {
    @include font-h3;
  }

  &__text {
    margin: 16px 0;
  }

  &__back-btn {
    display: inline-block;
    min-width: 158px;
    height: 52px;
    padding: 14px 28px;
    color: var(--color-dark-800);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    background: var(--color-white);
    border-radius: 6px;
  }
}
</style>
