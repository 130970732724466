<template>
  <OnboardingFormLayout
    :current-step="currentStep"
    :error="error"
    :steps="steps"
    title="Username"
  >
    <template #default>
      <v-form
        v-model="valid"
        @submit.prevent="$emit('submitForm', userName)"
      >
        <v-text-field
          v-model="userName"
          :rules="[rules.required]"
          autocomplete="off"
          background-color="var(--color-white)"
          hide-details
          label="Dancer name, nickname, etc...*"
          outlined
        />
      </v-form>
    </template>
    <template #actions>
      <BKButton
        outlined
        @click="$emit('goBack')"
      >
        back
      </BKButton>

      <BKButton
        :disabled="loading || !valid"
        :loading="loading"
        white-text
        @click="$emit('submitForm', userName)"
      >
        next
      </BKButton>
    </template>
  </OnboardingFormLayout>
</template>

<script>
import validationRules from '@/mixins/validation'
import BKButton from '@/components/AppShared/BKButton.vue'
import OnboardingFormLayout from '@/components/Onboarding/OnboardingFormLayout.vue'

export default {
  components: {
    BKButton,
    OnboardingFormLayout,
  },
  mixins: [validationRules],
  props: {
    currentStep: {
      type: Number,
      default: 0,
    },
    error: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    steps: {
      type: Number,
      default: 0,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userName: '',
      valid: true,
    }
  },
}
</script>
