import { render, staticRenderFns } from "./BKModal.vue?vue&type=template&id=e8372192&scoped=true"
import script from "./BKModal.vue?vue&type=script&lang=ts&setup=true"
export * from "./BKModal.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./BKModal.vue?vue&type=style&index=0&id=e8372192&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8372192",
  null
  
)

export default component.exports