<template>
  <div class="event-sidebar">
    <div>
      <div class="event-sidebar-copy">
        <p class="paragraph event-title">
          {{ event.title }}
          <!--eslint-disable  vuejs-accessibility/click-events-have-key-events-->
          <span
            class="mobile caret"
            @click="handleMobileInfoToggle"
          >
            <v-icon v-if="isCollapsed">{{ mdiChevronUp }} </v-icon>
            <v-icon v-if="!isCollapsed">{{ mdiChevronDown }}</v-icon>
          </span>
          <!--eslint-enable  vuejs-accessibility/click-events-have-key-events-->
        </p>
        <div class="desktop">
          <p class="paragraph">
            <v-icon
              class="icon"
              color="black"
            >
              {{ mdiCalendarCheck }}
            </v-icon>
            {{ event.dateFlat || date }}
          </p>
          <p class="paragraph">
            <v-icon
              class="icon"
              color="black"
            >
              {{ mdiClockOutline }}
            </v-icon>
            {{ event.timeFlat || time }}
          </p>
          <p
            v-if="event.locationAddress"
            class="paragraph"
          >
            <v-icon
              class="icon"
              color="black"
            >
              {{ mdiMapMarkerRadius }}
            </v-icon>
            <a
              :href="`https://www.google.com/maps/search/${event.locationAddress}`"
              class="link"
              target="_blank"
              >{{ event.locationAddress }}</a
            >
          </p>
          <p
            v-if="event.website"
            class="paragraph"
          >
            <v-icon class="icon">
              {{ mdiLink }}
            </v-icon>
            <a
              :href="event.website"
              class="link"
              target="_blank"
            >
              Visit website to learn more</a
            >
          </p>
          <p
            v-if="totalTierCost !== 'Free'"
            class="paragraph"
          >
            <v-icon class="icon">
              {{ mdiCurrencyUsd }}
            </v-icon>
            {{ totalTierCost }}<br />
          </p>
        </div>
        <div
          v-if="!isCollapsed"
          class="mobile collapsible-content"
        >
          <p class="paragraph">
            <v-icon
              class="icon"
              color="black"
            >
              {{ mdiCalendarCheck }}
            </v-icon>
            {{ event.dateFlat || date }}
          </p>
          <p class="paragraph">
            <v-icon
              class="icon"
              color="black"
            >
              {{ mdiClockOutline }}
            </v-icon>
            {{ event.timeFlat || time }}
          </p>
          <p
            v-if="event.locationAddress"
            class="paragraph"
          >
            <v-icon
              class="icon"
              color="black"
            >
              {{ mdiMapMarkerRadius }}
            </v-icon>
            <a
              :href="`https://www.google.com/maps/search/${event.locationAddress}`"
              class="link"
              target="_blank"
              >{{ event.locationAddress }}</a
            >
          </p>
          <p
            v-if="event.website"
            class="paragraph"
          >
            <v-icon class="icon">
              {{ mdiLink }}
            </v-icon>
            <a
              :href="event.website"
              class="link"
              target="_blank"
            >
              Visit website to learn more</a
            >
          </p>
          <p
            v-if="totalTierCost !== 'Free'"
            class="paragraph"
          >
            <v-icon class="icon">
              {{ mdiCurrencyUsd }}
            </v-icon>
            {{ totalTierCost }}<br />
          </p>
        </div>
      </div>
      <div>
        <v-btn
          v-if="event.permissions.canEdit"
          block
          class="white--text d-flex justify-space-between"
          color="var(--color-blue-800)"
          height="48"
          tile
          @click="editEvent"
        >
          edit event
          <v-icon right>
            {{ mdiPencilOutline }}
          </v-icon>
        </v-btn>

        <div class="mt-3">
          <BKCheckinButton
            :event="event"
            @purchase="$emit('reload:event')"
            @update:event="$emit('update:event', $event)"
          />
        </div>

        <v-btn
          v-if="event.permissions.canEdit"
          :to="{ name: 'Checkin', params: { idEvent: event.id } }"
          block
          class="mt-3 mb-4 white--text font-weight-bold"
          color="var(--color-green)"
          height="48"
          tile
        >
          Tickets and check-ins
          <v-icon
            class="ml-auto"
            color="white"
            size="28"
          >
            {{ mdiCheckCircle }}
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import BKCheckinButton from '@/components/BKCheckinButton.vue'

import { mapGetters } from 'vuex'

import { getDateRangeFormatted, getTimeRangeFormatted } from '@/services/date'
import {
  mdiCalendarCheck,
  mdiCheckCircle,
  mdiChevronDown,
  mdiChevronUp,
  mdiClockOutline,
  mdiCurrencyUsd,
  mdiLink,
  mdiMapMarkerRadius,
  mdiPencilOutline,
} from '@mdi/js'

export default {
  name: 'EventSidebar',
  components: {
    BKCheckinButton,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mdiPencilOutline,
      mdiLink,
      mdiCurrencyUsd,
      mdiClockOutline,
      mdiCheckCircle,
      mdiCalendarCheck,
      mdiChevronDown,
      mdiChevronUp,
      mdiMapMarkerRadius,
      isCollapsed: true,
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    date() {
      return getDateRangeFormatted(this.event.dateStart, this.event.dateEnd, false)
    },
    time() {
      return getTimeRangeFormatted(this.event.dateStart, this.event.dateEnd)
    },
    totalTierCost() {
      if (!this.event.tiers.length) return 'Free'
      const costs = this.event.tiers.map((tier) => tier.cost)
      const min = Math.min(...costs)
      const max = Math.max(...costs)
      if (min === Infinity || max === Infinity) return ' '
      if (max === 0) {
        return 'Free'
      }
      if (min === max) return `$${max}`

      return `$${min} - $${max}`
    },
  },
  methods: {
    editEvent() {
      this.$router.push({ name: 'CreateEventProcessing', params: { id: this.event.id } })
    },
    handleMobileInfoToggle() {
      this.isCollapsed = !this.isCollapsed
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.event-sidebar {
  position: relative;
  width: 100%;
  max-width: 340px;
  padding-left: 40px;

  .mobile {
    display: none;
  }

  @media (max-width: 920px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }

    width: 90%;
    max-width: 100%;
    margin: 30px auto 0;
    padding-left: 0;
  }
}

.event-sidebar-copy {
  position: relative;
}

.caret {
  display: none;

  .v-icon {
    color: white;
  }

  @media (max-width: 920px) {
    display: block;

    .v-icon {
      font-size: 40px;
    }
  }
}

.paragraph {
  position: relative;
  min-height: 24px;
  padding-left: 30px;
  color: var(--color-white);

  &.event-title {
    @include font-h2;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0;
    font-weight: var(--font-weight-bold);

    @media (max-width: 600px) {
      @include font-h4;
    }
  }

  .icon {
    position: absolute;
    left: 0;
    color: white !important;
    caret-color: white !important;
  }

  a {
    color: white !important;
  }
}

.link {
  text-decoration: none;
}

.checked-text,
.checked-text .v-icon {
  color: var(--color-green);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
}

.ticket-btn-text {
  @include font-h4;

  display: flex !important;
  justify-content: space-between !important;
  width: 100%;
}

.pay-checkbox {
  padding: 5px 16px 0;
  background-color: #eee;
}
</style>
