// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ImageActions } from '@/services/image'
import { CountryFlag } from '@/shared/types/countryFlag'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { imgApi } from '@/plugins/axios'
import flags from '../../../public/flags/data.json'

const defaultImg = {
  avatar: '/img/default-avatar.png',
  card: '/img/profile/default-avatar.svg',
  event: '/img/org-default.svg',
  flag: '/img/flag-earth.png',
  org: '/img/org-default.svg',
}

export function useImage() {
  function getImageById(
    id: string,
    width: number,
    height: number,
    type: 'avatar' | 'card' | 'event' | 'org' | 'flag' = 'avatar'
  ) {
    if (!id) return defaultImg[type]
    return `${process.env.VUE_APP_IMG_URL}/${id}/${width}/${height}`
  }

  function getAvatar(id: string, size: number) {
    return ImageActions.getImageById(id, size, size, 'avatar')
  }

  function getEventImg(id: string, size: number) {
    return ImageActions.getImageById(id, size, size, 'event')
  }

  function getOrgImage(id: string, size: number) {
    return ImageActions.getImageById(id, size, size, 'org')
  }

  function getCard(id: string, size: number) {
    return ImageActions.getImageById(id, size, size, 'card')
  }

  function getFlag(countryName: string) {
    const countryFlag = flags.find((flag: CountryFlag) => flag.name === countryName)
    if (!countryFlag) return defaultImg.flag
    return countryFlag.flag
  }

  async function saveImage(file: File) {
    if (file.size > 10000000) {
      store.commit('SHOW_ALERT_TOAST_MESSAGE', "Use an image smaller than 10Mb, otherwise, it won't be saved.")
      setTimeout(() => store.commit('SHOW_ALERT_TOAST_MESSAGE', ''), 5000)
      return
    }
    const formData = new FormData()
    formData.append('file', file)

    const { data } = await imgApi.post('/', formData)

    // eslint-disable-next-line consistent-return
    return data
  }

  return {
    getAvatar,
    getEventImg,
    getOrgImage,
    getImageById,
    saveImage,
    getCard,
    getFlag,
  }
}
