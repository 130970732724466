<template>
  <div class="sort">
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <div
          class="sort-string"
          v-on="on"
        >
          Order by: {{ selectedSortOption.name }}
        </div>
      </template>

      <v-list
        class="sort-menu"
        width="242"
      >
        <v-list-item>
          <span class="sort-title">Sort by</span>
        </v-list-item>

        <v-radio-group
          v-model="selectedSortOption.value"
          class="mt-0"
        >
          <v-list-item
            v-for="option in sortOptions"
            :key="option.value"
            class="d-flex justify-space-between"
            @click="selectedSortOption = Object.assign({}, option)"
          >
            <span>{{ option.name }}</span>
            <div class="radio-wrap">
              <v-radio
                :value="option.value"
                class="mr-0"
                color="var(--color-blue-800)"
              />
            </div>
          </v-list-item>
        </v-radio-group>
      </v-list>
    </v-menu>

    <button
      class="sort-arrows"
      type="button"
      @click="sortDirection = !sortDirection"
    >
      <v-icon class="icon-up">
        {{ mdiChevronUp }}
      </v-icon>
      <v-icon class="icon-down">
        {{ mdiChevronDown }}
      </v-icon>
    </button>
  </div>
</template>

<script>
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'

export default {
  name: 'BKSort',
  props: {
    sortOptions: {
      type: Array,
      required: true,
    },
    defaultSortBy: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      mdiChevronUp,
      mdiChevronDown,
      selectedSortOption: { name: '', value: '' },
      sortDirection: false,
    }
  },
  computed: {
    sortByPreset() {
      const sortDirectionName = this.sortDirection ? 'desc' : 'asc'
      return this.selectedSortOption.value ? `${this.selectedSortOption.value}-${sortDirectionName}` : ''
    },
  },
  watch: {
    sortByPreset() {
      this.$emit('update', this.sortByPreset)
    },
  },
  mounted() {
    if (!this.defaultSortBy) return

    const defaultOption = this.sortOptions.find((o) => o.value === this.defaultSortBy)
    if (!defaultOption) return

    this.selectedSortOption = defaultOption
  },
}
</script>

<style lang="scss" scoped>
.sort {
  display: flex;
  margin-left: 24px;
}

.sort-string {
  cursor: pointer;
}

.sort-menu {
  .sort-title {
    font-weight: var(--font-weight-bold);
  }
}

.sort-arrows {
  display: flex;
  flex-direction: column;

  .icon-up {
    top: -6px;
  }

  .icon-down {
    top: -12px;
  }
}

.radio-wrap {
  margin-left: auto;
}
</style>
