<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { mdiArrowLeft } from '@mdi/js'
import BKCountrySelect from '@/components/AppShared/BKCountrySelect.vue'
import { useImage } from '@/shared/composables/image'
import { UserDB } from '@/enitites/user'

interface IProps {
  loading: boolean
  user: UserDB
}

const props = defineProps<IProps>()
const { getImageById, saveImage } = useImage()

const emit = defineEmits<{
  (e: 'update:user', value: UserDB): void
  (e: 'save'): void
}>()

const isFormValid = ref(false)
const isShowState = ref(false)
const mask = ref('##/##/####')

const dancerNameRules = [(value: string) => !!value || 'Dancer name is required']
const fullNameRules = [(value: string) => !!value || 'Full name is required']
const birthdayRules = (value: string) => {
  const isMoreThanHundredYears = new Date().getFullYear() - new Date(value).getFullYear() > 100
  const isInFutureOrNow = new Date().getFullYear() <= new Date(value).getFullYear()

  if (isMoreThanHundredYears || isInFutureOrNow) return 'Please enter a correct date'

  const pattern = /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/
  return pattern.test(value) || 'Date format mm/dd/yyyy'
}

const getUser = computed({
  get: () => props.user,
  set: (value) => emit('update:user', value),
})

watch(
  () => getUser.value.country,
  () => {
    getUser.value.city = ''
    getUser.value.state = ''
  }
)

const handlerFileChange = (evt: Event) => {
  const filesImgAvatar = (evt.target as HTMLInputElement).files
  if (!filesImgAvatar || filesImgAvatar.length === 0) return

  const file = filesImgAvatar[0]
  if (file.size > 10000000) {
    store.dispatch('showAlertToast', 'Use an image smaller than 10Mb')
    isFormValid.value = false
    return
  }

  const reader = new FileReader()
  reader.onload = async () => {
    getUser.value.avatar = await saveImage(file)
  }
  reader.readAsDataURL(file)
  isFormValid.value = true
}
</script>

<template>
  <v-form
    v-model="isFormValid"
    class="d-flex flex-column justify-space-between h100"
  >
    <div>
      <v-row
        class="profile"
        justify="start"
        no-gutters
      >
        <v-col
          class="mb-4"
          cols="12"
        >
          <button
            class="back-button"
            type="button"
            @click="store.commit('TOGGLE_MENU_MOBILE')"
          >
            <v-icon v-show="$vuetify.breakpoint.xsOnly">
              {{ mdiArrowLeft }}
            </v-icon>
            Profile
          </button>
        </v-col>
        <v-col
          class="mb-4"
          cols="12"
        >
          <v-avatar size="80">
            <v-img
              :src="getImageById(getUser.avatar, 200, 200, 'avatar')"
              alt="avatar"
            />
          </v-avatar>
          <v-btn
            class="profile__btn-edit-photo text-none ml-2"
            for="profileUserImage"
            tag="label"
            text
          >
            Edit Photo
            <!--          eslint-disable vuejs-accessibility/form-control-has-label-->
            <input
              id="profileUserImage"
              accept="image/png, image/jpeg"
              name="userImage"
              style="display: none"
              type="file"
              @change="handlerFileChange"
            />
            <!--          eslint-enable vuejs-accessibility/form-control-has-label-->
          </v-btn>
          <span class="prompt">* Use an image smaller than 10Mb</span>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="getUser.dancerName"
            :rules="dancerNameRules"
            autocomplete="off"
            background-color="#fff"
            dense
            label="Dancer Name"
            outlined
            required
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="getUser.fullName"
            :rules="fullNameRules"
            autocomplete="off"
            background-color="#fff"
            dense
            label="Full Name"
            outlined
            required
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="getUser.birthday"
            v-mask="mask"
            :rules="[birthdayRules]"
            autocomplete="off"
            background-color="#fff"
            class="profile__birthday"
            dense
            label="Birthday"
            outlined
            required
          />
        </v-col>
        <v-col cols="12">
          <BKCountrySelect
            :country.sync="getUser.country"
            :dense="true"
            :is-show-state.sync="isShowState"
          />
        </v-col>
        <v-col cols="12">
          <div class="d-flex">
            <v-text-field
              v-if="isShowState"
              v-model="getUser.state"
              autocomplete="off"
              background-color="#fff"
              class="profile__state flex-shrink-0 mr-2"
              dense
              label="State"
              outlined
            />
            <v-text-field
              v-model="getUser.city"
              autocomplete="off"
              background-color="#fff"
              class="profile__city"
              dense
              label="City"
              outlined
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-end">
      <v-btn
        :disabled="!isFormValid"
        :loading="loading"
        class="profile__btn-save"
        color="var(--color-blue-800)"
        height="43"
        outlined
        tile
        @click="emit('save')"
      >
        save
      </v-btn>
    </div>
  </v-form>
</template>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.profile {
  .back-button {
    color: var(--color-dark-800);
    font-weight: var(--font-weight-medium);

    @include font-h3;
  }

  &__btn-edit-photo ::v-deep .v-btn__content {
    color: var(--color-blue-800);
    font-weight: var(--font-weight-bold);
    cursor: pointer;

    @include font-body1;
  }

  &__btn-save {
    width: 180px;
    font-weight: var(--font-weight-bold);
    border: 2px solid;

    @media screen and (max-width: 600px) {
      width: 100%;
      margin: 8px 0;
    }
  }

  &__birthday {
    width: 50%;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  &__state {
    width: 20%;
  }

  &__city {
    width: 70%;
  }

  .prompt {
    @include font-caption2;

    display: flex;
    margin-top: 4px;
    color: var(--color-grey-300);
  }
}
</style>
