<template>
  <!--eslint-disable  vuejs-accessibility/click-events-have-key-events-->
  <div
    :class="{ registered: battle.isRegistered, register: !battle.isRegistered }"
    class="battle"
    @click="$emit('click')"
  >
    <div class="battle-details">
      <p class="battle-title">
        {{ battle.title }}
      </p>
      <span class="type">{{ getBattleCategory(battle.crewSize) }}</span>
    </div>
    <div class="battle-status">
      <!-- figure out how to check invited?? -->
      <span
        v-if="battle.isInvited"
        class="invited"
        >Invited to battle</span
      >
      <span
        v-else-if="battle.isRegistered"
        class="registered"
        >Registered</span
      >
      <span
        v-else-if="!battle.isRegistered"
        class="register"
        >Register</span
      >
      <BattleOrgInfo
        v-if="battle.orgChip && battle.orgChip.id"
        :chip="battle.orgChip"
      />
    </div>
  </div>
  <!--eslint-enable  vuejs-accessibility/click-events-have-key-events-->
</template>

<script>
import Battles from '@/mixins/battle'
import BattleOrgInfo from '@/components/Battle/BattleOrgInfo.vue'

export default {
  name: 'EventBattlesBattleChip',
  components: {
    BattleOrgInfo,
  },
  mixins: [Battles],
  props: {
    battle: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.battle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 12px;
  color: var(--color-dark-800);
  background: var(--color-white);
  border-radius: 6px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 20%);

  .battle-status {
    display: flex;
    align-items: center;

    &::v-deep {
      .battle-org-info {
        position: relative;
        right: unset;
        bottom: unset;
        margin-left: 12px;
      }
    }
  }

  &.registered {
    color: var(--color-white);
    background: var(--color-green);
  }

  &.register {
    .battle-status {
      color: var(--color-green);
    }
  }

  .battle-title {
    @include font-h4;

    margin-bottom: 0;
    font-weight: var(--font-weight-bold);
    text-align: left;
    word-break: break-all;
  }

  .type {
    @include font-body2;

    text-align: left;
  }

  .btn {
    position: absolute;
    top: 15px;
    right: 15px;
    border: var(--color-white) 2px solid;
  }
}
</style>
