<template>
  <ModalWrapper
    :is-show="isShowModal"
    class="dialog"
    @update:isShow="updateShowModal($event)"
  >
    <v-progress-circular
      v-if="!battle"
      class="loader"
      color="#FC7800"
      indeterminate
      size="200"
      width="15"
    />
    <v-card
      v-else
      class="dialog__card"
      outlined
    >
      <div class="dialog__content">
        <h3 class="dialog__title">Battle details</h3>
        <BKOrganizationSelect
          :orgs="getUserDB.organizations"
          :selected-org.sync="battle.orgId"
          class="my-4"
        />
        <p class="dialog__subtitle">Category type</p>
        <v-text-field
          v-model="battle.title"
          autocomplete="off"
          background-color="var(--color-white)"
          class="dialog__text-field"
          label="Title"
          outlined
        />
        <v-select
          v-model="battle.crewSize"
          :items="categories"
          class="dialog__select"
          outlined
        />
        <VueEditor
          v-model="battle.description"
          :editor-toolbar="editorConfig"
          class="editor"
          label="Give a description of the battle (optional)"
        />
        <p class="dialog__subtitle">Registration, check-in cap</p>
        <v-text-field
          v-model.number="battle.limit"
          autocomplete="off"
          background-color="var(--color-white)"
          class="dialog__text-field"
          label="Enter number"
          min="0"
          outlined
          type="number"
        />
        <p class="description">
          Once this number of checked-in competitors is reached, new entries will be put in a waitlist. If a crew or
          competitor is unregistered from the battle, next inline will be entered into prelims.
        </p>

        <p class="dialog__subtitle">Judging system</p>

        <v-select
          v-model="battle.style"
          :items="battleStyles"
          class="dialog__select"
          label="Select judging system"
          outlined
        />

        <template v-if="battle.style !== 'classic'">
          <span>Bracket size</span>
          <v-select
            v-model="battle.bracketSize"
            :items="bracketSizes"
            class="dialog__select"
            outlined
            @change="setLevels"
          />

          <span>Rounds</span>
          <v-select
            v-for="level in battle.bracketLevels"
            :key="level.name"
            v-model="level.rounds"
            :items="availableRounds"
            :label="level.name"
            class="dialog__select"
            outlined
          />
        </template>

        <p class="dialog__subtitle">Judging</p>

        <BKUserSelect
          :role="`judge`"
          :select-user-chip.sync="battle.judges"
          label-text="Find and add judges"
          @removeUser="removeBattleRole"
          @selectUser="addBattleRole"
        />
        <p class="dialog__subtitle">Prizes</p>
        <v-text-field
          v-model="battle.prizesDesc"
          autocomplete="off"
          background-color="var(--color-white)"
          class="dialog__text-field"
          label="Prizes descriptions if needed"
          outlined
        />
        <div
          v-for="(place, i) in battle.prizes"
          :key="i"
          class="dialog__place my-2 d-flex align-center justify-center"
        >
          <div class="dialog__wrap-place text-center flex-shrink-0">
            <v-icon
              v-if="i === 0 || i === 1"
              :class="i === 0 ? 'amber--text' : 'grey--text'"
            >
              {{ mdiTrophyOutline }}
            </v-icon>
            <span class="mx-2">{{ place.title }}</span>
          </div>
          <v-text-field
            v-model.number="place.amount"
            class="mb-n8 dialog__amount"
            label="Cash Prize"
            min="0"
            outlined
            prefix="$"
            type="number"
            @input="setPlacePrize(place.id)"
          />
        </div>
        <div class="dialog__place my-2 d-flex align-center justify-end">
          <v-btn
            v-if="isShowBtnAddPlace"
            class="dialog__btn-add mr-auto"
            color="var(--color-blue-800)"
            text
            tile
            @click.stop="addPlaceToBattle"
          >
            Add Place
            <v-icon class>
              {{ mdiPlus }}
            </v-icon>
          </v-btn>
          <v-btn
            v-if="battle.prizes.length && battle.prizes.length !== 1"
            class="dialog__btn-remove"
            color="red"
            icon
            text
            @click.stop="handlerClickDeletePlace"
          >
            <v-icon>
              {{ mdiMinusCircleOutline }}
            </v-icon>
          </v-btn>
        </div>
        <p
          v-show="error"
          class="red--text text-center"
        >
          {{ error }}
        </p>
        <v-card-actions>
          <v-btn
            v-if="isEditMode"
            :disabled="createBtnLoading"
            :loading="createBtnLoading"
            class="dialog__btn-create px-8 mt-10 mx-auto"
            color="var(--color-blue-800)"
            height="45"
            outlined
            tile
            @click.stop="updateBattle"
          >
            Publish Battle
          </v-btn>
          <v-btn
            v-else
            :disabled="createBtnLoading"
            :loading="createBtnLoading"
            class="dialog__btn-create px-8 mt-10 mx-auto"
            color="var(--color-blue-800)"
            height="45"
            outlined
            tile
            @click.stop="updateBattle"
          >
            Create Battle
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </ModalWrapper>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
import { BATTLE_CATEGORIES, BATTLE_PLACES } from '@/common/constants'
import { mdiMinusCircleOutline, mdiPlus, mdiTrophyOutline } from '@mdi/js'

import BKUserSelect from '@/components/BKUserSelect.vue'
import { ModalWrapper } from '@/shared/ui/modals'
import BKOrganizationSelect from '@/components/BKOrganizationSelect.vue'
import { getDateFormattedForPicker } from '@/services/date'
import { VueEditor } from 'vue2-editor'
import BKEditorConfig from '../common/vue2Config'

export default {
  components: {
    BKUserSelect,
    ModalWrapper,
    BKOrganizationSelect,
    VueEditor,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    battleId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      mdiMinusCircleOutline,
      mdiTrophyOutline,
      mdiPlus,
      battle: null,
      categories: BATTLE_CATEGORIES,
      existingPlaces: BATTLE_PLACES,
      createBtnLoading: false,
      battleStyles: ['bracket', 'classic'],
      indexCurrentBattleForEdit: null,
      loadingNextBtn: false,
      error: null,
      sameDateAnswer: 'yes',
      isShowDateStart: false,
      dateForPicker: null,
      bracketSizes: [32, 16, 8, 4, 2],
      availableRounds: Array.from(Array(101).keys()).map((n) => n + 1),
      levels: [],
      editorConfig: BKEditorConfig,
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    isShowBtnAddPlace() {
      return this.battle.prizes.length !== this.existingPlaces.length // скрыть кнопку добавления
    },
  },
  async mounted() {
    this.battle = await api.battles.getBattle(this.battleId)

    this.dateForPicker = getDateFormattedForPicker(this.battle.startDate)

    if (!this.battle.prizes.length) this.addPlaceToBattle()

    if (!this.battle.bracketLevels.length) {
      this.setLevels()
    }
  },

  methods: {
    async updateBattle() {
      this.createBtnLoading = true
      this.battle.startDate = new Date(this.dateForPicker.replace(/-/g, '/'))
      this.$emit('updateBattle', this.battle)
      this.createBtnLoading = false
      this.$emit('update:isShowModal', false)
    },
    addPlaceToBattle() {
      this.battle.prizes = [
        ...this.battle.prizes,
        {
          id: this.existingPlaces[this.battle.prizes.length].id,
          title: this.existingPlaces[this.battle.prizes.length].title,
          amount: 0,
        },
      ]
    },
    setLevels() {
      const levelsMax = this.bracketSizes
        .slice()
        .reverse()
        .findIndex((s) => s === this.battle.bracketSize)
      this.battle.bracketLevels = []

      if (this.battle.bracketSize === 2) {
        this.battle.bracketLevels.push({
          name: 'Final',
          rounds: 1,
        })

        return
      }

      for (let i = 0; i < levelsMax; i++) {
        const level = {
          name:
            i === this.battle.bracketLevels.length - 1
              ? 'Semi-final'
              : `Top ${this.bracketSizes.filter((size) => size <= this.battle.bracketSize)[i]}`,
          rounds: 1,
        }

        this.battle.bracketLevels.push(level)
      }

      this.battle.bracketLevels.push({
        name: 'Final',
        rounds: 1,
      })
    },
    handlerClickDeletePlace() {
      this.battle.prizes.pop()
    },
    updateShowModal(evt) {
      this.$emit('update:isShowModal', evt)
    },
    async addBattleRole(user, role) {
      const updatedRoles = await api.battles.addRole(this.battle.id, user.id, role)
      if (updatedRoles) this.battle.judges = updatedRoles
    },
    async removeBattleRole(user, role) {
      const updatedRoles = await api.battles.removeRole(this.battle.id, user.id, role)
      if (updatedRoles) this.battle.judges = updatedRoles
    },
    setPlacePrize(placeId) {
      const place = this.battle.prizes.find((placeItem) => placeItem.id === placeId)
      place.amount = Math.abs(place.amount)
      if (place.amount > 0 && place.amount < 1) place.amount = 1
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/navigationEventCreate';
@import '@/assets/style/mixins';

.loader {
  display: flex !important;
  margin: 210px auto;
}

.hidden {
  visibility: hidden;
}

.show {
  visibility: visible;
}

.editor {
  margin-bottom: 20px;
}

.wrap {
  max-width: 400px;
  margin: 0 auto;
}

.description {
  @include font-body2;

  color: var(--color-grey-300);
}

.dialog {
  &__close {
    position: absolute;
    z-index: 1;
    width: auto;
    margin-top: -30px;
    text-transform: none;
  }

  &__content {
    padding: 40px 70px;

    @media (max-width: 600px) {
      padding: 100px 20px 20px;
    }
  }

  &__card {
    position: relative;
  }

  &__title {
    @include font-h2;

    text-transform: uppercase;
  }

  &__subtitle {
    margin: 10px 0 8px;
    color: var(--color-dark-800);
  }

  &__text-field {
    margin-bottom: -20px !important;

    :deep(input) {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin: 0;
        appearance: none;
      }

      /* Firefox */
      &[type='number'] {
        appearance: textfield;
      }
    }
  }

  &__select {
    margin-bottom: -20px !important;
  }

  &__place {
    position: relative;
    width: 250px;
  }

  &__wrap-place {
    width: 120px;
  }

  &__btn-create {
    font-weight: bold;
    border: 2px solid;
  }

  &__btn-add {
    font-weight: bold;
  }

  &__btn-remove {
    position: absolute;
    top: -60px;
    right: -50px;
  }
}
</style>
