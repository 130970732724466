<script setup>
import { computed, onMounted, ref } from 'vue'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router/composables'
import { getAuth, isSignInWithEmailLink, onAuthStateChanged, signInWithEmailLink } from 'firebase/auth'

import BKLoader from '@/components/BKLoader.vue'
import { userApi } from '@/enitites/user'
import { TheFooter } from '@/widgets/Footer'
import { TheHeader } from '@/widgets/Header'
import TargetPage from './pages/TargetPage.vue'

const route = useRoute()
const router = useRouter()
const auth = getAuth()

const swRefreshing = ref(false)
const swRegistration = ref(null)
const swUpdateExists = ref(false)
const error = ref(null)
const loading = ref(true)
const userEmail = ref('')
const ROUTES_WITHOUT_FOOTER = ['EventsMap', 'EventsCalendar']

const getUserDB = computed(() => store.getters.getUserDB)
const isShowFooter = computed(
  () => (route.name === 'EventsCalendar' && window.innerWidth >= 960) || !ROUTES_WITHOUT_FOOTER.includes(route.name)
)

const setUserFB = (userFB) => store.dispatch('setUserFB', userFB)
const setUserDB = (userDB) => store.dispatch('setUserDB', userDB)
const refreshApp = () => {
  swUpdateExists.value = false
  if (!swRegistration.value || !swRegistration.value.waiting) return
  swRegistration.value.waiting.postMessage({ type: 'SKIP_WAITING' })
}

const updateAvailable = (event) => {
  swRegistration.value = event.detail
  swUpdateExists.value = true
  refreshApp()
}

onMounted(async () => {
  document.addEventListener('swUpdated', updateAvailable, { once: true })

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (swRefreshing.value) return
      swRefreshing.value = true
      window.location.reload()
    })
  }

  if (!getUserDB.value && isSignInWithEmailLink(auth, window.location.href)) {
    userEmail.value = window.localStorage.getItem('emailForMagicLink')
    try {
      await signInWithEmailLink(auth, userEmail.value, window.location.href)
      window.localStorage.removeItem('emailForMagicLink')
    } catch (error) {
      console.error(error)
    } finally {
      router.push({ name: 'Home' })
    }
  }

  onAuthStateChanged(auth, async (userFB) => {
    if (userFB === null) {
      loading.value = false
      await setUserDB(null)
      return
    }

    await setUserFB(userFB)
    loading.value = true

    const userDB = await userApi.getCurrentUser()
    loading.value = false
    if (!userDB) return

    await setUserDB(userDB)

    if (route.name === 'Login' || (route.name === 'Signup' && userDB.dancerName)) {
      await router.push(route.query.redirect || { name: 'Home' })
    }

    if (route.name !== 'Signup' && !userDB.dancerName) {
      await router.push({
        name: 'Signup',
        query: {
          incomplete: true,
        },
      })
    }

    /* TODO: Need save Target page logic
    if (this.getTargetPage !== null) {
      const targetPage = this.getTargetPage
      this.setTargetPage(null)
      this.loading = false
      if (targetPage !== this.$route.fullPath) {
        this.$router.push(targetPage)
        return
      }
      return
    } */
  })
})
</script>

<template>
  <div class="wrapp">
    <BKLoader v-if="loading" />
    <div
      v-else
      id="app"
    >
      <TheHeader />
      <TargetPage />
      <TheFooter v-if="isShowFooter" />
    </div>
    <p
      v-if="error"
      class="er"
    >
      Something went wrong, please try again later
    </p>
  </div>
</template>

<style lang="scss">
@import '@/assets/style/global';
@import '@/assets/style/variables';

a {
  color: inherit;
  text-decoration: none;
}

ul,
li {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 100%;
  vertical-align: baseline;
  list-style-type: none;
  background: transparent;
  border: 0;
}

button {
  padding: 0;
  color: inherit;
  font: inherit;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  display: flex;
  box-sizing: border-box;
  min-height: 100%;
  color: var(--color-grey-900);
  font-size: 16px;
  font-family: Roboto, Helvetica, sans-serif;
  line-height: 1.5;
  letter-spacing: 0.04em;
  background-color: var(--color-black);
  cursor: default;

  &.no-scroll {
    overflow: hidden;
  }
}

@supports (selector(:has(*))) {
  html:has(.header__menu--active) {
    overflow: hidden;
  }
}

body {
  width: 100%;
  max-width: 2720px;
  margin: 0 auto;
  color: var(--color-grey-900);
  background: var(--color-white);
}

// move icont rotation to the aotucomplete
.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0) !important;
}

#app {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 2720px;
  margin: 0 auto;
  padding: 0;
  background: transparent;
}

.wrapp {
  background-color: var(--color-black);
}

.er {
  padding: 20px;
  color: var(--color-white);
}
</style>
