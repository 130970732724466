export default function throttle(func: (...args: unknown[]) => unknown, timeout = 100) {
  let timer: number
  let lastArgs: unknown[]

  return (...args: unknown[]) => {
    lastArgs = args
    if (timer) return
    timer = setTimeout(() => {
      timer = 0
      func(lastArgs)
    }, timeout)
  }
}
