<script lang="ts" setup>
import { mdiCrownOutline } from '@mdi/js'

const props = defineProps<{
  eventsAttended?: number | null
}>()

const badgesList = [
  {
    id: 0,
    count: 1,
    color: '#db97ff',
  },
  {
    id: 1,
    count: 10,
    color: '#ff97d7',
  },
  {
    id: 2,
    count: 40,
    color: '#ffd297',
  },
  {
    id: 3,
    count: 80,
    color: '#97ffdc',
  },
  {
    id: 4,
    count: 100,
    color: '#8c7328',
  },
]

function getOpacity(count: number) {
  if (!props.eventsAttended) return 0.3
  return props.eventsAttended < count ? 0.3 : 1
}
</script>

<template>
  <section class="badges">
    <div
      v-for="badge in badgesList"
      :key="badge.id"
      :style="{ backgroundColor: badge.color, opacity: getOpacity(badge.count) }"
      class="badge"
    >
      <div class="content">
        <v-icon
          v-if="badge.count === 100"
          class="icon"
        >
          {{ mdiCrownOutline }}
        </v-icon>
        <p class="count">{{ badge.count }}</p>
        <p v-if="badge.count === 1">
          First Event <br />
          Attended
        </p>
        <p v-else>
          Events <br />
          attended
        </p>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.badges {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .badge {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 164px;
    height: 164px;
    margin: 15px;
    color: var(--color-white);
    text-align: center;
    background-image: url('/img/spica.svg');
    background-position: center;
    background-size: 90%;
    border-radius: 164px;

    .count {
      margin-bottom: 0;
      font-weight: bold;
      font-size: 40px;
      line-height: 40px;
    }

    .icon {
      color: white;
      font-size: 60px;
    }
  }
}

// TODO: rewrite later
@media (max-width: 1263px) {
  .badges {
    justify-content: flex-start;
  }
}

@media (max-width: 452px) {
  .badge {
    width: 140px;
    height: 140px;
    margin: 5px;
  }

  .badge .badge__count {
    line-height: 30px;
  }
}
</style>
