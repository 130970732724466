import { callPrivateApi } from '@/api/apiHelper'
import { initBattleResp } from '@/api/adapter'

export const registerCrew = async ({ eventId, battleId, name, participants }) => {
  const payload = { eventId, name, userIds: participants.map((par) => par.id) }
  const data = await callPrivateApi('post', `/crews/${battleId}`, payload)
  return initBattleResp(data)
}

export const unregister = async (battleId, crewId) => {
  const data = await callPrivateApi('post', `/crews/${battleId}/${crewId}/unregister`)
  return initBattleResp(data)
}

export const updateCrew = async ({ eventId, battleId, name, crewId, participants }) => {
  const payload = { eventId, name, userIds: participants.map((par) => par.id) }
  const data = await callPrivateApi('put', `/crews/${battleId}/${crewId}`, payload)
  return initBattleResp(data)
}

export const joinToCrew = async (battleId, crewId) => {
  const data = await callPrivateApi('post', `/crews/${battleId}/${crewId}/join`)
  return initBattleResp(data)
}

export const acceptInvitation = async (battleId, crewId) => {
  const data = await callPrivateApi('post', `/crews/${battleId}/${crewId}/accept`)
  return initBattleResp(data)
}

export const declineInvitation = async (battleId, crewId) => {
  const data = await callPrivateApi('post', `/crews/${battleId}/${crewId}/decline`)
  return initBattleResp(data)
}
