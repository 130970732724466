<script lang="ts" setup>
import { computed, defineEmits, defineProps, ref } from 'vue'
import api from '@/api'
import { EVENT_STATUSES } from '@/common/constants'
import { mdiCheckCircleOutline, mdiClose, mdiDotsHorizontal } from '@mdi/js'
import { getDateFormatted } from '@/services/date'
import { useImage } from '@/shared/composables/image'
import { IEventPreview } from '@/types/event'

const { getEventImg } = useImage()

const props = defineProps<{
  canEdit?: boolean
  event: IEventPreview
}>()

const emit = defineEmits<{
  (e: 'reloadEvents'): void
}>()

const loading = ref(false)
const eventDate = computed(() => getDateFormatted(props.event.dateStart))

async function publishEvent() {
  loading.value = true
  const resp = await api.events.publishEvent(props.event.id)
  if (resp) {
    props.event.status = EVENT_STATUSES.eventPublished
  }
  loading.value = false
}

async function deleteEvent() {
  const resp = await api.events.deleteEvent(props.event.id)
  if (resp) {
    emit('reloadEvents')
  }
}
</script>

<template>
  <router-link
    :to="{ name: 'Event', params: { id: event.id } }"
    class="event"
    tag="article"
  >
    <div class="information">
      <v-avatar
        class="event-image"
        size="72"
        tile
      >
        <v-img :src="getEventImg(event.image, 200)" />
      </v-avatar>
      <div class="event-title">
        <div class="event-title-top">
          <span class="event-date">{{ eventDate }}</span>
          <span class="event-title-divider" />
          <span
            v-if="event.locationAddress"
            class="event-location"
          >
            {{ event.locationAddress }}
          </span>
        </div>
        <span class="name">{{ event.title }}</span>
      </div>
    </div>

    <div
      v-if="canEdit"
      class="options"
    >
      <div @click.stop="">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-icon
              class="menu-icon"
              v-on="on"
            >
              {{ mdiDotsHorizontal }}
            </v-icon>
          </template>
          <v-list>
            <v-list-item @click="deleteEvent">
              <v-list-item-title>
                <v-icon>
                  {{ mdiClose }}
                </v-icon>
                Remove event
              </v-list-item-title>
            </v-list-item>

            <v-list-item @click="publishEvent">
              <v-list-item-title>
                <v-icon>
                  {{ mdiCheckCircleOutline }}
                </v-icon>
                Publish event
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.event {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: var(--color-white);
  border-radius: var(--border-radius-rounded);
  cursor: pointer;
}

.information {
  display: flex;
  gap: 12px;
  align-items: center;
}

.event-title {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &-top {
    @include font-body2;

    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
  }

  &-divider {
    align-self: stretch;
    width: 1px;
    background-color: var(--color-dark-800);
  }

  .name {
    font-weight: var(--font-weight-bold);
  }
}

.options {
  display: flex;

  .menu-icon,
  .doc-progress-icon {
    color: var(--color-dark-800);
  }

  .doc-progress-loader {
    margin-right: 40px;
  }

  .doc-progress-icon {
    margin-right: 40px;
  }
}
</style>
