<template>
  <section class="authentication">
    <div class="background" />
    <v-form
      v-model="valid"
      class="login-form"
      lazy-validation
    >
      <h1 class="login-form__title page-title">Log in</h1>
      <div class="form-items-wrap">
        <BKButton
          :loading="loadingGoogleBtn"
          class="signup-btn-google"
          outlined
          @click="userLoginGoogle"
        >
          <img
            alt="Google"
            src="/img/google-icon-full-color.svg"
          />
          Log in with google
        </BKButton>
        <p class="subtitle">Or use your email</p>
        <div>
          <v-text-field
            v-model="email"
            :rules="[rules.required, rules.email]"
            autocomplete="off"
            background-color="#fff"
            class="login-form__field"
            hide-details="auto"
            label="Email*"
            outlined
            @input="$nextTick(() => (email = email.trim()))"
            @keyup.enter="$nextTick(() => $refs.password.focus())"
          />
          <v-text-field
            ref="password"
            v-model="pass"
            :append-icon="showPassword ? mdiEye : mdiEyeOff"
            :rules="[rules.required]"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="off"
            background-color="#fff"
            class="login-form__field"
            label="Password*"
            outlined
            @keyup.enter="userLogin"
            @click:append="showPassword = !showPassword"
          />
          <span
            v-show="error"
            class="login-form__text-error"
            >{{ error }}</span
          >

          <v-btn
            :disabled="btnDisabled"
            :loading="loading"
            block
            class="login-form__btn-log-social"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="userLogin"
          >
            Log in
          </v-btn>
          <div class="links-container">
            <!--            eslint-disable vuejs-accessibility/click-events-have-key-events-->
            <p
              class="login-form__link"
              @click="handlerClickForgotPass"
            >
              forgot password?
            </p>
            <!--            eslint-enable vuejs-accessibility/click-events-have-key-events-->
            <router-link
              class="login-form__link"
              to="/magic-link"
            >
              send magic link
            </router-link>
          </div>
          <span class="login-form__text-bottom">
            Don't have an account?
            <router-link
              class="login-form__link"
              to="/signup"
              >Create one here</router-link
            >
          </span>
          <br />
        </div>
      </div>
    </v-form>
  </section>
</template>

<script>
import validationRules from '@/mixins/validation'
import { mdiEye, mdiEyeOff } from '@mdi/js'
import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'

export default {
  mixins: [validationRules],
  data() {
    return {
      mdiEye,
      mdiEyeOff,
      valid: true,
      loading: false,
      loadingGoogleBtn: false,
      showPassword: false,
      email: null,
      pass: null,
      error: null,
      btnDisabled: false,
    }
  },
  watch: {
    valid(value) {
      this.btnDisabled = !value
    },
  },
  methods: {
    handlerClickForgotPass() {
      // eslint-disable-next-line no-restricted-globals
      this.$store.commit('setBrowserHistoryState', history.state)
      this.$router.push('/reset-password')
    },
    userLogin() {
      this.loading = true
      if (!this.email || !this.pass) {
        this.error = 'All fields is required'
        this.loading = false
        return
      }

      signInWithEmailAndPassword(getAuth(), this.email, this.pass)
        .catch((error) => {
          this.handleFirebaseAuthErrors(error)
        })
        .finally(() => {
          this.loading = false
          this.pass = null
        })
    },
    async userLoginGoogle() {
      this.loadingGoogleBtn = true
      try {
        const provider = new GoogleAuthProvider()
        await signInWithPopup(getAuth(), provider)
        this.loadingGoogleBtn = false
      } catch (error) {
        this.loadingGoogleBtn = false
        this.error = error.message
      }
    },
    handleFirebaseAuthErrors(error) {
      switch (error.code) {
        case 'auth/too-many-requests':
          this.error = 'Too many attempts to log in with this email, try again later'
          break
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          this.error = 'Incorrect email or password'
          break
        default:
          this.error = error.message
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.authentication {
  padding-bottom: 16px;

  @include profile-settings-bg;

  &__background {
    height: 488px;
    background: #fff url('/img/HeroBG.jpg') 50% 95% / cover no-repeat;
  }

  &__alert {
    position: absolute;
    z-index: 1;
    width: 100%;
  }
}

.login-form {
  position: relative;
  width: 100%;
  max-width: 460px;
  min-height: 500px;
  margin: 180px auto 0;
  padding: 0 20px;
  text-align: center;

  .form-items-wrap {
    display: grid;
    gap: 20px;
  }

  .signup-btn-google {
    & ::v-deep .v-btn__content {
      color: var(--color-dark-800);
    }

    & ::v-deep .button-text {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  .subtitle {
    margin: 0;
    text-align: center;
  }

  &__title {
    @include font-h1;

    margin-bottom: 40px;
    text-align: center;
    text-transform: uppercase;
  }

  &__field.v-text-field {
    margin-bottom: 10px;
  }

  &__btn-log-social {
    margin-bottom: 12px;
    font-weight: bold;
    border: 2px solid;
  }

  &__text-separate {
    display: block;
    margin: 12px 0;
  }

  &__text-error {
    display: block;
    margin-bottom: 15px;
    color: red;
  }

  &__text-bottom {
    margin: 15px 0;
    color: #747474;
  }

  &__link {
    color: #38a1f3;
    text-decoration: none;
  }

  .links-container {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
}
</style>
