import { callPrivateApi } from '@/api/apiHelper'

export const getSparring = async (id) => callPrivateApi('get', `/sparrings/${id}`)

export const setSparringScore = async (id, scores) => callPrivateApi('post', `/sparrings/${id}/judge`, scores)

export const setAudienceScore = async (id, color) => callPrivateApi('post', `/sparrings/${id}/audience/${color}`)

export const addExtraRound = async (id) => callPrivateApi('post', `/sparrings/${id}/extra`)

export const finish = async (id) => callPrivateApi('post', `/sparrings/${id}/finish`)
export const reset = async (id) => callPrivateApi('delete', `/sparrings/${id}/judge`)
