<template>
  <div class="verify-account">
    <div class="verify-account__content">
      <h1 class="verify-account__title page-title">Verification link sent</h1>
      <img
        alt="mail"
        class="verify-account__mail-image"
        src="/img/icon-mail.svg"
      />
      <div class="verify-account__text-wrapper">
        <p class="verify-account__text">
          We sent an email to
          <span class="verify-account__text--email">{{ getUserFB.email }}</span
          >. Verify your email to finish creating your profile.
        </p>
        <p class="verify-account__text">
          If you don’t see the email check your spam folder or
          <button
            class="verify-account__text--resend"
            @click="verifyAccount"
          >
            send again
          </button>
          .
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getAuth, sendEmailVerification } from 'firebase/auth'

export default {
  name: 'VerifyAccount',
  computed: {
    ...mapGetters(['getUserFB']),
  },
  created() {
    if (!this.getUserFB || this.getUserFB.emailVerified) {
      this.$router.push({ name: 'Home' })
    }
  },
  methods: {
    async verifyAccount() {
      await sendEmailVerification(getAuth().currentUser)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.verify-account {
  display: flex;
  align-items: center;
  padding: 0 20px;
  background: #fff url('/img/verify-bg-img.png') 50% 82px / cover no-repeat;

  @media (max-width: $screen-sm) {
    background: #fff url('/img/verify-bg-img-mobile.png') 50% 82px / contain no-repeat;
  }

  &__content {
    margin: 0 auto;
    text-align: center;
  }

  &__title {
    @include font-h1;

    text-transform: uppercase;

    @media (max-width: $screen-sm) {
      max-width: 200px;
      margin: 0 auto;
    }
  }

  &__mail-image {
    margin: 55px 0;

    @media (max-width: $screen-sm) {
      margin: 35px 0;
    }
  }

  &__text-wrapper {
    max-width: 405px;
    margin: 0 auto;
  }

  &__text {
    @include font-h4;

    &--email {
      font-weight: var(--font-weight-bold);
    }

    &--resend {
      all: unset;
      color: var(--color-orange-500);
      font-weight: var(--font-weight-bold);
      cursor: pointer;
    }
  }
}
</style>
