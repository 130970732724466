<template>
  <div class="brackets-container">
    <section class="renderer">
      <div class="rounds-chips">
        <BattleBracketsLevelChip
          v-for="(level, index) in battle.bracketLevels"
          :key="index"
          :level="level"
        />
      </div>
      <div class="brackets-renderer">
        <div class="brackets">
          <h5 class="section-title">Left Bracket</h5>
          <Bracket
            v-if="brackets.left"
            :bracket="brackets.left"
            :participant-to-highlight-id="participantToHighlightId"
            side="left"
          />

          <h5 class="section-title">Right Bracket</h5>
          <Bracket
            v-if="brackets.right"
            :bracket="brackets.right"
            :participant-to-highlight-id="participantToHighlightId"
            side="right"
          />
        </div>
        <div class="final-container">
          <BattleBracketsFinalSparring
            v-if="brackets.final.red.id || brackets.final.blue.id"
            :sparring="brackets.final"
          />

          <div
            v-else
            class="vs"
          >
            VS
          </div>
        </div>
      </div>
    </section>

    <section
      v-if="canEdit"
      class="actions"
    >
      <BKButton
        white-text
        @click="$emit('edit')"
      >
        Edit brackets
      </BKButton>
    </section>
  </div>
</template>

<script>
import BattleBracketsLevelChip from '@/components/Battle/Brackets/BattleBracketsLevelChip.vue'
import Bracket from '@/components/BKBracket.vue'
import BattleBracketsFinalSparring from '@/components/Battle/Brackets/BattleBracketsFinalSparring.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'BattleBracketsRenderer',
  components: { BattleBracketsFinalSparring, BattleBracketsLevelChip, Bracket },
  props: {
    brackets: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
    battle: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['getUserDB']),
    participantToHighlightId() {
      if (!this.getUserDB) return

      if (this.battle.crewSize === 1) {
        // eslint-disable-next-line consistent-return
        return this.getUserDB.id
      }

      const currentUserCrew = this.battle.crews.find((cr) =>
        cr.participants.find((par) => par.id === this.getUserDB.id && par.confirmed)
      )
      if (!currentUserCrew) return

      // eslint-disable-next-line consistent-return
      return currentUserCrew.id
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.renderer {
  max-width: 880px;
  margin: auto;
  overflow-x: auto;
}

.brackets-renderer {
  display: flex;
  align-items: center;
  column-gap: 65px;
}

.section-title {
  @include font-h5;

  margin-bottom: 8px;
}

.vs {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  color: var(--color-white);
  font-weight: var(--font-weight-bold);
  background-color: var(--color-grey-500);
  border-radius: 120px;

  &::after {
    position: absolute;
    right: -20px;
    color: var(--color-grey-600);
    content: '?';
  }

  &::before {
    position: absolute;
    left: -20px;
    color: var(--color-grey-600);
    content: '?';
  }
}

.rounds-chips {
  display: flex;
  margin-bottom: 12px;
  column-gap: 20px;
}
</style>
