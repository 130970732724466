<template>
  <div
    :style="{ backgroundColor: backgroundColor }"
    class="loader"
  >
    <v-progress-circular
      class="loader-main"
      size="120"
      color="#FC7800"
      width="5"
      indeterminate
    />
  </div>
</template>

<script>
export default {
  name: 'BKLoader',
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: 'black',
    },
  },
}
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
