<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import OrganizationEventChip from '@/modules/Organizations/components/info/OrganizationEventChip.vue'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import api from '@/api'
import { EventsTabsTitles, IEventsTabsStats } from '@/types/events'
import { Organization } from '@/modules/Organizations/types'
import { IEventPreview } from '@/types/event'

const props = defineProps<{
  org: Organization
}>()

const currentTab = ref<string>('')
const tabTitles: EventsTabsTitles = {
  now: 'Happening',
  past: 'Past',
  future: 'Upcoming',
  draft: 'Drafts',
}

const tabs = ref<IEventsTabsStats>()
const infiniteLoaderId = ref<number>(+new Date())
const pageSize = ref(8)
const pageNumber = ref(0)
const events = ref<IEventPreview[]>([])
const sortDirection = ref<'asc' | 'desc'>('asc')
const defaultSortDirections = ref<Record<string, 'asc' | 'desc'>>({
  now: 'asc',
  past: 'desc',
  future: 'asc',
  draft: 'desc',
})
const sortBy = ref<'date' | 'title'>('date')

const existedTabs = computed(() => {
  if (!tabs.value) return []
  return Object.keys(tabs.value).filter((t) => tabs.value?.[t as keyof IEventsTabsStats])
})

async function fetchTabs() {
  const data = await api.events.getEventsAvailableTabs({ orgId: props.org.id })
  if (data) {
    tabs.value = data
    currentTab.value = Object.keys(data).find((key) => data[key] !== 0) || Object.keys(data)[0]
  }
}

function selectTab(tab: string) {
  sortDirection.value = defaultSortDirections.value[tab]
  currentTab.value = tab
}

async function loadEvents($state: { complete: () => void; loaded: () => void }) {
  const searchParams = {
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
    tab: currentTab.value,
    orgIds: props.org.id,
    sortBy: `${sortBy.value}-${sortDirection.value}`,
  }

  let data = await api.events.getEvents(searchParams)

  if (!data) data = []
  pageNumber.value += 1
  events.value.push(...data)
  if (data.length < pageSize.value) $state.complete()
  else $state.loaded()
}

function resetLoading() {
  pageNumber.value = 0
  events.value = []
  infiniteLoaderId.value += 1
}

watch([currentTab, sortDirection, sortBy], () => resetLoading())

onMounted(async () => {
  await fetchTabs()
})
</script>

<template>
  <div
    v-if="currentTab"
    class="events"
  >
    <div class="filter-row">
      <div class="periods">
        <button
          v-for="tab in existedTabs"
          :key="tab"
          :class="{ active: currentTab === tab }"
          class="period"
          type="button"
          @click="selectTab(tab)"
        >
          {{ tabTitles[tab] }}
        </button>
      </div>

      <div class="filters">
        <div class="sort">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <div
                class="sort-string"
                v-on="on"
              >
                Sort by:
                <span v-if="sortBy === 'date'"> Date </span>

                <span v-else> A - Z </span>
              </div>
            </template>
            <v-list
              class="sort-menu"
              width="242"
            >
              <v-list-item>
                <span class="sort-title">Sort by</span>
              </v-list-item>

              <v-radio-group
                v-model="sortBy"
                class="mt-0"
              >
                <v-list-item
                  class="d-flex justify-space-between"
                  @click="sortBy = 'date'"
                >
                  <span>Date created</span>
                  <div>
                    <v-radio
                      class="mr-0"
                      value="date"
                    />
                  </div>
                </v-list-item>

                <v-list-item
                  class="d-flex justify-space-between"
                  @click="sortBy = 'title'"
                >
                  <span>A - Z</span>
                  <div>
                    <v-radio
                      class="mr-0"
                      value="title"
                    />
                  </div>
                </v-list-item>
              </v-radio-group>
            </v-list>
          </v-menu>
          <button
            class="sort-arrows"
            type="button"
            @click="sortDirection = sortDirection === 'asc' ? 'desc' : 'asc'"
          >
            <v-icon class="icon-up">
              {{ mdiChevronUp }}
            </v-icon>
            <v-icon class="icon-down">
              {{ mdiChevronDown }}
            </v-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="list">
      <template v-for="event in events">
        <OrganizationEventChip
          :key="event.id"
          :can-edit="org.canEdit"
          :event="event"
          @reloadEvents="resetLoading"
        />
      </template>
      <infinite-loading
        :distance="400"
        :identifier="infiniteLoaderId"
        @infinite="loadEvents"
      >
        <template #spinner>
          <v-progress-circular
            color="#FC7800"
            indeterminate
            size="80"
            width="10"
          />
        </template>
        <span slot="no-more" />
        <span slot="no-results" />
      </infinite-loading>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/style/mixins';

.filter-container .v-list-item__title.filter-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  cursor: pointer;
}

.filter-container .v-list-item__title.filter-title {
  font-weight: var(--font-weight-bold);
}
</style>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.filter-row {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: $screen-sm) {
    flex-direction: column;
  }
}

.filters {
  display: flex;

  .v-icon {
    color: var(--color-black);
  }

  .sort {
    display: flex;
  }

  .sort-arrows {
    display: flex;
    flex-direction: column;

    .icon-up {
      top: -6px;
    }

    .icon-down {
      top: -12px;
    }
  }
}

.filter-checkbox {
  margin: 0;
}

.sort-string {
  cursor: pointer;
}

.sort-menu {
  .sort-title {
    font-weight: var(--font-weight-bold);
  }
}

.periods {
  display: flex;
  gap: 40px;

  @media (max-width: $screen-sm) {
    gap: 20px;
    justify-content: space-between;
  }
}

.period {
  padding-bottom: 4px;
  color: var(--color-dark-800);
  letter-spacing: 1.1px;
  cursor: pointer;

  &.active {
    font-weight: var(--font-weight-bold);
    border-bottom: 2px solid var(--color-dark-800);
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
}
</style>
