<template>
  <div class="join-exist">
    <v-btn
      class="btn-register"
      block
      height="52"
      color="var(--color-blue-800)"
      @click="$emit('register')"
    >
      Register new crew
    </v-btn>

    <v-btn
      block
      height="52"
      outlined
      class="btn-join-existing"
      color="var(--color-blue-800)"
      @click="$emit('join')"
    >
      Join existing crew
    </v-btn>

    <span class="description"> Search for your crew below then click on the crew to join. </span>
  </div>
</template>

<script>
export default {
  name: 'BattleJoinOrRegister',
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.join-exist {
  position: relative;
  background-color: var(--color-grey-100) !important;

  .btn-join-existing {
    margin-bottom: 8px;
  }

  .description {
    display: block;
    margin-bottom: 40px;
  }

  .btn-register {
    margin-bottom: 20px;
    color: var(--color-white) !important;
  }
}
</style>
