<template>
  <div>
    <div class="view-event">
      <div class="view-event__edit-conteiner mb-4">
        <v-btn
          class="view-event__edit-btn"
          color="var(--color-blue-800)"
          icon
          text
          @click="handlerClickEdit('CreateEventInfo')"
        >
          <v-icon>{{ mdiLeadPencil }}</v-icon>
        </v-btn>
      </div>
      <v-img
        :src="getEventImg(eventData.image, 800)"
        class="view-event__image d-flex align-center"
      />
      <p class="view-event__title">General information</p>

      <p v-if="org">
        <span class="view-event__bold-title">Event Organization:</span>
        <v-avatar
          class="ml-3"
          size="25"
        >
          <v-img :src="getOrgImage(org.image, 50)" />
        </v-avatar>
        {{ org.name }}
      </p>

      <p><span class="view-event__bold-title">Event name:</span> {{ eventData.title }}</p>
      <p>
        <span class="view-event__bold-title">Location:</span>
        {{ eventData.locationAddress }}
      </p>
      <p><span class="view-event__bold-title">Website:</span> {{ eventData.website }}</p>
      <div>
        <span class="view-event__bold-title">Description:</span>
        <!--          eslint-disable-next-line-->
        <p
          class="rich-text"
          v-html="$sanitize(eventData.description)"
        />
      </div>

      <p class="view-event__title">Admins</p>

      <v-chip
        v-for="(admin, i) in eventData.staff.admins"
        :key="i + 'a'"
        class="view-event__chip"
        color="transparent"
        large
        pill
      >
        <v-avatar left>
          <v-img :src="getAvatar(admin.avatar, 100)" />
        </v-avatar>
        {{ admin.dancerName }}
      </v-chip>

      <p class="view-event__title">Hosts</p>

      <v-chip
        v-for="(host, i) in eventData.staff.hosts"
        :key="i + 'x'"
        class="view-event__chip"
        color="transparent"
        large
        pill
      >
        <v-avatar left>
          <v-img :src="getAvatar(host.avatar, 100)" />
        </v-avatar>
        {{ host.dancerName }}
      </v-chip>

      <p class="view-event__title">DJs</p>
      <v-chip
        v-for="(Djs, i) in eventData.staff.djs"
        :key="i"
        class="view-event__chip"
        color="transparent"
        large
        pill
      >
        <v-avatar left>
          <v-img :src="getAvatar(Djs.avatar, 100)" />
        </v-avatar>
        {{ Djs.dancerName }}
      </v-chip>
    </div>
    <div class="divider">
      <v-divider class="divider__line" />
    </div>
    <div class="view-event mt-12">
      <div class="view-event__edit-conteiner">
        <v-btn
          class="view-event__edit-btn"
          color="var(--color-blue-800)"
          icon
          text
          @click="handlerClickEdit('CreateEventDate')"
        >
          <v-icon>{{ mdiLeadPencil }}</v-icon>
        </v-btn>
      </div>
      <p class="view-event__title">Date and time *</p>
      <v-row>
        <v-col cols="2">
          <p class="view-event__bold-title">Start:</p>
        </v-col>
        <v-col cols="5">
          <p>
            <v-icon>{{ mdiCalendarCheckOutline }} {{ mdiCalendarCheckOutline }}</v-icon>
            {{ dateStart }}
          </p>
        </v-col>
        <v-col cols="5">
          <p>
            <v-icon>{{ mdiClockOutline }}</v-icon>
            {{ timeStart }}
          </p>
        </v-col>
        <v-col cols="2">
          <p class="view-event__bold-title">End:</p>
        </v-col>
        <v-col cols="5">
          <p>
            <v-icon>{{ mdiCalendarCheckOutline }}</v-icon>
            {{ dateEnd }}
          </p>
        </v-col>
        <v-col cols="5">
          <p>
            <v-icon>{{ mdiClockOutline }}</v-icon>
            {{ timeEnd }}
          </p>
        </v-col>
      </v-row>
    </div>
    <div class="divider">
      <v-divider class="divider__line" />
    </div>
    <div class="view-event mt-12">
      <div class="view-event__edit-conteiner">
        <v-btn
          class="view-event__edit-btn"
          color="var(--color-blue-800)"
          icon
          text
          @click="handlerClickEdit('CreateEventTiers')"
        >
          <v-icon>{{ mdiLeadPencil }}</v-icon>
        </v-btn>
      </div>
      <p class="view-event__title">Cost</p>

      <div
        v-for="(item, index) in eventData.tiers"
        :key="index"
        class="view-event__tier"
      >
        <p class="view-event__title">Tier {{ index + 1 }}</p>
        <div class="view-event__wrap-title-cost">
          <p>
            <span class="view-event__bold-title">Title:</span>
            {{ item.title }}
          </p>
          <p class="ml-2">
            <span class="view-event__bold-title">Cost:</span> $
            {{ item.cost }}
          </p>
        </div>
        <div>
          <span
            v-if="item.description"
            class="view-event__bold-title"
            >Description:</span
          >
          <!--          eslint-disable-next-line-->
          <p
            class="rich-text"
            v-html="$sanitize(item.description)"
          />
        </div>
      </div>

      <!-- Here should be information about the method of payment -->
    </div>
    <div class="divider">
      <v-divider class="divider__line" />
    </div>
    <div class="view-event__battle">
      <div class="view-event__edit-conteiner">
        <v-btn
          class="view-event__edit-btn"
          color="var(--color-blue-800)"
          icon
          text
          @click="handlerClickEdit('CreateEventBattles')"
        >
          <v-icon>{{ mdiLeadPencil }}</v-icon>
        </v-btn>
      </div>
      <p class="view-event__title-bt">Battle</p>
      <div class="view-event__wrap-blocks d-flex flex-wrap flex-column flex-sm-row justify-center align-center">
        <div
          v-for="(battle, index) in eventData.battles"
          :key="index"
          class="view-event__block d-flex flex-column justify-center align-center"
        >
          <p class="view-event__block-title">
            {{ battle.title }}
          </p>
          <span class="view-event__block-type">{{ getBattleCategory(battle.crewSize) }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="isNew"
      class="navigation"
    >
      <div class="navigation__wrap-dots mt-6">
        <span
          v-for="i in 5"
          :key="i"
          :class="i === currentDotNav ? 'navigation__dot--active' : ''"
          class="navigation__dot"
        />
      </div>
      <p
        v-show="error"
        class="navigation__text-error"
      >
        {{ error }}
      </p>
      <div class="navigation__wrap-btn mb-10">
        <div class="d-flex flex-column flex-sm-row-reverse">
          <v-btn
            v-if="isPublished"
            :loading="loadingNextBtn"
            class="navigation__btn btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="$emit('saveAsDraft')"
          >
            Done
          </v-btn>
          <v-btn
            v-if="!isPublished"
            :loading="loadingNextBtn"
            class="navigation__btn btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="handlerClickNext"
          >
            Publish Event
          </v-btn>
          <v-btn
            class="navigation__btn btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="handlerClickBack"
          >
            back
          </v-btn>
        </div>
        <v-btn
          block
          class="navigation__btn-cancel"
          color="var(--color-blue-800)"
          height="48"
          max-width="194"
          text
          @click="toggleCancelEventCreation"
        >
          cancel
        </v-btn>
      </div>
    </div>
    <div
      v-else
      class="navigation"
    >
      <div class="mt-6" />
      <span
        v-show="error"
        class="navigation__text-error"
        >{{ error }}</span
      >
      <div class="navigation__wrap-btn mb-10">
        <div class="d-flex flex-column flex-sm-row-reverse">
          <v-btn
            v-if="isPublished"
            :loading="loadingNextBtn"
            class="navigation__btn btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="$emit('saveAsDraft')"
          >
            Done
          </v-btn>

          <v-btn
            v-if="!isPublished"
            :loading="loadingNextBtn"
            class="navigation__btn btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="handlerClickNext"
          >
            Publish Event
          </v-btn>
          <v-btn
            v-if="!isPublished"
            class="navigation__btn btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="$emit('saveAsDraft')"
          >
            save draft
          </v-btn>
        </div>
        <v-btn
          block
          class="navigation__btn-cancel"
          color="var(--color-blue-800)"
          height="48"
          max-width="194"
          text
          @click="handlerClickDelete"
        >
          delete event
        </v-btn>
      </div>
    </div>
    <CreateEventDelete
      v-if="isDelete"
      :event="eventData"
      :is-show-delete.sync="isDelete"
      :redirect-user="true"
    />
    <CreateEventCancel
      :event="eventData"
      @saveAsDraft="$emit('saveAsDraft')"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import { getDateFormatted, getTimeFormatted } from '@/services/date'
import CreateEventCancel from '@/components/CreateEventCancel.vue'
import CreateEventDelete from '@/components/CreateEventDelete.vue'
import Images from '@/mixins/image'
import Battles from '@/mixins/battle'
import { mdiCalendarCheckOutline, mdiClockOutline, mdiLeadPencil } from '@mdi/js'

export default {
  components: { CreateEventCancel, CreateEventDelete },
  mixins: [Images, Battles],
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    eventData: {
      type: Object,
      default: () => ({}),
    },
    backStep: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiClockOutline,
      mdiLeadPencil,
      mdiCalendarCheckOutline,
      isFirstLoadingDjs: false,
      isFirstLoadingHost: false,
      isFirstLoadingBt: false,

      dateStart: '',
      dateEnd: '',
      timeStart: '12:00',
      timeEnd: '12:00',

      dialog: false,
      loadingNextBtn: false,
      currentDotNav: 5,
      error: null,
      isCancel: false,
      isDelete: false,
      org: null,
    }
  },
  computed: {
    isEventHasDates() {
      return !!(this.eventData.dateStart && this.eventData.dateEnd)
    },
    isPublished() {
      return this.eventData.status === 'published'
    },
  },
  async mounted() {
    // TODO: Org...
    // if (this.eventData.orgId) {
    //   this.org = await api.orgs.getPublicOrg(this.eventData.orgId)
    // }
    this.$emit('update:backStep', false)
    this.initDate()
  },
  methods: {
    ...mapActions(['toggleCancelEventCreation']),
    handlerClickEdit(step) {
      this.$emit('update:backStep', true)
      this.$emit('update:screen', step)
    },
    async handlerClickNext() {
      this.error = null
      this.loadingNextBtn = true
      if (!this.isEventHasDates) {
        this.error = 'Please select an event date'
        this.loadingNextBtn = false
        return
      }
      this.$emit('publishEvent')
    },
    handlerClickBack() {
      this.$parent.isClickBtnNext = false
      this.$emit('update:screen', 'CreateEventBattles')
    },
    handlerClickCancel() {
      this.isCancel = true
    },
    handlerClickDelete() {
      this.isDelete = true
    },
    initDate() {
      const dateStart = getDateFormatted(this.eventData.dateStart)
      const dateEnd = getDateFormatted(this.eventData.dateEnd)
      this.timeStart = getTimeFormatted(this.eventData.dateStart)
      this.timeEnd = getTimeFormatted(this.eventData.dateEnd)
      this.dateStart = dateStart.slice(0, 10)
      this.dateEnd = dateEnd.slice(0, 10)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/navigationEventCreate';
@import '@/assets/style/mixins';

.divider {
  margin: 10px 0;

  &__line {
    position: absolute;
    left: 10%;
    width: 80%;
  }
}

@media (min-width: 600px) {
  .btn {
    width: 50%;
  }
}

.view-event {
  max-width: 400px;
  margin: 106px auto 0;

  &__edit-conteiner {
    max-width: 400px;
    margin: 0 auto;
  }

  &__image {
    position: relative;
    width: 100%;
    height: 400px;
    background: #6cace4;

    @media (max-width: $screen-sm) {
      height: 320px;
    }
  }

  &__title {
    margin: 16px 0;
    color: var(--color-dark-800);
    font-weight: var(--font-weight-semi-bold);

    &--description {
      color: #999;
    }
  }

  &__bold-title {
    font-weight: var(--font-weight-semi-bold);
  }

  &__field {
    width: 100%;
    margin-bottom: -20px !important;
  }

  &__chip {
    width: 100%;
    max-width: 265px;
    height: 34px !important;
    margin: 6px 0;
  }

  &__wrap-title-cost {
    position: relative;
    display: flex;
  }

  &__field-cost {
    max-width: 80px;
    margin-left: 10px;
  }

  &__battle {
    min-height: 100px;
    padding-top: 50px;
  }

  &__block {
    position: relative;
    width: 100%;
    max-width: 360px;
    height: 230px;
    margin: 5px;
    padding: 0 45px;
    background: var(--color-grey-700);

    &-title {
      @include font-h2;

      color: var(--color-dark-800);
      font-weight: var(--font-weight-bold);
      text-align: center;
    }

    &-type {
      color: var(--color-dark-800);
      font-weight: var(--font-weight-normal);
      text-align: center;
      word-break: break-all;
    }
  }

  &__title-bt {
    max-width: 400px;
    margin: 16px auto;
    color: var(--color-dark-800);
    font-weight: var(--font-weight-semi-bold);

    &--description {
      color: #999;
    }
  }

  &__edit-btn {
    margin-left: 90%;
  }
}
</style>
<style lang="scss">
.rich-text {
  ul,
  li {
    list-style-type: inherit;
  }

  p {
    margin: 0 !important;
  }
}
</style>
