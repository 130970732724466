<script lang="ts" setup>
import { getCurrentInstance } from 'vue'
import { Organization } from '@/modules/Organizations/types'
import { useImage } from '@/shared/composables/image'

const { getAvatar } = useImage()
const props = defineProps<{
  org: Organization
}>()

const instance = getCurrentInstance()
</script>

<template>
  <div class="about">
    <div class="text">
      <span class="about-title">Description</span>
      <div v-if="org.description">
        <!-- eslint-disable-next-line -->
        <p v-html="instance?.proxy?.$sanitize(org.description)" />
      </div>
    </div>

    <div class="stats">
      <div class="owners">
        <span
          v-if="org.admins.length"
          class="about-title"
        >
          Organization owners
        </span>
        <router-link
          v-for="admin in org.admins"
          :key="admin.id"
          :to="{ name: 'Profile', params: { id: admin.id } }"
          class="owner-chip"
        >
          <v-avatar size="32">
            <v-img :src="getAvatar(admin.avatar, 64)" />
          </v-avatar>
          <span class="name">{{ admin.dancerName }}</span>
        </router-link>
      </div>

      <div
        v-if="org.founded"
        class="date-created"
      >
        <span class="about-title">Date created</span>
        {{ props.org.founded }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.about {
  display: flex;
  justify-content: space-between;

  @media (max-width: $screen-sm) {
    flex-direction: column-reverse;
  }
}

.text {
  width: 100%;
  max-width: 575px;
  color: var(--color-black);

  @media (max-width: $screen-sm) {
    margin-bottom: 20px;
  }
}

.about-title {
  display: block;
  margin-bottom: 12px;
  font-weight: var(--font-weight-bold);

  @media (max-width: $screen-sm) {
    margin-bottom: 6px;
  }
}

.stats {
  min-width: 220px;

  .owners {
    margin-bottom: 40px;

    @media (max-width: $screen-sm) {
      margin-bottom: 20px;
    }
  }

  .owner-chip {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    color: inherit;
    cursor: pointer;

    .name {
      margin-left: 8px;
    }
  }

  .date-created {
    margin-bottom: 18px;
  }
}
</style>

<style lang="scss">
.about .text {
  ul,
  li {
    list-style-type: inherit;
  }

  p {
    margin: 0;
  }
}
</style>
