<template>
  <ModalWrapper
    :is-show="isShowModal"
    @update:isShow="updateShowModal($event)"
  >
    <v-card tile>
      <div class="col-10 py-10 mx-auto">
        <v-container>
          <v-row>
            <v-card-title class="cancel__title"> Unregister</v-card-title>
          </v-row>
          <v-card-text class="pa-1">
            Are you sure you want to unregister <strong v-if="battle.title">{{ battle.title }}</strong>
            <strong v-else>this battle</strong>?
          </v-card-text>
        </v-container>

        <v-card-actions
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
          class="d-flex"
        >
          <v-btn
            class="px-10 mx-3 my-2"
            color="#000000"
            height="48"
            outlined
            tile
            width="220"
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="battle.canUnregister"
            class="px-10 mx-3 my-2"
            color="#f21849"
            height="48"
            outlined
            tile
            width="220"
            @click="unregister"
          >
            Unregister
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </ModalWrapper>
</template>

<script>
import api from '@/api'

import { ModalWrapper } from '@/shared/ui/modals'

export default {
  components: {
    ModalWrapper,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    battle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    updateShowModal(evt) {
      this.$emit('update:isShowModal', evt)
    },
    async unregister() {
      this.loading = true
      const updatedBattle = await api.battles.unregisterDancer(this.battle.id)
      if (updatedBattle) {
        this.$emit('update:battle', updatedBattle)
        this.$emit('update:isShowModal', false)
      }
      this.loading = false
    },
    close() {
      this.$emit('update:isShowModal', false)
    },
  },
}
</script>
<style lang="scss" scoped>
.cancel {
  &__title {
    text-transform: uppercase;
  }
}
</style>
